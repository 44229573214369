import React, { useState, useEffect } from "react";
import { Sections } from "./extenttimeTag";
import "./extentTime.css";
import SweetAlert from "react-bootstrap-sweetalert";
import { useNavigate, useLocation } from "react-router-dom";
import { IscustomEvent } from "src/global-functions/globalFunctions";

const ExtendTime = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = localStorage.getItem("theme");
  const storedTime = parseInt(localStorage.getItem("timer") || "480", 10);
  const [time, setTime] = useState(storedTime || "480");
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  const [showAlert, setShowAlert] = useState(false);
  const hitGa = (event) => {
    let dataLayer = window["dataLayer"] || [];
    dataLayer.push({
      event: event,
    });
  };
  useEffect(() => {
    const timer = setInterval(() => {
      if (time > 0) {
        setTime((prevTime) => prevTime - 1);
      } else {
        clearInterval(timer);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [time]);

  useEffect(() => {
    localStorage.setItem("timer", time.toString());
  }, [time]);

  useEffect(() => {
    if (time < 1) {
      setShowAlert(true);
      IscustomEvent("Moive Session Ended");
    }
  }, [time]);

  useEffect(() => {
    if (showAlert) {
      // Handle your alert logic here
      hitGa("fnb_end_the_session")
      setTimeout(()=>{
        navigate("/");
      }, 3000)
    }
  }, [showAlert, navigate]);

  return (
    <div>
      <SweetAlert
        error
        show={showAlert}
        title={"Oops"}
        onConfirm={() => {
          setShowAlert(false);
          navigate("/");
        }}
        showConfirm={true}
        showCancel={false}
        confirmBtnText="OK"
        confirmBtnCssClass={
          theme === "inoxTheme"
            ? "confirm-btn-inox border-0"
            : "confirm-btn-pvr border-0"
        }
        closeOnClickOutside={false}
        closeOnEscape={false}
        // timeout={null}
      >
        {"Your session has been expired. Please try again."}
      </SweetAlert>
      <Sections className="p-2">
        <div className="timer-body">
          <div>
            {minutes < 10 ? "0" : ""}
            {minutes}:{seconds < 10 ? "0" : ""}
            {seconds} Mins Remaining
          </div>
        </div>
      </Sections>
    </div>
  );
};

export default ExtendTime;

