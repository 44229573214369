import React, { useState } from "react";
import { Chips } from "primereact/chips";
import explore from "../../../assets/payment/explore.svg";
import offerRight from "../../../assets/payment/offer-right-arrow.svg";
import { usePaymentInputs } from "react-payment-inputs";
import { Checkbox } from 'primereact/checkbox';
import { DonateButton } from "../paymentTags";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from "axios";
import * as appConstants from "../../../constants/index";
import { applycoupon, Gyftrapplycoupon } from "src/services/CouponPayment";
import { InputText } from "primereact/inputtext";

const Gyftr = ({ name, type, sendDataForPayment }) => {
  const theme = localStorage.getItem("theme");
  const [value6, setValue6] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  const transactionType = localStorage.getItem("TransactionType");
  const fnb_qr = localStorage.getItem("fnb_qr") || null;
  const getApply = async () => {
    if (!value6) {
      setErrorMsg("Please enter voucher code");
      setShowAlert(true);
      return '';
    }
    setLoading(true);
    const postData =
    {
      "ccode": localStorage.getItem("theaterId"),
      "bookingId": localStorage.getItem("bookingid"),
      "transId": localStorage.getItem("transid"),
      "bookType": localStorage.getItem("booking_type") ? localStorage.getItem("booking_type") : "BOOKING",
      "coupon": value6,
      "couponType": type,
      "couponId": 0,
      "qr": "NO",
      qrs: fnb_qr === "YES" ? true : false,
      "card": "",
      "mobile": "",
      "otp": ""
    }

    // await axios
    //   .post(
    //     `${appConstants.base_url}v1/payment/coupon/apply`,
    //     postData,
    //     {
    //       headers: {
    //         Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    // chain: theme === "inoxTheme" ? "INOX" : "PVR",
    // city: localStorage.getItem("city"),
    //         appVersion: appConstants.av,
    //         platform: appConstants.pt,
    //       },
    //     }
    //   )
    if (type == 'GYFTR') {
      Gyftrapplycoupon(postData).then((res) => {
        const dt = res.data;
        dt.voucheName = name;
        sendDataForPayment(dt);
        setLoading(false);
      })
        .catch((err) => {
          console.log("error", err);
          setLoading(false);
        });
    } else {
      applycoupon(postData).then((res) => {
        const dt = res.data;
        sendDataForPayment(dt);
        setLoading(false);
      })
        .catch((err) => {
          console.log("error", err);
          setLoading(false);
        });
    }
  }
  return (
    <>
      <SweetAlert
        error
        show={showAlert}
        title={errorMsg}
        onConfirm={() => {
          setShowAlert(false);
          // console.log('confirm')
          //navigate('/')
        }}
        onCancel={() => setShowAlert(false)}
        confirmBtnCssClass={
          theme === "inoxTheme"
            ? "confirm-btn-inox border-0"
            : "confirm-btn-pvr border-0"
        }
      >
        {errorMsg}
      </SweetAlert>
      <div className="">
        <div className="payment-form">
          <div className="paywithdebit">
            <div className="debit-card show-in-desktop-view">
              <h6>{name}</h6>
            </div>
            <div className="to-paid">
              {/* <p>
                To be Paid:
                <span className="price-cross">₹8888</span>{" "}
                <span className="price-blod">₹8888</span>
              </p> */}
            </div>
          </div>
          <div className="payment-debitcard">
            <form>
              <div className="row">

                <div className="col-md-12">
                  <div className="payment-feild">
                    <span className="p-float-label">
                      {/* <Chips
                        id="chips"
                        value={value6}
                        onChange={(e) => console.log(e)}
                      /> */}
                      <InputText type="text" maxlength="18" id="chips" className="offer-control" value={value6}
                        onChange={(e) => setValue6(e.target.value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase())} />
                      <label htmlFor="chips">Enter Voucher Code</label>
                    </span>
                    <p>We promise to keep your voucher safe</p>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="gyftr-box">
                    <p>{name} Voucher amount would not be refunded on cancellation</p>
                    <div className="divider-gyftr"></div>
                    <p>If the vouchervalue is more than the bill the remaining amount will be forfeited.</p>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="verfiy-pay">
                    <DonateButton
                      className={!loading && value6 ? "verfiy-payment" : "verfiy-payment btn-opacity"}
                      type="button"
                      onClick={() => !loading ? getApply() : ''}
                    >
                      Apply
                      {loading ? (
                        <i
                          className="pi pi-spin pi-spinner"
                          style={{ fontSize: "1.5em" }}
                        ></i>
                      ) : (
                        ""
                      )}
                    </DonateButton>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>)
}

export default Gyftr