import React, { useEffect } from "react";
import { Sidebar } from "primereact/sidebar";
import foodGIF from "../../../assets/food/food.gif";
import { ProceedButton } from "src/styled-components/generalTags";
import FlexiLogo from "../../../assets/flexi/FLEXISHOW-LOGO-WHITE.svg";
import cross from "../../../assets/flexi/cross.svg";
import "./flexi.css"

function FlexiHowTouse({ visible, content, setVisible }) {
  const theme = localStorage.getItem("theme");
  const isMobile = window.matchMedia("(max-width: 575px)").matches;
  useEffect(() => {

  }, [visible]);
  return (
    <div>
      <Sidebar
        visible={visible}
        //   icons={proceedbtn}
        showCloseIcon={false}
        // style={{ height: "64%" }}
        position={isMobile ? "bottom" : "right"}
        className="flexi-terms-cond"
        onHide={() => {
          setVisible(false);
        }}
        dismissable={true}
      >

        <div className="flexi-cross-how show-in-desktop-view">
          <img src={cross} alt="" className="img-fluid" onClick={() => setVisible(false)} />
        </div>
        <div className="row">
          <div className="col-lg-12 col-sm-12">

            <div className="how-itwork-icon">
              <img src={FlexiLogo} alt="" className="img-fluid" />
              <h2>How to use</h2>
            </div>
            {/* <div className="work-point-list">
                <ol>
                  <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</li>
                  <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</li>
                  <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</li>
                  <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</li>
                  <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</li>
                  <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</li>
                </ol>
              </div> */}
            <div
              dangerouslySetInnerHTML={{ __html: content?.howToUse }}
            >
            </div>
            <ProceedButton className="how-it-okay" onClick={() => setVisible(false)}>
              Okay
            </ProceedButton>
          </div>
        </div>
      </Sidebar>
    </div>
  );
}

export default FlexiHowTouse;
