import React, { useEffect, useState } from "react";
import { MainDiv } from "./breadcrumbTag";
import { useNavigate } from "react-router-dom";
import "./breadCrumb.css";
import Rightarrow from "../../../assets/seatlayout/arrowblck.svg";
import * as appConstants from "../../../constants/index";
import axios from "axios";
import { refraceTokenService } from "src/global-functions/refreshToken";
import SweetAlert from "react-bootstrap-sweetalert";
import { IscustomEvent } from "src/global-functions/globalFunctions";

const SeatLayoutBreadBrumb = () => {
  const navigate = useNavigate();
  const isMobile = window.matchMedia("(max-width: 575px)").matches;
  const [activePage, setActivePage] = useState();
  const theme = localStorage.getItem("theme");
  const currentPath = window.location.pathname.split("/");
  const [errorMsg, setErrorMsg] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  // console.log("activePage", activePage);

  useEffect(() => {
    setActivePage(currentPath[1]);
  }, []);

  useEffect(() => {
    if (currentPath[1] === "payment" || currentPath[1] === "getfood") {
      let disableBackButton = (e) => {
        e.preventDefault();
        if (currentPath[1] === "payment") {
          hitGa("back_button_dynamic");
          window.history.forward();
        } else if (currentPath[1] === "getfood") {
          cancelTrnsService();
          navigate(-1);
        }
      };

      window.history.pushState(null, null, window.location.pathname);
      window.addEventListener("popstate", disableBackButton);

      return () => {
        window.removeEventListener("popstate", disableBackButton);
      };
    }
  }, []);

  const cancelTrnsService = async () => {
    const postData = {
      qr: localStorage.getItem("isQR") && isMobile ? "YES" : "NO",
      bookType: localStorage.getItem("booking_type"),
      transId: localStorage.getItem("transid"),
      bookingId: localStorage.getItem("bookingid"),
      //ccode: cinemacode,
      ccode: localStorage.getItem("theaterId"),
    };

    await axios
      .post(`${appConstants.base_url}v1/booking/ticketing/cancel`, postData, {
        headers: {
          chain: theme === "inoxTheme" ? "INOX" : "PVR",
          city: localStorage.getItem("city"),
          Authorization: `Bearer ${sessionStorage.getItem("token") || ""}`,
          appVersion: appConstants.av,
          platform: appConstants.pt,
          country: appConstants.cname,
        },
      })
      .then((res) => {
        const dt = res.data;
        let name = localStorage.getItem("booking_type") == "FOOD"?"FnB Session Ended":localStorage.getItem("booking_type") == "GIFTCARD"?"Gift Card Session Ended":"Moive Session Ended";
        IscustomEvent(name);
        localStorage.removeItem("is_cvp_apply");
        if (!isMobile) {
          localStorage.removeItem("isQR");
        }
        // if (dt.result === "success") {
        //   setShowTnC(true);
        //   const ndt = dt.output;
        //   // console.log("cancelTrnce", ndt);
        // }
      })
      .catch((error) => {
        const err = error?.response;
        if (err?.status === 403) {
          refraceTokenService();
          console.log(
            "Forbidden: You do not have permission to access this resource."
          );
        } else {
          console.log("Error:", error.message);
        }
      });
  };
  // seatlayout
  const hitGa = (event) => {
    let dataLayer = window["dataLayer"] || [];
    dataLayer.push({
      event: event,
    });
    if (currentPath[1] === "seatlayout") {
      navigate(-1);
    } else if (currentPath[1] === "getfood") {
      cancelTrnsService();
      navigate(-1);
    } else {
      setErrorMsg("Are you sure you want to cancel checking out ?");
      if (!localStorage.getItem("unpaidMode") === "true") {
        navigate("/");
      } else {
        setShowAlert(true);
      }
    }
  };

  return (
    <MainDiv className="brdcrmb">
      <SweetAlert
        error
        show={showAlert}
        title={errorMsg}
        onConfirm={() => {
          setShowAlert(false);
          cancelTrnsService();
          //navigate(-1);
          // console.log('confirm')
          navigate("/");
        }}
        showConfirm={true}
        showCancel={true}
        onCancel={() => setShowAlert(false)}
        confirmBtnCssClass={
          theme === "inoxTheme"
            ? "confirm-btn-inox border-0"
            : "confirm-btn-pvr border-0"
        }
      ></SweetAlert>
      <div className="seat-bread-movies food-bread-mobile">
        <ul>
          <li
            className={
              localStorage.getItem("unpaidMode") === "true"
                ? "btn-disabled"
                : "pointer"
            }
            onClick={() => {
              hitGa("back_button_dynamic");
            }}
          >
            <span className="seat-arrow-backs">
              <img src={Rightarrow} alt="" className="img-fluid" />
            </span>
            Back
          </li>
          {localStorage.getItem("booking_type") !== "FOOD" ? (
            <>
              <li
                className={
                  activePage === "seatlayout" ? "seat-bread-active" : ""
                }
              >
                Select Seats
              </li>
              <li className="">Choose Cinema</li>
            </>
          ) : (
            ""
          )}
          <li className={activePage === "getfood" ? "seat-bread-active" : ""}>
            Grab Food
          </li>
          <li className={activePage === "payment" ? "seat-bread-active" : ""}>
            Payment
          </li>
        </ul>
      </div>
    </MainDiv>
  );
};

export default SeatLayoutBreadBrumb;
