import React, { useEffect, useState } from "react";
import * as appConstants from "../../../constants/index";
import axios from "axios";
import { refraceTokenService } from "src/global-functions/refreshToken";
import { Link, useLocation, useNavigate } from "react-router-dom";
import miv_inox from "../../../assets/default-images/vertical-poster-inox.png";
import miv_pvr from "../../../assets/default-images/vertical-poster-pvr.svg";
import mih_pvr from "../../../assets/default-images/horizontal-poster-pvr.svg";
import mih_inox from "../../../assets/default-images/horizontal-poster-inox.png";
import noImage from "../../../assets/food/noimage.png";
import Cities from "src/views/common/cities/Cities";
import { useDispatch, useSelector } from "react-redux";
import MobileFooter from "../mobile-footer/MobileFooter";
import { Sidebar } from "primereact/sidebar";
import { InputText } from "primereact/inputtext";
import location from "../../../assets/nav-icon/location.svg";
import foodGIF from "../../../assets/food/food.gif";
import way from "../../../assets/search/ways.svg";
import angle_left from "../../../assets/mobilemoviedetail/arrow-b.svg";
import "./instafood.css";
import LikeIcon from "../../../assets/mobilemoviedetail/heart.svg";
import heart_pvr from "../../../assets/mobilemoviedetail/yellowheart.svg";
import heart_inox from "../../../assets/mobilemoviedetail/blueheart.svg";
import { addMinutes, setHours, format, setMinutes, isAfter, isBefore, getHours, getMinutes, isToday, isValid, parse, addDays } from 'date-fns';
import NowShowingSkeleton from "src/skeletons/NowShowingSkeleton";
import { monthsName } from "src/global-functions/globalFunctions";
import { Calendar } from "primereact/calendar";
import { ProceedButton } from "src/views/gift-cards/giftCardTags";
import backBtn from "../../../assets/food/backBtnn.svg";
import backBtndesk from '../../../assets/food/desktopBack.svg';
import locationIcon from '../../../assets/food/locationBlack.svg'
import FbHeader from "src/views/food-and-beverage/component/FbHeader";
import { Modal } from "react-bootstrap";
import Navbar from "src/views/common/navbar/Navbar";
import { getResponsiveClasses } from "src/views/food-and-beverage/component/ResponsiveClasses";
import Footer from "src/views/common/footer/Footer";
import SweetAlert from "react-bootstrap-sweetalert";
import SweetAlertUtility from "src/views/food-and-beverage/component/SweetAlertUtility";
import { setCityChangeData } from "src/redux/actions/cityAction";




const FnBDatePicker = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = localStorage.getItem("theme");
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [daysList, setDaysList] = useState(null);
  const [theaterId, setTheaterId] = useState(null);
  const [theaterInfo, setTheaterInfo] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [time, setTime] = useState(addMinutes(new Date(), 30));
  const [date, setDate] = useState("");
  const [cinemaDistance, setCinemaDistance] = useState();
  const [maxtime, setMaxtime] = useState(addMinutes(new Date(), 30));
  const [mintime, setMintime] = useState("");
  const nineAM = setHours(setMinutes(new Date(), 0), 9); // 9:00 AM
  const elevenPM = setHours(setMinutes(new Date(), 0), 23); // 11:00 PM
  const [alertDetails, setAlertDetails] = useState({ show: false, type: '', title: '', message: '' });
  const [showAlert, setShowAlert] = useState(false);
  const [screenType, setScreenType] = "";

  const { currentClasses, getWindowWidth } = getResponsiveClasses();


  localStorage.setItem("upcomingMovieBack", false);
  let TransactionType = localStorage.getItem("TransactionType");
  const selectedTheater = JSON.parse(localStorage.getItem("selectedTheater"));

  useEffect(() => {
    if (!TransactionType && getWindowWidth) {
      localStorage.setItem("TransactionType", "OnlyFB");
    }
  }, [TransactionType])

  useEffect(() => {
    if (date) {
      getMaxTime();
      getMinTime();
    }
  }, [date, activeTab]);

  const getMaxTime = () => {
    setMaxtime(setHours(setMinutes(new Date(date), 0), 23));
  };

  // const getMinTime = () => {
  //   const now = new Date();
  //   let minTime;
  //   const nineAM = setHours(setMinutes(new Date(), 0), 9);
  //   const elevenPM = setHours(setMinutes(new Date(), 0), 23);
  //   const tomorrow9AM = setHours(setMinutes(addDays(new Date(), 1), 9), 0);

  //   minTime = addMinutes(now, 30);

  //   if (isBefore(minTime, nineAM)) {
  //     minTime = nineAM;
  //   } else if (isAfter(minTime, elevenPM)) {
  //     minTime = tomorrow9AM;
  //   }

  //   setTime(minTime);
  // };

  const getMinTime = () => {
    const now = new Date();
    let minTime;

    if (activeTab === 0) {
      minTime = addMinutes(now, 30);
      const nineAM = setHours(setMinutes(new Date(), 0), 9);
      if (minTime.getTime() < nineAM.getTime()) {
        minTime = nineAM;
      }
    } else {
      minTime = setHours(setMinutes(new Date(date), 0), 9);
    }

    setTime(minTime);
    // setMintime(minTime);
  };

  // function validateTime(event) {
  //   let selectedDate;

  //   // ✅ Get the selected date from event or local storage
  //   if (!event || !event.value) {
  //     const selectedData = localStorage.getItem("seatLayoutDate");
  //     selectedDate = new Date(selectedData);
  //   } else {
  //     selectedDate = new Date(event.value);
  //   }

  //   if (isNaN(selectedDate.getTime())) {
  //     console.error("Invalid date parsed:", selectedDate);
  //     return;
  //   }

  //   const now = new Date();
  //   const selectedDay = startOfDay(selectedDate); // Normalize selected day to start of day
  //   const today = startOfDay(now); // Normalize today to start of day

  //   // ✅ Define min & max time constraints
  //   const today9AM = setHours(setMinutes(new Date(), 0), 9);
  //   const today11PM = setHours(setMinutes(new Date(), 0), 23);
  //   const future9AM = setHours(setMinutes(new Date(selectedDate), 0), 9);
  //   const future11PM = setHours(setMinutes(new Date(selectedDate), 0), 23);

  //   // ✅ **Case 2 & 3: If selecting TODAY, apply 9 AM - 11 PM limit + 30-min buffer**
  //   if (selectedDay.getTime() === today.getTime()) {
  //     let minAllowedTime = addMinutes(now, 30); // 30-min buffer
  //     if (minAllowedTime < today9AM) minAllowedTime = today9AM; // Ensure min time is 9 AM

  //     if (isBefore(selectedDate, minAllowedTime)) {
  //       selectedDate = minAllowedTime;
  //     }
  //     if (isAfter(selectedDate, today11PM)) {
  //       selectedDate = today11PM;
  //     }
  //   }
  //   // ✅ **Case 1, 4 & 5: If selecting a FUTURE DATE, set min time to 9 AM & max to 11 PM**
  //   else {
  //     if (isBefore(selectedDate, future9AM)) {
  //       selectedDate = future9AM;
  //     } else if (isAfter(selectedDate, future11PM)) {
  //       selectedDate = future11PM;
  //     }
  //   }

  //   setTime(selectedDate);
  // }

  function validateTime(event) {
    let selectedDate;
    let minTime;

    // Parse the selected time
    if (!event || !event.value) {
      const selectedData = localStorage.getItem("seatLayoutDate");
      const selDate = new Date(selectedData)
      if (selDate.getDate() === time.getDate() &&
        selDate.getMonth() === time.getMonth() &&
        selDate.getFullYear() === time.getFullYear())
        return;

      const now = new Date();
      const timeString = `${now.getHours()}:${now.getMinutes()}`;
      selectedDate = new Date(`${selectedData} ${timeString}`);
    } else {
      selectedDate = new Date(event.value);
    }

    if (isNaN(selectedDate.getTime())) {
      console.error("Invalid date parsed:", selectedDate);
      return;
    }

    const now = new Date();
    const selectedCurrentDate = new Date(selectedDate);
    const todayStart = new Date();
    todayStart.setHours(9, 0, 0, 0);
    const todayEnd = new Date();
    todayEnd.setHours(23, 0, 0, 0);

    if (
      selectedCurrentDate.getDate() === now.getDate() &&
      selectedCurrentDate.getMonth() === now.getMonth() &&
      selectedCurrentDate.getFullYear() === now.getFullYear()
    ) {
      const minAllowedTime = new Date(now.getTime() + 30 * 60 * 1000);
      if (selectedDate < minAllowedTime) {
        selectedDate = minAllowedTime;
      }
    } else {
      minTime = setHours(setMinutes(new Date(selectedDate), 0), 9);
      const maxTime = setHours(setMinutes(new Date(selectedDate), 0), 23);

      if (selectedDate < minTime) {
        selectedDate = minTime;
      } else if (selectedDate > maxTime) {
        selectedDate = maxTime;
      }
    }
    if (
      selectedCurrentDate.getDate() === now.getDate() &&
      selectedCurrentDate.getMonth() === now.getMonth() &&
      selectedCurrentDate.getFullYear() === now.getFullYear()
    ) {
      if (selectedDate < todayStart) {
        selectedDate = todayStart;
      } else if (selectedDate > todayEnd) {
        selectedDate = todayEnd;
      }
    }

    // const timeString = format(selectedDate, "h:mm a");

    setTime(selectedDate);
  }

  const monthName = (data) => {
    const monthNum = new Date(data).getMonth();
    const monthName = monthsName[monthNum];
    return monthName;
  };

  useEffect(() => {
    CinemasSessionRequest(selectedTheater, "NA");
  }, []);

  const CinemasSessionRequest = async (data, date) => {
    setLoading(true);
    setTheaterId(data?.theatreId || data?.theaterId);
    setTheaterInfo(data);
    FoodPickuphitGa("food_pickup_cinema", data?.name);
    const postData = {
      city: localStorage.getItem("city"),
      cid: data?.theatreId || data?.theaterId,
      lat: localStorage.getItem("lat") || "0.00",
      lng: localStorage.getItem("long") || "0.00",
      dated: date,
      qr: localStorage.getItem("isQR") && isMobile ? "YES" : "NO",
      cineType: "",
      cineTypeQR: "",
    };
    try {
      await axios
        .post(
          `${appConstants.base_url}v1/booking/content/csessions`,
          postData,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token") || " "}`,
              chain: theme === "inoxTheme" ? "INOX" : "PVR",
              city: localStorage.getItem("city"),
              appVersion: appConstants.av,
              platform: appConstants.pt,
              country: appConstants.cname,
            },
          }
        )
        .then((res) => {
          const dt = res.data;
          if (dt.result === "success") {
            const ndt = dt.output;
            setDaysList(ndt?.days);
            setDate(ndt?.days[0]?.dt);
            setLoading(false);
            setVisibleRight(false);
          } else if (dt.result === "error") {
            setDaysList(null);
            setLoading(false);
            sweetAlertError(dt.msg);

            // navigate("/select-food");
          }
        })
        .catch((error) => {
          setLoading(false);
          const err = error.response;
          if (err.status === 403) {
            // refraceTokenService();
            console.log(
              "Forbidden: You do not have permission to access this resource."
            );
          } else {
            console.log("Error:", error.message);
          }
          // setLoading(false);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const sweetAlertSuccess = (msg) => {
    setAlertDetails({
      show: true,
      type: "success",
      title: "Success!",
      message: msg,
    });
  };

  const sweetAlertError = (msg) => {
    setAlertDetails({
      show: true,
      type: "error",
      title: "Error!",
      message: msg,

    });
  };

  const hideAlert = () => {
    setAlertDetails({ show: false });
    navigate("/select-cinema-for-fnb");
  }

  let dataLayer = window["dataLayer"] || [];

  const hitGaDateTime = (food_date, food_time) => {
    dataLayer.push({
      event: "food_pickup_date_time_proceed",
      food_date: food_date,
      food_time: food_time
    });
  };

  const FoodPickuphitGa = (event, cinema) => {
    dataLayer.push({
      event: event,
      cinema_name: cinema
    });
  };

  return (
    <>
      {getWindowWidth &&
        <div className="bg-white show-in-desktop-view" style={{ position: "sticky", top: "0", zIndex: "999" }}>
          <Navbar />
        </div>
      }
      <div className={currentClasses.fnbdeskdiv}>
        <div className={currentClasses.fnbdeskdivInn}>
          <FbHeader
            locationName={theaterInfo?.name}
            headname="Food Pickup Date & Time"
            addressShow={true}
          />
          <div className="phn-cinemas*">
            {!loading ? (
              <>
                <p className="topnotedc">Please confirm by when should we keep your order ready.</p>
                <div className="datePickers fnbDate">
                  <p className="pfontw">Select Pickup Date</p>
                  <div className="calnder-current">
                    <ul>
                      {daysList &&
                        daysList?.map((item, index) => (
                          <li
                            key={index}
                            onClick={() => {
                              setActiveTab(index);
                              setDate(item?.dt);
                            }}
                          >
                            <div
                              className={
                                activeTab === index && theme === "inoxTheme"
                                  ? "dates-active"
                                  : activeTab === index &&
                                    theme === "pvrTheme"
                                    ? "dates-pvr-active"
                                    : "dates-inactive"
                              }
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                // getMovieSessionData(item.dt);
                                localStorage.setItem("seatLayoutDate", item.dt);
                                validateTime("");
                              }}
                            >
                              <span
                                className="month"
                                style={
                                  item.d === "01" || activeTab === index
                                    ? {}
                                    : { visibility: "hidden" }
                                }
                              >
                                {monthName(item.dt)}
                              </span>
                              <h5>{item.d}</h5>
                              <span className="day">{item.wd}</span>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>

                <div className="pvr-movie-time* fnbDate">
                  <p className="pfontw">Select Pickup Time</p>
                  <div
                    className="datetimemn"
                    style={{ textAlign: "center" }}
                  >
                    <Calendar
                      value={time}
                      onChange={(e) => {
                        validateTime(e);
                      }}
                      // minDate={mintime}
                      // maxDate={maxtime}
                      showTime
                      timeOnly
                      inline
                      hourFormat="12"
                      showIcon
                      placeholder="Pickup Time"
                    />
                  </div>
                </div>
                <div className={currentClasses.fbAddedDiv} style={{ backgroundColor: "#FFF", paddingBottom: 15 }}>
                  <p className="notedc">Note: Our kitchen’s operational hours are <span style={{ whiteSpace: "nowrap" }}>9 am</span> to <span style={{ whiteSpace: "nowrap" }}>11 pm</span> everyday.</p>
                  <ProceedButton
                    className={
                      time && !loading
                        ? "btn-proceeds"
                        : "btn-proceeds btn-opacity"
                    }
                    style={{ position: "static" }}
                    type="button"
                    onClick={() => {
                      if (time && !loading) {
                        localStorage.setItem("booking_type", "FOOD");
                        //localStorage.setItem("fnb_qr", "Yes");
                        localStorage.setItem("theaterId", theaterId);
                        localStorage.setItem("screenType", screenType === "" ? screenType : "N");
                        localStorage.setItem("FoodPickupDateTime", format(time, "dd MMM yy, hh:mm a"));
                        navigate("/select-food", { state: { orderDateTime: format(time, "yyyy-MM-dd HH:mm:00") } });
                        hitGaDateTime(format(time, "dd/MM/yyyy"), format(time, "hh:mm:ss a"))
                      }
                    }}
                  >
                    Proceed
                  </ProceedButton>
                </div>
              </>
            ) : (
              <NowShowingSkeleton countVal={4} />
            )}
          </div>
        </div>
      </div>

      {getWindowWidth &&
        <Footer />
      }

      <SweetAlertUtility type={alertDetails.type} title={alertDetails.title} message={alertDetails.message} show={alertDetails.show} onConfirm={hideAlert} />
      {/* {showAlert &&
        <div>
          <SweetAlert
            error
            show={showAlert}
            title={errorMsg?.result === "dialog" ? "Oops" : errorMsg?.result}
            onConfirm={confirmAlert}
            onCancel={() => setShowAlert(false)}
            showConfirm={true}
            showCancel={false}
            confirmBtnText="OK"
            confirmBtnCssClass={
              theme === "inoxTheme"
                ? "confirm-btn-inox border-0"
                : "confirm-btn-pvr border-0"
            }
            closeOnClickOutside={false}
            closeOnEscape={false}
          >
            {errorMsg?.msg}
          </SweetAlert>
        </div>} */}
    </>
  );
};


export default FnBDatePicker;
