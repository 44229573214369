import React, { useState, useEffect } from "react";
import download from "../../assets/payment/file-download.svg";
import angle_left from "../../assets/mobilemoviedetail/arrow-b.svg";
import check from "../../assets/payment/checked 1.svg";
import direction from "../../assets/payment/direction.svg";
import vegIcon from "../../assets/food/veg-icon.svg";
import nonveg from "../../assets/food/nonveg.svg";
import share from "../../assets/payment/share.png";
import PassportWeeked from "../../assets/payment/passport-card.png";
import historyArrow from "../../assets/payment/historyarrow.svg";
import OfferIcon from "../../assets/payment/offer-inox-icon.svg";
import QrCodeIcon from "../../assets/payment/qr-code.svg";
import OfferPVRICON from "../../assets/payment/offer-pvr-icon.svg";
import PassportVoucher from "../../assets/payment/passport-voucher-google.svg";
// import m1 from "../../assets/payment/final-poster.png";
import Navbar from "../common/navbar/Navbar";
import * as appConstants from "../../constants/index";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import { useNavigate } from "react-router-dom";
import miv_pvr from "../../assets/default-images/vertical-poster-pvr.svg";
import miv_inox from "../../assets/default-images/vertical-poster-inox.png";
import { ProceedButton } from "../common/login/loginTags";
import { refraceTokenService } from "../../global-functions/refreshToken";
import FinalTicketSkeleton from "../../skeletons/FinalTicketSkeleton";
import "./finalticket.css";
import UserBook from "../../assets/payment/user-book.svg";
import { Divider } from "primereact/divider";
import { getRetryPayment } from "../../services/CouponPayment";
// import { getRetryPayment } from "src/services/CouponPayment";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import PassportBG from "../../assets/payment/passport-bg.png";
import GoogleWallet from "../common/googlewallet/GoogleWallet";
import Election from "../../assets/payment/election.svg";
import { getSplashData } from "src/services/OtherServices";
import OnTime from "../../assets/flexi/FLEXISHOW-LOGO-WHITE.svg";
import InfoFlexi from "../../assets/flexi/info.svg";
import {
  IsEvent,
  IscustomEvent,
  salesForceEvent,
} from "src/global-functions/globalFunctions";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import FlexiHowItWork from "../payment/flexipay/FlexiHowItWork";
import FlexiHowTouse from "../payment/flexipay/FlexiHowTouse";
import Slide from "../../assets/flexi/arrow-flexi.svg";
import PVRLeft from "../../assets/flexi/pvr-left.svg";
import InoxLeft from "../../assets/flexi/inox-left.svg";
import InoxRight from "../../assets/flexi/inox-right.svg";
import QrCodeView from "../profile/pages/FamilyBundle/QrCodeView";
const FinalTicket = () => {
  const navigate = useNavigate();
  const theme = localStorage.getItem("theme");
  const isMobile = window.matchMedia("(max-width: 575px)").matches;
  const [loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState(null);
  const [countStatus, setcountStatus] = useState(0);
  const [countTicket, setCountTicket] = useState(0);
  const [percentage, setpercentage] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [spalsh, setSpalsh] = useState(null);
  const loginInfo = JSON.parse(sessionStorage.getItem("loginInfo") || "null");
  const [mobileTicketShowMoreViewMode, setMobileTicketShowMoreViewMode] =
    useState(false);
  const [mobileTicketTnCViewMode, setMobileTicketTnCViewMode] = useState(false);
  const [finalTicket, setFinalTicket] = useState(null);
  const [isBadTransaction, setIsBadTransaction] = useState(false);
  const [seconds, setSeconds] = useState(60);
  const [showflexihiw, setShowflexiHiw] = useState(false);
  const [showflexihowitwork, setShowflexiHowitwork] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    let val = localStorage.getItem("booking_ticket");
    localStorage.removeItem("fnb_qr");
    //let val = localStorage.getItem("booking_ticket");
    if (val == 1) {
      getFinalTicket();
      localStorage.setItem("booking_ticket", 0);
    } else {
      getJuspayStatus();
      //  getFinalTicket();
    }
  }, [countStatus]);

  useEffect(() => {
    setTimeout(() => {
      setpercentage(percentage + 1);
    }, 1000);
  }, [percentage]);

  useEffect(() => {
    try {
      getSplashData().then((res) => {
        const dt = res.data;
        // setBookingLoading(false);
        if (dt.result === "success") {
          const ndt = dt.output;
          setSpalsh(ndt);
          // console.log(ndt, "spalsh");
        }
      });
    } catch (error) {
      // console.log(error);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds > 0) {
          return prevSeconds - 1;
        } else {
          clearInterval(interval);
          return 0;
        }
      });
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []); // Empty dependency array to run the effect only once on component mount

  const formattedTime = `${String(Math.floor(seconds / 60)).padStart(
    2,
    "0"
  )}:${String(seconds % 60).padStart(2, "0")}`;

  // call juspay for getting payment status
  const getJuspayStatus = async () => {
    setLoading(true);
    const postData = {
      bookingId: localStorage.getItem("bookingid"),
      paymentId: localStorage.getItem("paymentid"),
      bookType:
        localStorage.getItem("booking_type") === "GIFTCARD"
          ? "GIFTCARD"
          : localStorage.getItem("booking_type") === "FOOD"
            ? "FOOD"
            : "BOOKING",
    };

    // console.log("postData", postData)

    await axios
      .post(`${appConstants.base_url}v1/payment/juspay/status`, postData, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token") || ""}`,
          chain: theme === "inoxTheme" ? "INOX" : "PVR",
          city: localStorage.getItem("city"),
          appVersion: appConstants.av,
          platform: appConstants.pt,
        },
      })
      .then((res) => {
        const dt = res.data;
        if (dt.result === "success") {
          localStorage.removeItem("isDonation");
          const ndt = dt.output;
          if (ndt.p == "PAID") getFinalTicket();
          else if (ndt.p == "PENDING") {
            if (countStatus == 0) {
              setpercentage(percentage + 1);
            }
            if (countStatus < 6) {
              setTimeout(() => {
                //setpercentage(percentage+1);
                setcountStatus(countStatus + 1);
              }, 10000);
            } else {
              setLoading(false);
              setShowAlert(true);
              setIsBadTransaction(true);
              //}
              setErrorMsg({
                result: "Pending",
                msg: ndt?.bank_msg ? ndt.bank_msg : "Payment Pending",
              });
              // navigate(`/payment/${localStorage.getItem("cinemaName")}`);
            }
          } else {
            setShowAlert(true);
            if (!getRetryPayment()) {
              setIsBadTransaction(true);
            }
            setTimeout(() => {
              IscustomEvent("Moive Transaction Failed");
            }, 4000);
            setErrorMsg({
              result: "Failed",
              msg: ndt?.bank_msg
                ? ndt.bank_msg
                : "Payment Failed, Please try again",
            });
            setLoading(false);
            // navigate(`/payment/${localStorage.getItem("cinemaName")}`);
          }

          //console.log("finalTicket==>", dt);
        } else if (dt.result === "error" || dt.result === "dialog") {
          setShowAlert(true);
          setErrorMsg(dt);
          setIsBadTransaction(true);
          setTimeout(() => {
            IscustomEvent("Moive Transaction Failed");
          }, 4000);
        } else {
          setShowAlert(true);
          setErrorMsg(dt);
          setIsBadTransaction(true);
          //console.log(res.data.msg);
          setTimeout(() => {
            IscustomEvent("Moive Transaction Failed");
          }, 4000);
        }
      })
      .catch((error) => {
        const err = error?.response;
        if (err?.status === 403) {
          refraceTokenService();
          console.log(
            "Forbidden: You do not have permission to access this resource."
          );
        } else {
          console.log("Error:", error?.message);
        }
        setLoading(false);
      });
  };
  //===== final ticketing service ============
  const getFinalTicket = async () => {
    setLoading(true);
    const postData = {
      qr: localStorage.getItem("isQR") && isMobile ? "YES" : "NO",
      bookType: localStorage.getItem("booking_type")
        ? localStorage.getItem("booking_type")
        : "BOOKING",
      transId: localStorage.getItem("transid"),
      bookingId: localStorage.getItem("bookingid"),
      flowType: "TICKET",
      ccode: localStorage.getItem("theaterId"),
    };

    // console.log("postData", postData)
    await axios
      .post(
        `${appConstants.base_url}v1/booking/ticketing/completed`,
        postData,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token") || ""}`,
            chain: theme === "inoxTheme" ? "INOX" : "PVR",
            city: localStorage.getItem("city"),
            appVersion: appConstants.av,
            platform: appConstants.pt,
          },
        }
      )
      .then((res) => {
        const dt = res.data;
        if (dt.result === "success") {
          const ndt = dt.output;
          setFinalTicket(ndt);
          setLoading(false);
          if (res.data.output?.familyBundle_data) {
            const voucher = res.data.output?.familyBundle_data;
            let dataLayer = window["dataLayer"] || [];
            dataLayer.push({
              event: "family_bundle_purchase",
              voucher_name: voucher.voucherDesc,
              voucher_id: voucher.id,
              price: voucher.totalPrice,
              valid_date: voucher.validity,
              inc_quantity: voucher.quantity
            });
          }
          // console.log("finalTicket==>", dt);
          //hitGA
          // console.log("Hitga");
          const bookingTime = res.data.output.bookingTime;
          const dateOnly = bookingTime.split(" ")[0];
          let dataLayer = window["dataLayer"] || [];
          let fbTotalPrice = 0;
          let item_category = "";

          if (res?.data?.output?.orderFood?.fnb) {
            // The property is defined, and you can safely use it here
            const { totalTicketPrice, convTotal, donation } =
              res.data.output.orderTicket;

            // Convert values to floats
            const floatTotalTicketPrice = parseFloat(totalTicketPrice);
            const floatConvTotal = parseFloat(convTotal);
            const floatDonation = parseFloat(donation);

            // Calculate the sum
            const sum = floatTotalTicketPrice + floatConvTotal + floatDonation;
            fbTotalPrice = sum + "," + res.data.output.orderFood.fbTotalPrice;
            item_category = `ticket,f&b${ndt?.orderTicket?.familyBundleInfo ? "," + ndt?.orderTicket?.familyBundleInfo?.split("|").pop() : ""}`;
          } else {
            fbTotalPrice = res.data.output.finalAmount;
            // The property is undefined
            // console.log("fbTotalPrice is undefined");
          }
          const transId = localStorage.getItem("transid");
          const script = document.createElement("script");

          script.innerHTML = `
            gtag('set', 'user_data', {
            "phone_number": '+91${loginInfo?.data?.mob}', 
            });
            gtag('event', 'conversion', {
                'send_to': 'AW-1000372559/WFZECI_P3H4Qz_KB3QM',
                'value': '${res.data.output?.finalAmount
              ? Number(
                res.data.output?.finalAmount?.replace(/,/g, "")
              ).toFixed(2)
              : 0
            }',
                'currency': 'INR',
                'transaction_id': '${transId}'
            });
          `;
          document.head.appendChild(script);

          const vouDesc = res.data.output?.familyBundle_data?.voucherDesc || "";
          const vouId = res.data.output?.familyBundle_data?.id || "";
          const discValue = res.data.output?.familyBundle_data?.totalPrice || "";
          const validity = res.data.output?.familyBundle_data?.validity || "";
          const itemCategory5 = `${vouDesc}, ${vouId}, ${discValue}, ${validity}`;
          item_category = "ticket" + ", " + vouDesc;

          dataLayer.push({
            event: "purchase",
            ecommerce: {
              currency: "INR",
              value: Number(res.data.output.finalAmount), // (price amount)
              affiliation: localStorage.getItem("pgused"), //(payment type)
              transaction_id: localStorage.getItem("transid"),
              coupon: res.data.output.paymodes.couponType, //(apply offer)
              items: [
                {
                  item_name: res.data.output.orderFilmCinema.filmName, // (ticket's name,passport's name,gift card's name)
                  item_id: localStorage.getItem("movieid"), //(ticket's id,passport's id,gift card's id)
                  price: Number(res.data.output.finalAmount), //(total amount)
                  item_brand: appConstants.cinemaChain, // (pvr/inox)
                  item_category: item_category, //(selected ticket, gift card & passport)
                  item_category2:
                    localStorage.getItem("cinema_name") +
                    "/" +
                    localStorage.getItem("city"), //(selected f&b & city name)
                  item_category3:
                    dateOnly + "," + localStorage.getItem("selectedseats"), //( selected date & seat number)
                  item_category4: localStorage.getItem("genre"),
                  item_category5: itemCategory5, //(selected f&b & city name)
                  item_variant: localStorage.getItem("format"), //(selected f&b & city name)
                  quantity: res.data.output.orderTicket.numOfSeats, //( number of book ticket counting)
                },
              ],
            },
          });

          //hitGA

          if (res.data.output?.orderFood?.foods.length > 0) {
            let food_type = res.data.output.orderFood.foodType;
            let food_name = [];
            res.data.output.orderFood.foods.map((food, index) =>
              food_name.push(food.name)
            );

            let food_qty = [];
            res.data.output.orderFood.foods.map((food, index) =>
              food_qty.push(food.quantity)
            );

            dataLayer.push({
              event: "fnb_purchase",
              movie_name: res.data.output.orderFilmCinema.filmName,
              food_type: food_type,
              food_name: food_name.join(","),
              quantity: food_qty.join(","),
              price: parseFloat(
                res.data.output?.orderFood?.fbTotalPrice
              ).toFixed(2),
            });
            dataLayer.push({
              event: "food_purchase",
              //: res.data.output.orderFilmCinema.filmName,
              food_type: food_type,
              food_name: food_name.join(","),
              quantity: food_qty.join(","),
              price: parseFloat(
                res.data.output?.orderFood?.fbTotalPrice
              ).toFixed(2),
            });
          }

          fbq("track", "Purchase", {
            content_ids: "8767867868",
            content_type: "Movie",
            content_name: res.data.output.orderFilmCinema.filmName,
            value: Number(
              res.data.output?.finalAmount?.replace(/,/g, "") || 0
            ).toFixed(2),
            currency: "INR",
          });

          var sfmcLineitems = [
            {
              catalogObjectType: "Product",
              catalogObjectId: localStorage.getItem("movieid"),
              price: Number(res.data.output.orderTicket?.ticketPrice.replace(/,/g, "") || 0).toFixed(2),
              quantity: res.data.output.orderTicket.numOfSeats,
            },
          ];

          res.data.output?.orderFood?.foods?.forEach((item) => {
            sfmcLineitems.push({
              catalogObjectType: "Product",
              catalogObjectId: item?.itemId,
              price: item?.price / 100,
              quantity: item?.quantity,
            });
          });
          
          setTimeout(() => {
            salesForceEvent({
              interaction: {
                name: SalesforceInteractions.OrderInteractionName.Purchase,
                order: {
                  id: localStorage.getItem("bookingid"),
                  totalValue: Number(res.data.output?.finalAmount?.replace(/,/g, "") || 0).toFixed(2),
                  lineItems: sfmcLineitems,
                },
              },
            });
          }, 2000);
          // setTimeout(() => {
          //   console.log(sfmcLineitems);
          //   debugger

          // }, 5000);

          // sfmcLineitems = res.data.output?.orderFood?.foods.length >
          //   0 ?[{
          //       catalogObjectType: "Product",
          //       catalogObjectId: localStorage.getItem("movieid"),
          //       price: Number(res.data.output.orderTicket?.ticketPrice.replace(
          //         /,/g,
          //         ""
          //       )).toFixed(2),
          //       quantity: res.data.output.orderTicket.numOfSeats
          //    },
          //    {
          //       catalogObjectType: "Product",
          //       catalogObjectId: localStorage.getItem("bookingid"),
          //       price: Number(res.data.output?.orderFood?.fbTotalPrice.replace(
          //         /,/g,
          //         ""
          //       ) || 0).toFixed(2),
          //       quantity: res.data.output?.orderFood?.fbCount
          //    }]
          // : [{
          //   catalogObjectType: "Product",
          //   catalogObjectId: localStorage.getItem("movieid"),
          //   price: Number(res.data.output.orderTicket?.ticketPrice.replace(
          //     /,/g,
          //     ""
          //   ) || 0).toFixed(2),
          //   quantity: res.data.output.orderTicket.numOfSeats
          // }];



          // SalesforceInteractions.init({
          //   account: appConstants.ISaccount,
          //   dataset: appConstants.ISdataset,
          // });

          // SalesforceInteractions.sendEvent({
          //   interaction: {
          //     name: SalesforceInteractions.OrderInteractionName.Purchase,
          //     order: {
          //       id: localStorage.getItem("bookingid"),
          //       lineItems: [
          //         {
          //           catalogObjectType: "Product",
          //           catalogObjectId: localStorage.getItem("movieid"),
          //           price: res.data.output.finalAmount,
          //           quantity: res.data.output.orderTicket.numOfSeats,
          //         },
          //       ],
          //     },
          //   },
          // });

          localStorage.removeItem("bookingid");
          localStorage.removeItem("transid"),
            localStorage.removeItem("booking_type"); //this line
          localStorage.removeItem("isDonation");
          localStorage.removeItem("isOfferAppplied");
        } else {
          setShowAlert(true);
          setErrorMsg(dt);
          setIsBadTransaction(true);
          setTimeout(() => {
            IscustomEvent("Moive Transaction Failed");
          }, 4000);
        }
      })
      .catch((error) => {
        const err = error?.response;
        if (err?.status === 403) {
          refraceTokenService();
          console.log(
            "Forbidden: You do not have permission to access this resource."
          );
        } else {
          console.log("Error:", error?.message);
        }
        // setLoading(false);
      });
  };

  const convertStringForgoogle = async (string, index) => {
    let parts = string.split("||");
    return index >= 0 && index < parts.length ? parts[index] : "";
  };
  const hitGa = (event) => {
    let dataLayer = window["dataLayer"] || [];
    dataLayer.push({
      event: event,
    });
  };

  const formatBookDate = (dateString) => {
    // const dateObject = new Date(dateString);
    // const daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    // const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    // const formattedDate = `${daysOfWeek[dateObject.getDay()]} ${dateObject.getDate()} ${months[dateObject.getMonth()]}, ${dateObject.getFullYear()}`;

    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("en-US", {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    return formattedDate;
  };

  const formatBookTime = (timeString) => {
    const date = new Date(timeString);
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    return formattedTime;
  };

  const seatsArray = finalTicket?.orderTicket?.seats
    .split(",")
    .map((seat) => seat.trim());

  const seatsGSTArray = finalTicket?.orderTicket?.gstInvoiceNo
    .split(",")
    .map((seatgst) => seatgst.trim());

  const walletMsgArray = finalTicket?.googleWalletMessage
    ? finalTicket?.googleWalletMessage.split("||").map((item) => item.trim())
    : [];

  const pvrTnc =
    '<p style="font-size:10px;">1. The seat layout page for PVR cinemas is for representational purposes only and the actual seat layout might vary.<br><strong>2. Ticket is compulsory for children of 3 years &amp; above.</strong><br>3. Patrons below the age of 18 years cannot be admitted for movies certified A.<br>4. A baggage counter facility is not available at this cinema.<br>5. For 3D movies, ticket price includes charges towards usage of 3D glasses. &nbsp;<br>6. Outside Food and beverages are not allowed On the cinema premises. &nbsp;<br>7. Items like laptops, cameras, knives, lighters, matchboxes, cigarettes, firearms, and all types of inflammable objects are strictly prohibited. &nbsp;<br>8. Items, like carry bags, eatables, helmets, and handbags, are not allowed inside the theatres and are strictly prohibited. Kindly deposit at the baggage counter of the mall.<br>9. Patrons under the influence of alcohol or any other form of drugs will not be allowed inside the cinema premises.<br>10. Tickets once purchased at the cinema box office cannot be canceled, exchanged, or refunded.<br>11. PVR may contact the guest to seek feedback for service improvement. &nbsp;<br>12. Decision(s) taken by PVR shall be final and binding, Rights of admission reserved.<br><strong>13. Pre-booked food &amp; beverage needs to be collected from the F&amp;B counter.</strong><br>14. Recording of a film through mobile or camera is strictly prohibited and is a punishable offense.<br>15. Smoking is strictly not permitted inside the cinema premises. Cigarettes/lighters/matchsticks/Gutkha/Pan masala etc. will not be allowed. &nbsp; &nbsp;<br>16. Ticket prices are subject to change without any prior notification.<br>17. For celebrating Birthday parties and special occasions, kindly contact the cinema manager.</p>';
  const inoxTnx =
    '<p style="font-size:10px;">1. The seat layout page for INOX movies is for representational purposes only and the actual seat layout might vary.<br><strong>2. Ticket is compulsory for children of 3 years &amp; above.</strong><br>3. Patrons below the age of 18 years cannot be admitted for movies certified A.<br>4. A baggage counter facility is not available at this cinema.<br>5. For 3D movies, ticket price includes charges towards usage of 3D glasses. &nbsp;<br>6. Outside Food and beverages are not allowed On the cinema premises. &nbsp;<br>7. Items like laptops, cameras, knives, lighters, matchboxes, cigarettes, firearms, and all types of inflammable objects are strictly prohibited. &nbsp;<br>8. Items, like carry bags, eatables, helmets, and handbags, are not allowed inside the theatres and are strictly prohibited. Kindly deposit at the baggage counter of the mall.<br>9. Patrons under the influence of alcohol or any other form of drugs will not be allowed inside the cinema premises.<br>10. Tickets once purchased at the cinema box office cannot be canceled, exchanged, or refunded.<br>11. INOX may contact the guest to seek feedback for service improvement. &nbsp;<br>12. Decision(s) taken by INOX shall be final and binding, Rights of admission reserved.<br><strong>13. Pre-booked food &amp; beverage needs to be collected from the F&amp;B counter.</strong><br>14. Recording of a film through mobile or camera is strictly prohibited and is a punishable offense.<br>15. Smoking is strictly not permitted inside the cinema premises. Cigarettes/lighters/matchsticks/Gutkha/Pan masala etc. will not be allowed. &nbsp; &nbsp;<br>16. Ticket prices are subject to change without any prior notification.<br>17. For celebrating Birthday parties and special occasions, kindly contact the cinema manager.</p>';
  return (
    <>
      {loading ? (
        <>
          <div className="bg-white">
            <div className="row">
              <div className="col-md-12 col-xs-12">
                <div className="Payment-show-align">
                  <div className="Payment-show-content">
                    <h3>Payment is under process, Please wait ...</h3>
                  </div>
                  <div className="Payment-process-bar">
                    <CircularProgressbar
                      value={percentage}
                      text={`${percentage}%`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className="bg-white show-in-desktop-view"
            style={{ position: "sticky", top: "0", zIndex: "999" }}
          >
            <Navbar />
          </div>
          <div
            className="bg-white show-in-mobile-view"
            style={{ position: "sticky", top: "0", zIndex: "999" }}
          >
            <div className="booking-confirm">
              <div
                className="back-div"
                onClick={() => {
                  if (localStorage.getItem("isQR")) {
                    navigate(
                      `/cinemasessions/${localStorage.getItem(
                        "city"
                      )}/qr/${localStorage.getItem("theaterId")}`
                    );
                  } else if (localStorage.getItem("fnb_qr")) {
                    navigate(
                      `/food/${localStorage.getItem(
                        "city"
                      )}/qr/${localStorage.getItem("theaterId")}`
                    );
                  } else {
                    navigate("/");
                  }
                  // navigate("/")
                }}
              >
                <img src={angle_left} alt="" className="" />{" "}
                <h3>Booking Confirmed</h3>
              </div>
              {/* {spalsh &&
              spalsh?.google_Wallet == "true" &&
              !finalTicket?.orderTicket?.flexiApply ? (
                <div className="googlewalletdivmbl">
                  <GoogleWallet
                    orderID={finalTicket?.orderIdEx}
                    booktype="BOOKING"
                  />
                </div>
              ) : (
                ""
              )} */}
            </div>
          </div>
        </>
      )}
      {showAlert ? (
        <div>
          <SweetAlert
            show={showAlert}
            title={errorMsg?.result === "dialog" ? " " : errorMsg?.result}
            onConfirm={() => {
              setShowAlert(false);
              setLoading(false);
              // console.log('confirm')
              if (isBadTransaction) {
                if (localStorage.getItem("isQR")) {
                  navigate(
                    `/cinemasessions/${localStorage.getItem(
                      "city"
                    )}/qr/${localStorage.getItem("theaterId")}`
                  );
                } else if (localStorage.getItem("fnb_qr")) {
                  navigate(
                    `/food/${localStorage.getItem(
                      "city"
                    )}/qr/${localStorage.getItem("theaterId")}`
                  );
                } else {
                  navigate("/");
                }
                localStorage.removeItem("transid");
              } else {
                if (isMobile) {
                  navigate(`/m.payment/${localStorage.getItem("cinemaName")}`);
                } else {
                  navigate(`/payment/${localStorage.getItem("cinemaName")}`);
                }
              }
            }}
            onCancel={() => setShowAlert(false)}
            showConfirm={true}
            showCancel={false}
            hideCloseButton={errorMsg?.result === "dialog" ? false : ""}
            confirmBtnText="OK"
            confirmBtnCssClass={
              theme === "inoxTheme"
                ? "confirm-btn-inox border-0"
                : "confirm-btn-pvr border-0"
            }
            closeOnClickOutside={false}
            closeOnEscape={false}
          >
            {errorMsg?.msg}
          </SweetAlert>
        </div>
      ) : loading ? (
        ""
      ) : (
        <>
          {/* desktop view ticket start */}
          <section className="show-in-desktop-view">
            <div className="final-ticket-bg">
              <div className="container view-container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="enjoy-show">
                      <img src={check} alt="" className="img-fluid" />
                      <h4>Enjoy your show </h4>
                      <p>
                        Thank you for choosing PVR INOX! E-Order details have
                        been shared on your registered email ID and Phone Number
                      </p>
                      {finalTicket?.orderTicket?.flexiApply ? (
                        <div className="flexi-note">
                          <p>
                            Please note, we have sent seat-specific bookings and
                            QR Codes; scroll to view all.
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                      <a
                        href={finalTicket && finalTicket?.tu}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <ProceedButton
                          className={
                            finalTicket?.tu
                              ? "download-ticket"
                              : "download-ticket btn-opacity"
                          }
                          onClick={() => hitGa("download_ticket")}
                        >
                          <span className="download-tickets">
                            <img src={download} alt="" className="img-fluid" />
                          </span>
                          Download Ticket
                        </ProceedButton>
                      </a>
                    </div>
                    {/* <div className="election-logo-ticket">
                      <img src={Election} alt="" className="img-fluid" />
                    </div> */}
                    {finalTicket?.orderTicket?.flexiApply ? (
                      <div className="Ticket-of-flexi">
                        <p>
                          Ticket {countTicket + 1} of{" "}
                          {finalTicket?.invoiceData?.length}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                    {spalsh &&
                      spalsh?.google_Wallet == "true" &&
                      !finalTicket?.orderTicket?.flexiApply ? (
                      <div
                        className={
                          theme === "inoxTheme"
                            ? "google-wallet-inox"
                            : "google-wallet-pvr"
                        }
                      >
                        <div className="row google-center">
                          {finalTicket?.googleWalletMessage ? (
                            <>
                              <div className="col-md-5 col-sm-12">
                                <div className="offer-icon-final">
                                  <img
                                    src={
                                      walletMsgArray.length > 2 &&
                                        walletMsgArray[2] == "2"
                                        ? PassportVoucher
                                        : theme === "inoxTheme"
                                          ? OfferIcon
                                          : OfferPVRICON
                                    }
                                    alt=""
                                    className="img-fluid"
                                    width={25}
                                    height={20}
                                  />
                                  <h6>
                                    {walletMsgArray.length > 0
                                      ? walletMsgArray[0]
                                      : ""}
                                  </h6>
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-12">
                                <div className="claim-google-wallet">
                                  <p>
                                    {walletMsgArray.length > 1
                                      ? walletMsgArray[1]
                                      : ""}
                                  </p>
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          <div
                            className={
                              finalTicket?.googleWalletMessage
                                ? "col-md-3 col-sm-12"
                                : "col-md-12 col-sm-12"
                            }
                          >
                            <div
                              className={
                                finalTicket?.googleWalletMessage
                                  ? "google-btn-loader"
                                  : "google-btn-loader1"
                              }
                            >
                              <GoogleWallet
                                orderID={finalTicket?.orderIdEx}
                                booktype="BOOKING"
                              />
                            </div>
                          </div>
                        </div>
                        {/* <p><span className="offer-icon-final">
                          <img
                            src={
                              walletMsgArray.length > 2 && walletMsgArray[2] == "2" ? PassportVoucher :
                                theme === "inoxTheme"
                                  ? OfferIcon
                                  : OfferPVRICON
                            }
                            alt=""
                            className="img-fluid"
                            width={25}
                            height={20}
                          />
                        </span> <b>{walletMsgArray.length > 0 ? walletMsgArray[0] : ""}</b> {walletMsgArray.length > 1 ? walletMsgArray[1] : ""}<GoogleWallet orderID={finalTicket?.orderIdEx} booktype="BOOKING" /> </p> */}
                        {/* <b>You are just one step away!</b> From Claim your free Passport voucher */}
                      </div>
                    ) : (
                      ""
                    )}
                    {loading ? (
                      <FinalTicketSkeleton />
                    ) : (
                      <>
                        <section>
                          {finalTicket?.orderTicket?.flexiApply ? (
                            <div
                              className="flexi-booking-ticket-final"
                              style={
                                theme === "inoxTheme"
                                  ? { backgroundColor: "#DCE2EF" }
                                  : { backgroundColor: "#FFF0D6" }
                              }
                            >
                              <div className="final-flexi">
                                <p>
                                  <span className="on-time">
                                    <img
                                      src={OnTime}
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </span>{" "}
                                  Upgraded to Flexi Ticket{" "}
                                  <span className="info-flexi">
                                    <img
                                      onClick={() =>
                                        setShowflexiHowitwork(true)
                                      }
                                      src={InfoFlexi}
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </span>
                                  {finalTicket?.orderTicket?.ticketsRefunded >
                                    0 ? (
                                    <span>{`${finalTicket?.orderTicket?.ticketsRefunded
                                      } ${finalTicket?.orderTicket
                                        ?.ticketsRefunded > 1
                                        ? "Tickets"
                                        : "Ticket"
                                      }  Refunded!`}</span>
                                  ) : (
                                    ""
                                  )}
                                </p>
                              </div>
                              <div className="exit-you">
                                <p>
                                  Exit anytime you want, and get a partial
                                  refund!
                                </p>
                              </div>
                              <div className="how-final-ticket">
                                <button
                                  className={
                                    theme === "inoxTheme"
                                      ? "how-finali-inox"
                                      : "how-finali-pvr"
                                  }
                                  onClick={() => setShowflexiHiw(true)}
                                >
                                  How it works?
                                </button>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="container view-container">
                            <div className="row">
                              <div className="col-md-12">
                                {finalTicket?.paymodes?.couponType ==
                                  "PASSPORT" ? (
                                  <div className="Passport-final-ticket">
                                    <div className="row">
                                      <div className="col-md-2 passport-week-img">
                                        <div className="passport-weeked">
                                          <img
                                            src={PassportWeeked}
                                            alt=""
                                            className=""
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="row kindly">
                                          <div className="col-md-6">
                                            <div className="passport-user">
                                              <h3>
                                                Name{" "}
                                                <span className="passport-details-person">
                                                  {loginInfo?.data?.un}
                                                </span>
                                              </h3>
                                            </div>
                                          </div>
                                          {loginInfo?.data?.dob ? (
                                            <div className="col-md-6">
                                              <div className="passport-user">
                                                <h3>
                                                  DOB{" "}
                                                  <span className="passport-details-person">
                                                    {" "}
                                                    {loginInfo?.data?.dob}
                                                  </span>
                                                </h3>
                                              </div>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="kindly">
                                          <p>
                                            <img
                                              src={UserBook}
                                              alt=""
                                              className="img-fluid"
                                            />{" "}
                                            Kindly Carry Your Valid Photo ID
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                                <div
                                  className={
                                    finalTicket?.paymodes?.couponType ===
                                      "PASSPORT"
                                      ? "final-ticket-new passport-final"
                                      : "final-ticket-new"
                                  }
                                >
                                  {finalTicket?.orderTicket?.flexiApply ? (
                                    <>
                                      <Swiper
                                        // slidesPerView={'auto'}
                                        navigation={{
                                          nextEl: ".custom-next",
                                          prevEl: ".custom-prev",
                                        }}
                                        modules={[Navigation]}
                                        slidesPerView={
                                          countTicket + 1 ==
                                            finalTicket?.invoiceData?.length
                                            ? 1.0
                                            : 1.1
                                        }
                                        spaceBetween={8}
                                        pagination={{
                                          clickable: true,
                                        }}
                                        className="mySwiper"
                                        onSlideChange={(e) =>
                                          setCountTicket(e.activeIndex)
                                        }
                                      >
                                        {finalTicket?.invoiceData.map(
                                          (indata, index) => (
                                            <SwiperSlide>
                                              <div className="row">
                                                <div className="col-md-2">
                                                  <div className="ticket-new-box">
                                                    <div className="movie-poster-new">
                                                      <img
                                                        src={
                                                          finalTicket
                                                            ?.orderFilmCinema
                                                            ?.posterVert
                                                            ? finalTicket
                                                              ?.orderFilmCinema
                                                              ?.posterVert
                                                            : theme ===
                                                              "inoxTheme"
                                                              ? miv_inox
                                                              : miv_pvr
                                                        }
                                                        alt=""
                                                        className=""
                                                      />
                                                      <div className="ticket-d">
                                                        <h6>
                                                          Ticket:{" "}
                                                          {indata?.seats}
                                                        </h6>
                                                      </div>
                                                    </div>
                                                    <div className="share-qr-code">
                                                      <img
                                                        src={indata?.qr}
                                                        alt=""
                                                        className="img-fluid"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-md-10">
                                                  <div className="final-ticket-detail-new">
                                                    <h3>
                                                      {
                                                        finalTicket
                                                          ?.orderFilmCinema
                                                          ?.filmName
                                                      }
                                                    </h3>
                                                    <div className="gener-new">
                                                      <ul>
                                                        <li>
                                                          {
                                                            finalTicket
                                                              ?.orderFilmCinema
                                                              ?.certificate
                                                          }
                                                        </li>
                                                        {finalTicket
                                                          ?.orderFilmCinema
                                                          ?.genre ? (
                                                          <li>
                                                            <span className="dot-sb"></span>
                                                            {
                                                              finalTicket
                                                                ?.orderFilmCinema
                                                                ?.genre
                                                            }
                                                          </li>
                                                        ) : (
                                                          ""
                                                        )}
                                                        {finalTicket
                                                          ?.orderFilmCinema
                                                          ?.language ? (
                                                          <li>
                                                            <span className="dot-sb"></span>
                                                            {
                                                              finalTicket
                                                                ?.orderFilmCinema
                                                                ?.language
                                                            }
                                                          </li>
                                                        ) : (
                                                          ""
                                                        )}
                                                        {finalTicket
                                                          ?.orderFilmCinema
                                                          ?.format ? (
                                                          <li>
                                                            <span className="dot-sb"></span>
                                                            {
                                                              finalTicket
                                                                ?.orderFilmCinema
                                                                ?.format
                                                            }
                                                          </li>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </ul>
                                                    </div>

                                                    <h5>
                                                      {
                                                        finalTicket
                                                          ?.orderFilmCinema
                                                          ?.theaterName
                                                      }
                                                    </h5>
                                                    {/* <p>PVR Select Citywalk, Select Citywalk Mall, First Floor, Saket District Centrestrict Centre, New Delhi, Delhi 110017</p> */}
                                                    <p>
                                                      {finalTicket?.address}
                                                    </p>
                                                    <p className="new-direction">
                                                      Get Direction
                                                      <span className="direction-new">
                                                        <a
                                                          href={`https://maps.google.com/?q=${finalTicket?.ltd},${finalTicket?.lngt}`}
                                                          target="_blank"
                                                          rel="noopener noreferrer"
                                                        >
                                                          <img
                                                            src={direction}
                                                            alt=""
                                                            className="img-fluid"
                                                          />
                                                        </a>
                                                      </span>
                                                    </p>
                                                    <div className="final-ticket-divider"></div>
                                                    <div className="row">
                                                      <div className="col-md-4">
                                                        <div className="date-new-ticket">
                                                          <h6>
                                                            Show Date & Time
                                                          </h6>
                                                          <p>
                                                            <b>
                                                              {
                                                                finalTicket?.showTimeStr
                                                              }
                                                            </b>
                                                          </p>
                                                        </div>
                                                      </div>
                                                      {finalTicket?.orderTicket
                                                        ?.seats ? (
                                                        <div className="col-md-4">
                                                          <div className="date-new-ticket">
                                                            <h6>Screen</h6>
                                                            <p>
                                                              {
                                                                finalTicket
                                                                  ?.orderTicket
                                                                  ?.audi
                                                              }
                                                            </p>
                                                          </div>
                                                        </div>
                                                      ) : (
                                                        ""
                                                      )}
                                                      <div className="col-md-4">
                                                        <div className="date-new-ticket">
                                                          <h6>Class</h6>
                                                          <p>
                                                            {
                                                              finalTicket
                                                                ?.orderTicket
                                                                ?.showClassName
                                                            }
                                                          </p>
                                                        </div>
                                                      </div>
                                                      {finalTicket?.orderTicket
                                                        ?.seats &&
                                                        seatsArray &&
                                                        seatsArray.length > 0 ? (
                                                        <div className="col-md-12">
                                                          <div className="new-seat-info">
                                                            <h6>Seat INFO</h6>

                                                            <div className="select-seat-number-final new-ticket-box">
                                                              <div
                                                                className="seat-number-final"
                                                                key={index}
                                                                style={
                                                                  theme ===
                                                                    "inoxTheme"
                                                                    ? {
                                                                      backgroundColor:
                                                                        "#DCE2EF",
                                                                    }
                                                                    : {
                                                                      backgroundColor:
                                                                        "#FFF0D6",
                                                                    }
                                                                }
                                                              >
                                                                <p>
                                                                  {
                                                                    indata?.seats
                                                                  }
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </div>
                                                    <div className="final-ticket-divider"></div>
                                                    <div className="row">
                                                      <div className="col-md-3">
                                                        <div className="date-new-ticket">
                                                          <h6>BOOKING ID</h6>
                                                          <p>
                                                            {
                                                              finalTicket?.bookingId
                                                            }
                                                          </p>
                                                        </div>
                                                      </div>
                                                      <div className="col-md-3">
                                                        <div className="date-new-ticket">
                                                          <h6>TRACK ID</h6>
                                                          <p>
                                                            {
                                                              finalTicket?.orderIdEx
                                                            }
                                                          </p>
                                                        </div>
                                                      </div>
                                                      <div className="col-md-3">
                                                        <div className="date-new-ticket">
                                                          <h6>BOOKING DATE</h6>
                                                          <p>
                                                            <b>
                                                              {formatBookDate(
                                                                finalTicket?.bookingTime
                                                              )}
                                                            </b>
                                                          </p>
                                                        </div>
                                                      </div>
                                                      <div className="col-md-3">
                                                        <div className="date-new-ticket">
                                                          <h6>BOOKING TIME</h6>
                                                          <p>
                                                            <b>
                                                              {formatBookTime(
                                                                finalTicket?.bookingTime
                                                              )}
                                                            </b>
                                                          </p>
                                                        </div>
                                                      </div>
                                                      <div className="col-md-3">
                                                        <div className="date-new-ticket">
                                                          <h6>Payment mode</h6>
                                                          <p>
                                                            {
                                                              finalTicket?.payMode
                                                            }
                                                          </p>
                                                        </div>
                                                      </div>
                                                      <div className="col-md-3">
                                                        <div className="date-new-ticket">
                                                          <h6>State GST No.</h6>
                                                          <p>
                                                            {
                                                              finalTicket
                                                                ?.orderFilmCinema
                                                                ?.stateGSTN
                                                            }
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </SwiperSlide>
                                          )
                                        )}
                                      </Swiper>
                                      <div className="custom-arrow-slide">
                                        <div
                                          className="custom-prev"
                                          style={
                                            countTicket == 0
                                              ? { display: "none" }
                                              : {}
                                          }
                                        >
                                          <img
                                            src={
                                              theme === "inoxTheme"
                                                ? InoxLeft
                                                : PVRLeft
                                            }
                                            alt=""
                                            className="img-fluid"
                                          />
                                          {/* <img src={inoxLeft} alt="" className="img-fluid" /> */}
                                        </div>
                                        <div
                                          className="custom-next"
                                          style={
                                            countTicket + 1 ==
                                              finalTicket?.invoiceData?.length
                                              ? { display: "none" }
                                              : {}
                                          }
                                        >
                                          <img
                                            src={
                                              theme === "inoxTheme"
                                                ? InoxRight
                                                : Slide
                                            }
                                            alt=""
                                            className="img-fluid"
                                          />
                                          {/* <img src={inoxRight} alt="" className="img-fluid" /> */}
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <div className="row">
                                      <div className="col-md-2">
                                        <div className="ticket-new-box">
                                          <div className="movie-poster-new">
                                            <img
                                              src={
                                                finalTicket?.orderFilmCinema
                                                  ?.posterVert
                                                  ? finalTicket?.orderFilmCinema
                                                    ?.posterVert
                                                  : theme === "inoxTheme"
                                                    ? miv_inox
                                                    : miv_pvr
                                              }
                                              alt=""
                                              className=""
                                            />
                                          </div>
                                          <div className="share-qr-code">
                                            <img
                                              src={finalTicket?.qr}
                                              alt=""
                                              className="img-fluid"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-10">
                                        <div className="final-ticket-detail-new">
                                          <h3>
                                            {
                                              finalTicket?.orderFilmCinema
                                                ?.filmName
                                            }
                                          </h3>
                                          <div className="gener-new">
                                            <ul>
                                              <li>
                                                {
                                                  finalTicket?.orderFilmCinema
                                                    ?.certificate
                                                }
                                              </li>
                                              {finalTicket?.orderFilmCinema
                                                ?.genre ? (
                                                <li>
                                                  <span className="dot-sb"></span>
                                                  {
                                                    finalTicket?.orderFilmCinema
                                                      ?.genre
                                                  }
                                                </li>
                                              ) : (
                                                ""
                                              )}
                                              {finalTicket?.orderFilmCinema
                                                ?.language ? (
                                                <li>
                                                  <span className="dot-sb"></span>
                                                  {
                                                    finalTicket?.orderFilmCinema
                                                      ?.language
                                                  }
                                                </li>
                                              ) : (
                                                ""
                                              )}
                                              {finalTicket?.orderFilmCinema
                                                ?.format ? (
                                                <li>
                                                  <span className="dot-sb"></span>
                                                  {
                                                    finalTicket?.orderFilmCinema
                                                      ?.format
                                                  }
                                                </li>
                                              ) : (
                                                ""
                                              )}
                                            </ul>
                                          </div>

                                          <h5>
                                            {
                                              finalTicket?.orderFilmCinema
                                                ?.theaterName
                                            }
                                          </h5>
                                          {/* <p>PVR Select Citywalk, Select Citywalk Mall, First Floor, Saket District Centrestrict Centre, New Delhi, Delhi 110017</p> */}
                                          <p>{finalTicket?.address}</p>
                                          <p className="new-direction">
                                            Get Direction
                                            <span className="direction-new">
                                              <a
                                                href={`https://maps.google.com/?q=${finalTicket?.ltd},${finalTicket?.lngt}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                              >
                                                <img
                                                  src={direction}
                                                  alt=""
                                                  className="img-fluid"
                                                />
                                              </a>
                                            </span>
                                          </p>
                                          <div className="final-ticket-divider"></div>
                                          <div className="row">
                                            <div className="col-md-4">
                                              <div className="date-new-ticket">
                                                <h6>Show Date & Time</h6>
                                                <p>
                                                  <b>
                                                    {finalTicket?.showTimeStr}
                                                  </b>
                                                </p>
                                              </div>
                                            </div>
                                            {finalTicket?.orderTicket?.seats ? (
                                              <div className="col-md-4">
                                                <div className="date-new-ticket">
                                                  <h6>Screen</h6>
                                                  <p>
                                                    {
                                                      finalTicket?.orderTicket
                                                        ?.audi
                                                    }
                                                  </p>
                                                </div>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            <div className="col-md-4">
                                              <div className="date-new-ticket">
                                                <h6>Class</h6>
                                                <p>
                                                  {
                                                    finalTicket?.orderTicket
                                                      ?.showClassName
                                                  }
                                                </p>
                                              </div>
                                            </div>
                                            {finalTicket?.orderTicket?.seats &&
                                              seatsArray &&
                                              seatsArray.length > 0 ? (
                                              <div className="col-md-12">
                                                <div className="new-seat-info">
                                                  <h6>
                                                    {seatsArray &&
                                                      seatsArray.length > 1
                                                      ? "Seats"
                                                      : "Seat"}{" "}
                                                    INFO
                                                  </h6>
                                                  {seatsArray &&
                                                    seatsArray.length > 0 ? (
                                                    <div className="select-seat-number-final new-ticket-box">
                                                      {seatsArray.map(
                                                        (seat, index) => (
                                                          <div
                                                            className="seat-number-final"
                                                            key={index}
                                                            style={
                                                              theme ===
                                                                "inoxTheme"
                                                                ? {
                                                                  backgroundColor:
                                                                    "#DCE2EF",
                                                                }
                                                                : {
                                                                  backgroundColor:
                                                                    "#FFF0D6",
                                                                }
                                                            }
                                                          >
                                                            <p>{seat}</p>
                                                          </div>
                                                        )
                                                      )}
                                                    </div>
                                                  ) : (
                                                    ""
                                                  )}
                                                </div>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            {finalTicket?.familyBundle_data ? (
                                              <>
                                                <div className="col-md-12">
                                                  <div className="new-seat-info">
                                                    <h6>Vouchers</h6>
                                                    <div className="row">
                                                      <div className="col-3">
                                                        <span>
                                                          {
                                                            finalTicket
                                                              ?.familyBundle_data
                                                              ?.voucherDesc
                                                          }{" "}
                                                        </span>
                                                      </div>
                                                      <div className="col-3">
                                                        <span className="familyb-original-price">
                                                          ₹
                                                          {
                                                            finalTicket
                                                              ?.familyBundle_data
                                                              ?.totalPrice
                                                          }
                                                        </span>
                                                        <span className="familyb-discounted-price">
                                                          ₹
                                                          {
                                                            finalTicket
                                                              ?.familyBundle_data
                                                              ?.discountPrice
                                                          }
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <div className="col-12">
                                                      {
                                                        finalTicket
                                                          ?.familyBundle_data
                                                          ?.vouchers
                                                      }
                                                    </div>
                                                    <div
                                                      className="col-12 pointer familyb-view-qr-code"
                                                      onClick={() =>
                                                        setVisible(true)
                                                      }
                                                    >
                                                      <img
                                                        src={QrCodeIcon}
                                                        className="familyb-qr-icon"
                                                      />{" "}
                                                      {"View QR Code"}
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                          <div className="final-ticket-divider"></div>
                                          <div className="row">
                                            <div className="col-md-3">
                                              <div className="date-new-ticket">
                                                <h6>BOOKING ID</h6>
                                                <p>{finalTicket?.bookingId}</p>
                                              </div>
                                            </div>
                                            <div className="col-md-3">
                                              <div className="date-new-ticket">
                                                <h6>TRACK ID</h6>
                                                <p>{finalTicket?.orderIdEx}</p>
                                              </div>
                                            </div>
                                            <div className="col-md-3">
                                              <div className="date-new-ticket">
                                                <h6>BOOKING DATE</h6>
                                                <p>
                                                  <b>
                                                    {formatBookDate(
                                                      finalTicket?.bookingTime
                                                    )}
                                                  </b>
                                                </p>
                                              </div>
                                            </div>
                                            <div className="col-md-3">
                                              <div className="date-new-ticket">
                                                <h6>BOOKING TIME</h6>
                                                <p>
                                                  <b>
                                                    {formatBookTime(
                                                      finalTicket?.bookingTime
                                                    )}
                                                  </b>
                                                </p>
                                              </div>
                                            </div>
                                            <div className="col-md-3">
                                              <div className="date-new-ticket">
                                                <h6>Payment mode</h6>
                                                <p>{finalTicket?.payMode}</p>
                                              </div>
                                            </div>
                                            <div className="col-md-3">
                                              <div className="date-new-ticket">
                                                <h6>State GST No.</h6>
                                                <p>
                                                  {
                                                    finalTicket?.orderFilmCinema
                                                      ?.stateGSTN
                                                  }
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="final-ticket-divider"></div>
                                    </div>
                                    <div className="col-md-2">
                                      <div className="Ticket_summary-new">
                                        <h5>Ticket Summary:</h5>
                                      </div>
                                    </div>
                                    <div className="col-md-10">
                                      <div className="row">
                                        <div className="col-md-3">
                                          <div className="date-new-ticket">
                                            <h6>
                                              AMOUNT (
                                              {
                                                finalTicket?.orderTicket
                                                  ?.numOfSeats
                                              }{" "}
                                              X{" "}
                                              {
                                                finalTicket?.orderTicket
                                                  ?.ticketPrice
                                              }
                                              )
                                            </h6>
                                            <p>
                                              <b>INR</b>{" "}
                                              {
                                                finalTicket?.orderTicket
                                                  ?.totalTicketPrice
                                              }
                                            </p>
                                          </div>
                                        </div>
                                        {finalTicket?.orderTicket?.surcharge ? (
                                          <div className="col-md-3">
                                            <div className="date-new-ticket">
                                              <h6>SURCHARGE AMOUNT</h6>
                                              <p>
                                                <b>
                                                  {
                                                    finalTicket?.orderTicket
                                                      ?.surchargeAmount
                                                  }
                                                </b>
                                              </p>
                                            </div>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                        <div className="col-md-3">
                                          <div className="date-new-ticket">
                                            <h6>CONVENIENCE FEE</h6>
                                            <p>
                                              <b>
                                                {finalTicket?.orderTicket?.conv}
                                              </b>
                                            </p>
                                          </div>
                                        </div>
                                        <div className="col-md-3">
                                          <div className="date-new-ticket">
                                            <h6>CGST+SGST AMOUNT</h6>
                                            <p>
                                              <b>
                                                {
                                                  finalTicket?.orderTicket
                                                    ?.convGst
                                                }
                                              </b>
                                            </p>
                                          </div>
                                        </div>
                                        {/* <div className="col-md-3">
                                          <div className="date-new-ticket">
                                            <h6>GIFT CARD</h6>
                                            <p>
                                              <b>{""}</b>
                                            </p>
                                          </div>
                                        </div> */}
                                        <div className="col-md-3">
                                          {finalTicket?.cvp_data ? (
                                            <div className="date-new-ticket">
                                              <h6>
                                                CVP VOUCHER:{" "}
                                                {finalTicket?.cvp_data?.id}
                                              </h6>
                                              <p>
                                                <span
                                                  className="text-success"
                                                  style={{
                                                    textDecoration:
                                                      "line-through",
                                                  }}
                                                >
                                                  <b>INR</b>{" "}
                                                  {
                                                    finalTicket?.cvp_data
                                                      ?.actualPrice
                                                  }
                                                </span>{" "}
                                                <b>INR</b>{" "}
                                                {
                                                  finalTicket?.cvp_data
                                                    ?.discountPrice
                                                }
                                              </p>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    {seatsGSTArray &&
                                      seatsGSTArray.length > 0 ? (
                                      <>
                                        <div className="col-md-12">
                                          <div className="final-ticket-divider"></div>
                                        </div>
                                        <div className="col-md-2">
                                          <div className="Ticket_summary-new">
                                            <h5>GST Invoice No.</h5>
                                          </div>
                                        </div>
                                        <div className="col-md-10">
                                          {seatsArray &&
                                            seatsArray.length > 0 ? (
                                            <div className="row">
                                              {seatsArray.map((seat, index) => (
                                                <div
                                                  className="col-md-3"
                                                  key={index}
                                                >
                                                  <div className="date-new-ticket">
                                                    <h6>Ticket {seat}</h6>
                                                    {seatsGSTArray[index] && (
                                                      <p>
                                                        {seatsGSTArray[index]}
                                                      </p>
                                                    )}
                                                  </div>
                                                </div>
                                              ))}
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    <div className="col-md-12">
                                      <div className="final-ticket-divider"></div>
                                    </div>
                                    {finalTicket?.orderFood?.foods.length >
                                      0 ? (
                                      <>
                                        <div className="col-md-2">
                                          <div className="Ticket_summary-new">
                                            <h5>Food Summary:</h5>
                                          </div>
                                        </div>
                                        <div className="col-md-10">
                                          <div className="new-food-beverage">
                                            <h6>FOOD & BEVERAGES</h6>
                                            <div className="food-list-new">
                                              {finalTicket?.orderFood?.foods.map(
                                                (item, index) => (
                                                  <div key={index} className="buyItem-list">
                                                    <div className="buyItem-listInn">
                                                      <div className="co-itemName">
                                                        {item?.foodType === 1 ?
                                                          <svg className="co-vNv" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <rect x="0.5" y="0.5" width="13" height="13" rx="1.5" stroke="#149752" />
                                                            <circle cx="6.99984" cy="6.99935" r="4.08333" fill="#149752" />
                                                          </svg>
                                                          : item?.foodType === 0 ?
                                                            <svg className="co-vNv" width="14" height="14" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                              <rect x="0.961426" y="0.964844" width="10.0769" height="10.0769" rx="1.5" stroke="#87001B" />
                                                              <path d="M9 8.80886H3L6.00011 3.18945L9 8.80886Z" fill="#87001B" />
                                                              <clipPath id="clip0_3452_1183">
                                                                <rect width="6" height="6" fill="#87001B" transform="translate(3 3)" />
                                                              </clipPath>
                                                            </svg>
                                                            : ""
                                                        }

                                                        <div>
                                                          <p className="co-itemNameP">{`${item?.name} X ${item?.quantity}`}</p>
                                                          {/* {item?.upgradeParentId && <span className='upgradeTag'>Upgraded</span>} */}
                                                        </div>
                                                      </div>
                                                      <div className="co-itemName chkoutbtn">
                                                        <p className="co-pricep">
                                                          ₹{((item?.quantity * item?.price) / 100).toFixed(2)}
                                                        </p>
                                                      </div>
                                                    </div>
                                                    {item?.addons?.length > 0 &&
                                                      <div className="co-addon-div" style={{ paddingLeft: "25px" }}>
                                                        Add ons:
                                                        {item?.addons?.map((addon, index) => {
                                                          return (
                                                            <div key={index} className="co-addon-inndiv">
                                                              <span>
                                                                <em>{`${addon?.name} x ${addon?.quantity}`}</em>
                                                              </span>
                                                              <span style={{ fontFamily: "sf-pro-text-medium" }}>₹{((addon?.price * addon?.quantity) / 100).toFixed(2)}</span>
                                                            </div>
                                                          );
                                                        })}
                                                      </div>
                                                    }
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="col-md-2">
                                              <div className="date-new-ticket">
                                                <h6>Food Value</h6>
                                                <p>
                                                  <b>
                                                    INR{" "}
                                                    {parseFloat(
                                                      finalTicket?.orderFood?.fbTotalPrice.replace(
                                                        /,/g,
                                                        ""
                                                      )
                                                    ).toFixed(2)}
                                                  </b>
                                                </p>
                                              </div>
                                            </div>
                                            <div className="date-new-ticket">
                                              <h6>Food Discount</h6>
                                              <p>
                                                <b>
                                                  INR{" "}
                                                  {parseFloat(
                                                    finalTicket?.orderFood?.fbDiscount.replace(
                                                      /,/g,
                                                      ""
                                                    )
                                                  ).toFixed(2)}
                                                </b>
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    <div className="col-md-2">
                                      {/* <div className="date-new-ticket">
                                                <h6>Food GST INvoice no.</h6>
                                                <p>GST0004</p>
                                              </div>
                                            </div> */}
                                    </div>
                                    <div className="col-md-10">
                                      <div className="grand-total-price-new">
                                        <h6>GRAND TOTAL</h6>
                                        <p>
                                          {parseFloat(
                                            finalTicket?.totalAmount
                                          ) !=
                                            parseFloat(
                                              finalTicket?.finalAmount
                                            ) ? (
                                            <b className="discount-price-cut">
                                              INR{" "}
                                              {parseFloat(
                                                finalTicket?.totalAmount.replace(
                                                  /,/g,
                                                  ""
                                                )
                                              ).toFixed(2)}
                                            </b>
                                          ) : (
                                            ""
                                          )}
                                          <b>
                                            INR{" "}
                                            {parseFloat(
                                              finalTicket?.finalAmount.replace(
                                                /,/g,
                                                ""
                                              )
                                            ).toFixed(2)}
                                          </b>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  {finalTicket?.paymodes?.couponType ==
                                    "PASSPORT" ? (
                                    <div className="passport-bg-ticket">
                                      <img
                                        src={PassportBG}
                                        alt=""
                                        className="img-fluid"
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section>
                          {finalTicket?.ca_a ? (
                            <div className="container view-container">
                              <div className="row">
                                <div className="col-md-12">
                                  {/* <div className="terms-ticket-cancel">
                                    <div className="row">
                                      <div className="col-md-12">
                                        <div className="now-ticket-new">
                                          <h3>Now, with ticket cancellation</h3>
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="refund-discount-new">
                                          <h5>
                                            <b>75%</b> Refund
                                          </h5>
                                          <p>Until 2 hours before show time</p>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="refund-discount-new">
                                          <h5>
                                            <b>50%</b> Refund
                                          </h5>
                                          <p>
                                            Until 2 hours before show
                                            timebetween 2 hours & 20 min before
                                            show start time
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="refund-discount-new">
                                          <h5>
                                            <b>100%</b> Refund
                                          </h5>
                                          <p>on Food & Beverages</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </section>
                        <section>
                          <div className="container view-container">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="terms-use-ticket">
                                  <h6>Terms of use</h6>
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        theme === "inoxTheme"
                                          ? inoxTnx
                                          : pvrTnc,
                                    }}
                                    className="px-2"
                                  ></p>
                                  <p>
                                    Customer Support: feedback@pvrcinemas.com,
                                    +91 8800900009, +91 8800 900008
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </>
                    )}
                    <div className="history-book">
                      <div
                        className="book-history pointer"
                        onClick={() => {
                          localStorage.setItem("myBooking_viewMode", 0);
                          if (isMobile) {
                            navigate("/m.profile");
                          } else {
                            navigate("/profile");
                          }
                        }}
                      >
                        <p>Go to Booking History</p>
                      </div>
                      <div className="history-arrow">
                        <img src={historyArrow} alt="" className="img-fluid" />
                      </div>
                    </div>
                    {/* <div className="mobile-passport-ticket">
                      {finalTicket?.ph?.length > 0 ? (
                        finalTicket?.ph?.map((item, idx) => (
                          <div className="mobile-placeholder" key={idx}>
                            <img src={item?.imageUrl} alt="" />
                          </div>
                        ))
                      ) : (
                        <img
                          src={BannerPromtion}
                          alt=""
                          className="img-fluid"
                        />
                      )}
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* desktop view ticket end */}

          {/* mobile view ticket start */}
          <section className="show-in-mobile-view">
            <div className="mobile-final-ticket-bg">
              <div className="container view-container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="mobile-enjoy-show">
                      {spalsh &&
                        spalsh?.google_Wallet == "true" &&
                        finalTicket?.googleWalletMessage &&
                        !finalTicket?.orderTicket?.flexiApply ? (
                        <div
                          className={
                            theme === "inoxTheme"
                              ? "mobile-google-wallet-inox"
                              : "mobile-google-wallet-pvr"
                          }
                        >
                          <div className="row">
                            <div className="col-2">
                              <div className="offer-final-img">
                                <img
                                  src={
                                    walletMsgArray.length > 2 &&
                                      walletMsgArray[2] == "2"
                                      ? PassportVoucher
                                      : theme === "inoxTheme"
                                        ? OfferIcon
                                        : OfferPVRICON
                                  }
                                  alt=""
                                  className="img-fluid"
                                  width={25}
                                  height={20}
                                />
                              </div>
                            </div>
                            <div className="col-10">
                              <div className="offer-final-details">
                                {/* <h6>{finalTicket?.googleWalletMessage}</h6> */}
                                <h6>
                                  {walletMsgArray.length > 0
                                    ? walletMsgArray[0]
                                    : ""}
                                </h6>
                                <p>
                                  {walletMsgArray.length > 1
                                    ? walletMsgArray[1]
                                    : ""}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <img src={check} alt="" className="img-fluid" />
                      <h4>Enjoy your show </h4>
                      <p>
                        Thank you for choosing PVR INOX! E-Order details have
                        been shared on your registered email ID and Phone Number
                      </p>
                    </div>
                    {loading ? (
                      <FinalTicketSkeleton />
                    ) : (
                      <>
                        <section>
                          {finalTicket?.orderTicket?.flexiApply ? (
                            <div
                              className="flexi-booking-confirm-ticket"
                              style={
                                theme === "inoxTheme"
                                  ? { backgroundColor: "#DCE2EF" }
                                  : { backgroundColor: "#FFF0D6" }
                              }
                            >
                              <p>
                                <span className="on-time">
                                  <img
                                    src={OnTime}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </span>{" "}
                                <b>Upgraded to Flexi Ticket</b>{" "}
                                <span className="info-flexi">
                                  <img
                                    onClick={() => setShowflexiHowitwork(true)}
                                    src={InfoFlexi}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </span>
                                {finalTicket?.orderTicket?.ticketsRefunded >
                                  0 ? (
                                  <span>{`${finalTicket?.orderTicket?.ticketsRefunded
                                    } ${finalTicket?.orderTicket?.ticketsRefunded >
                                      1
                                      ? "Tickets"
                                      : "Ticket"
                                    }  Refunded!`}</span>
                                ) : (
                                  ""
                                )}
                              </p>
                              <p>
                                Exit anytime you want, and get a partial refund!
                              </p>
                              <div className="how-final-ticket">
                                <button
                                  className={
                                    theme === "inoxTheme"
                                      ? "how-finali-inox"
                                      : "how-finali-pvr"
                                  }
                                  onClick={() => setShowflexiHiw(true)}
                                >
                                  How it works?
                                </button>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {finalTicket?.orderTicket?.flexiApply ? (
                            <div className="flexi-note">
                              <p>
                                Please note, we have sent seat-specific bookings
                                and QR Codes; scroll to view all.
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          {finalTicket?.orderTicket?.flexiApply ? (
                            <div className="Ticket-of-flexi">
                              <p>
                                Ticket {countTicket + 1} of{" "}
                                {finalTicket?.invoiceData?.length}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="mobile-container">
                            {finalTicket?.paymodes?.couponType == "PASSPORT" ? (
                              <div className="passport-mobile-bg">
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="week-passprt-mobile">
                                      <img
                                        src={PassportWeeked}
                                        alt=""
                                        className=""
                                      />
                                    </div>
                                  </div>
                                  <div className="passport-divider-mobile"></div>
                                  <div className="col-6">
                                    <div className="user-mobile">
                                      <h3>Name</h3>
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="user-mobile-view">
                                      <h3>{loginInfo?.data?.un}</h3>
                                    </div>
                                  </div>
                                  {loginInfo?.data?.dob ? (
                                    <>
                                      <div className="col-6">
                                        <div className="user-mobile">
                                          <h3>DOB</h3>
                                        </div>
                                      </div>
                                      <div className="col-6">
                                        <div className="user-mobile-view">
                                          <h3>{loginInfo?.data?.dob}</h3>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  <div className="col-md-12">
                                    <div className="kindly-mobile">
                                      <p>
                                        <img
                                          src={UserBook}
                                          alt=""
                                          className="img-fluid"
                                        />{" "}
                                        Kindly Carry Your Valid Photo ID
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="Mobile-final-ticket-border">
                              {finalTicket?.orderTicket?.flexiApply ? (
                                <>
                                  <Swiper
                                    slidesPerView={1.1}
                                    spaceBetween={10}
                                    className="mySwiper"
                                    onSlideChange={(e) =>
                                      setCountTicket(e.activeIndex)
                                    }
                                  >
                                    {finalTicket?.invoiceData.map(
                                      (indata, index) => (
                                        <SwiperSlide>
                                          <div className="mobile-movie-info d-flex">
                                            <div className="final-poster-show-ticket">
                                              <img
                                                src={
                                                  finalTicket?.orderFilmCinema
                                                    ?.posterVert
                                                    ? finalTicket
                                                      ?.orderFilmCinema
                                                      ?.posterVert
                                                    : theme === "inoxTheme"
                                                      ? miv_inox
                                                      : miv_pvr
                                                }
                                                alt=""
                                                className=""
                                                width={100}
                                              />
                                              <div className="ticket-d">
                                                <h6>Ticket: {indata?.seats}</h6>
                                              </div>{" "}
                                            </div>
                                            <div className="mobile-ticket-details mx-2">
                                              <h3>
                                                {
                                                  finalTicket?.orderFilmCinema
                                                    ?.filmName
                                                }
                                              </h3>
                                              <div className="gener-new">
                                                <ul>
                                                  <li>
                                                    {
                                                      finalTicket
                                                        ?.orderFilmCinema
                                                        ?.certificate
                                                    }
                                                  </li>
                                                  {finalTicket?.orderFilmCinema
                                                    ?.genre ? (
                                                    <li>
                                                      <span className="dot-sb"></span>
                                                      {
                                                        finalTicket
                                                          ?.orderFilmCinema
                                                          ?.genre
                                                      }
                                                    </li>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {finalTicket?.orderFilmCinema
                                                    ?.language ? (
                                                    <li>
                                                      <span className="dot-sb"></span>
                                                      {
                                                        finalTicket
                                                          ?.orderFilmCinema
                                                          ?.language
                                                      }
                                                    </li>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {finalTicket?.orderFilmCinema
                                                    ?.format ? (
                                                    <li>
                                                      <span className="dot-sb"></span>
                                                      {
                                                        finalTicket
                                                          ?.orderFilmCinema
                                                          ?.format
                                                      }
                                                    </li>
                                                  ) : (
                                                    ""
                                                  )}
                                                </ul>
                                              </div>

                                              <h5>
                                                {
                                                  finalTicket?.orderFilmCinema
                                                    ?.theaterName
                                                }
                                              </h5>
                                              <p>{finalTicket?.address}</p>
                                              <p className="new-direction">
                                                Get Direction
                                                <span className="direction-new">
                                                  <a
                                                    href={`https://maps.google.com/?q=${finalTicket?.ltd},${finalTicket?.lngt}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                  >
                                                    <img
                                                      src={direction}
                                                      alt=""
                                                      className="img-fluid"
                                                    />
                                                  </a>
                                                </span>
                                              </p>
                                            </div>
                                          </div>
                                          <div className="final-ticket-divider"></div>
                                          <div className="mobile-share-qr-code row">
                                            <div className="col-5">
                                              <img
                                                src={indata?.qr}
                                                alt=""
                                                className="img-fluid qr-final-ticket-mobile"
                                                width={100}
                                              />
                                            </div>
                                            <div className="col-7">
                                              <div className="">
                                                <div className="date-new-ticket">
                                                  <h6>DATE & TIME</h6>
                                                  <p>
                                                    <b>
                                                      {finalTicket?.showTimeStr}
                                                    </b>
                                                  </p>
                                                </div>
                                                {/* <div className="date-new-ticket">
                                      <h6></h6>
                                      <p>
                                        <b>{finalTicket?.showTimeStr}</b>
                                      </p>
                                    </div> */}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="row">
                                            {finalTicket?.orderTicket?.seats ? (
                                              <div className="col-5">
                                                <div className="date-new-ticket">
                                                  <h6>Screen</h6>
                                                  <p>
                                                    {
                                                      finalTicket?.orderTicket
                                                        ?.audi
                                                    }
                                                  </p>
                                                </div>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            <div className="col-7">
                                              <div className="date-new-ticket">
                                                <h6>Class</h6>
                                                <p>
                                                  {
                                                    finalTicket?.orderTicket
                                                      ?.showClassName
                                                  }
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          {finalTicket?.orderTicket?.seats &&
                                            seatsArray &&
                                            seatsArray.length > 0 ? (
                                            <div className="mx-2">
                                              <div className="new-seat-info">
                                                <h6>
                                                  {seatsArray &&
                                                    seatsArray.length > 1
                                                    ? "Seats"
                                                    : "Seat"}{" "}
                                                  INFO
                                                </h6>
                                                {seatsArray &&
                                                  seatsArray.length > 0 ? (
                                                  <div className="select-seat-number-final new-ticket-box">
                                                    <div
                                                      className="seat-number-final"
                                                      style={
                                                        theme === "inoxTheme"
                                                          ? {
                                                            backgroundColor:
                                                              "#DCE2EF",
                                                          }
                                                          : {
                                                            backgroundColor:
                                                              "#FFF0D6",
                                                          }
                                                      }
                                                      key={index}
                                                    >
                                                      <p>{indata?.seats}</p>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          {finalTicket?.familyBundle_data ? (
                                            <>
                                              <div className="divider-dash-book"></div>
                                              <div className="mx-2">
                                                <div className="new-seat-info">
                                                  <h6>Vouchers</h6>
                                                  <div className="row">
                                                    <div className="col-6">
                                                      <span>
                                                        {
                                                          finalTicket
                                                            ?.familyBundle_data
                                                            ?.voucherDesc
                                                        }{" "}
                                                      </span>
                                                    </div>
                                                    <div className="col-6">
                                                      <span className="familyb-original-price">
                                                        ₹
                                                        {
                                                          finalTicket
                                                            ?.familyBundle_data
                                                            ?.totalPrice
                                                        }
                                                      </span>
                                                      <span className="familyb-discounted-price">
                                                        ₹
                                                        {
                                                          finalTicket
                                                            ?.familyBundle_data
                                                            ?.discountPrice
                                                        }
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div className="col-12">
                                                    {
                                                      finalTicket
                                                        ?.familyBundle_data
                                                        ?.vouchers
                                                    }
                                                  </div>
                                                  <div
                                                    className="col-12 pointer familyb-view-qr-code"
                                                    onClick={() =>
                                                      setVisible(true)
                                                    }
                                                  >
                                                    <img
                                                      src={QrCodeIcon}
                                                      className="familyb-qr-icon"
                                                    />{" "}
                                                    {"View QR Code"}
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </SwiperSlide>
                                      )
                                    )}
                                  </Swiper>
                                </>
                              ) : (
                                <>
                                  <div className="mobile-movie-info d-flex">
                                    <div className="final-poster-show-ticket">
                                      <img
                                        src={
                                          finalTicket?.orderFilmCinema
                                            ?.posterVert
                                            ? finalTicket?.orderFilmCinema
                                              ?.posterVert
                                            : theme === "inoxTheme"
                                              ? miv_inox
                                              : miv_pvr
                                        }
                                        alt=""
                                        className=""
                                        width={100}
                                      />
                                    </div>
                                    <div className="mobile-ticket-details mx-2">
                                      <h3>
                                        {finalTicket?.orderFilmCinema?.filmName}
                                      </h3>
                                      <div className="gener-new">
                                        <ul>
                                          <li>
                                            {
                                              finalTicket?.orderFilmCinema
                                                ?.certificate
                                            }
                                          </li>
                                          {finalTicket?.orderFilmCinema
                                            ?.genre ? (
                                            <li>
                                              <span className="dot-sb"></span>
                                              {
                                                finalTicket?.orderFilmCinema
                                                  ?.genre
                                              }
                                            </li>
                                          ) : (
                                            ""
                                          )}
                                          {finalTicket?.orderFilmCinema
                                            ?.language ? (
                                            <li>
                                              <span className="dot-sb"></span>
                                              {
                                                finalTicket?.orderFilmCinema
                                                  ?.language
                                              }
                                            </li>
                                          ) : (
                                            ""
                                          )}
                                          {finalTicket?.orderFilmCinema
                                            ?.format ? (
                                            <li>
                                              <span className="dot-sb"></span>
                                              {
                                                finalTicket?.orderFilmCinema
                                                  ?.format
                                              }
                                            </li>
                                          ) : (
                                            ""
                                          )}
                                        </ul>
                                      </div>

                                      <h5>
                                        {
                                          finalTicket?.orderFilmCinema
                                            ?.theaterName
                                        }
                                      </h5>
                                      <p>{finalTicket?.address}</p>
                                      <p className="new-direction">
                                        Get Direction
                                        <span className="direction-new">
                                          <a
                                            href={`https://maps.google.com/?q=${finalTicket?.ltd},${finalTicket?.lngt}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            <img
                                              src={direction}
                                              alt=""
                                              className="img-fluid"
                                            />
                                          </a>
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="final-ticket-divider"></div>
                                  <div className="mobile-share-qr-code row">
                                    <div className="col-5">
                                      <img
                                        src={finalTicket?.qr}
                                        alt=""
                                        className="img-fluid qr-final-ticket-mobile"
                                        width={100}
                                      />
                                    </div>
                                    <div className="col-7">
                                      <div className="">
                                        <div className="date-new-ticket">
                                          <h6>DATE & TIME</h6>
                                          <p>
                                            <b>{finalTicket?.showTimeStr}</b>
                                          </p>
                                        </div>
                                        {/* <div className="date-new-ticket">
                                      <h6></h6>
                                      <p>
                                        <b>{finalTicket?.showTimeStr}</b>
                                      </p>
                                    </div> */}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    {finalTicket?.orderTicket?.seats ? (
                                      <div className="col-5">
                                        <div className="date-new-ticket">
                                          <h6>Screen</h6>
                                          <p>
                                            {finalTicket?.orderTicket?.audi}
                                          </p>
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    <div className="col-7">
                                      <div className="date-new-ticket">
                                        <h6>Class</h6>
                                        <p>
                                          {
                                            finalTicket?.orderTicket
                                              ?.showClassName
                                          }
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  {finalTicket?.orderTicket?.seats &&
                                    seatsArray &&
                                    seatsArray.length > 0 ? (
                                    <div className="mx-2">
                                      <div className="new-seat-info">
                                        <h6>
                                          {seatsArray && seatsArray.length > 1
                                            ? "Seats"
                                            : "Seat"}{" "}
                                          INFO
                                        </h6>
                                        {seatsArray && seatsArray.length > 0 ? (
                                          <div className="select-seat-number-final new-ticket-box">
                                            {seatsArray.map((seat, index) => (
                                              <div
                                                className="seat-number-final"
                                                style={
                                                  theme === "inoxTheme"
                                                    ? {
                                                      backgroundColor:
                                                        "#DCE2EF",
                                                    }
                                                    : {
                                                      backgroundColor:
                                                        "#FFF0D6",
                                                    }
                                                }
                                                key={index}
                                              >
                                                <p>{seat}</p>
                                              </div>
                                            ))}
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {finalTicket?.familyBundle_data ? (
                                    <>
                                      <div className="divider-dash-book"></div>
                                      <div className="mx-2">
                                        <div className="new-seat-info">
                                          <h6>Vouchers</h6>
                                          <div className="row">
                                            <div className="col-6">
                                              <span>
                                                {
                                                  finalTicket?.familyBundle_data
                                                    ?.voucherDesc
                                                }{" "}
                                              </span>
                                            </div>
                                            <div className="col-6">
                                              <span className="familyb-original-price">
                                                ₹{" "}
                                                {
                                                  finalTicket?.familyBundle_data
                                                    ?.totalPrice
                                                }
                                              </span>
                                              <span className="familyb-discounted-price">
                                                ₹
                                                {
                                                  finalTicket?.familyBundle_data
                                                    ?.discountPrice
                                                }
                                              </span>
                                            </div>
                                          </div>
                                          <div className="col-12">
                                            {
                                              finalTicket?.familyBundle_data
                                                ?.vouchers
                                            }
                                          </div>
                                          <div
                                            className="col-12 pointer familyb-view-qr-code"
                                            onClick={() => setVisible(true)}
                                          >
                                            <img
                                              src={QrCodeIcon}
                                              className="familyb-qr-icon"
                                            />{" "}
                                            {"View QR Code"}
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </>
                              )}
                              <div className="show-more-finals">
                                <div
                                  className="d-flex justify-content-between mx-2"
                                  onClick={() =>
                                    setMobileTicketShowMoreViewMode(
                                      !mobileTicketShowMoreViewMode
                                    )
                                  }
                                >
                                  <span className="Show-more-detail-final">
                                    <u>
                                      {mobileTicketShowMoreViewMode
                                        ? "Show Less Details"
                                        : "Show More Details"}
                                    </u>
                                  </span>
                                  <span>
                                    <i
                                      className={
                                        mobileTicketShowMoreViewMode
                                          ? "pi pi-angle-up"
                                          : "pi pi-angle-down"
                                      }
                                    />{" "}
                                  </span>
                                </div>
                              </div>
                              <div className="finalticket-gap"></div>
                              {mobileTicketShowMoreViewMode ? (
                                <>
                                  <div className="">
                                    <div className="d-flex mx-2">
                                      <div className="w-50">
                                        <div className="date-new-ticket">
                                          <h6>BOOKING ID</h6>
                                          <p>{finalTicket?.bookingId}</p>
                                        </div>
                                      </div>
                                      <div className="w-50">
                                        <div className="date-new-ticket">
                                          <h6>TRACK ID</h6>
                                          <p>{finalTicket?.orderIdEx}</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="d-flex mx-2">
                                      <div className="w-50">
                                        <div className="date-new-ticket">
                                          <h6>BOOKING DATE</h6>
                                          <p>
                                            <b>
                                              {formatBookDate(
                                                finalTicket?.bookingTime
                                              )}
                                            </b>
                                          </p>
                                        </div>
                                      </div>
                                      <div className="w-50">
                                        <div className="date-new-ticket">
                                          <h6>BOOKING TIME</h6>
                                          <p>
                                            <b>
                                              {formatBookTime(
                                                finalTicket?.bookingTime
                                              )}
                                            </b>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="d-flex mx-2">
                                      <div className="w-50">
                                        <div className="date-new-ticket">
                                          <h6>Payment mode</h6>
                                          <p>{finalTicket?.payMode}</p>
                                        </div>
                                      </div>
                                      <div className="w-50">
                                        <div className="date-new-ticket">
                                          <h6>State GST No.</h6>
                                          <p>
                                            {
                                              finalTicket?.orderFilmCinema
                                                ?.stateGSTN
                                            }
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <Divider
                                    align="center"
                                    type="dashed"
                                    style={{ margin: "5px 0" }}
                                  />
                                  <div className="">
                                    <div className="Ticket_summary-new-mobile">
                                      <h5>Ticket Summary:</h5>
                                    </div>
                                  </div>
                                  <div className="">
                                    <div className="row">
                                      <div className="col-sm-3">
                                        <div className="date-new-ticket">
                                          <h6>
                                            AMOUNT (
                                            {
                                              finalTicket?.orderTicket
                                                ?.numOfSeats
                                            }{" "}
                                            X{" "}
                                            {
                                              finalTicket?.orderTicket
                                                ?.ticketPrice
                                            }
                                            )
                                          </h6>
                                          <p>
                                            <b>INR</b>{" "}
                                            {
                                              finalTicket?.orderTicket
                                                ?.totalTicketPrice
                                            }
                                          </p>
                                        </div>
                                      </div>
                                      {finalTicket?.orderTicket?.surcharge ? (
                                        <div className="col-sm-3">
                                          <div className="date-new-ticket">
                                            <h6>SURCHARGE AMOUNT</h6>
                                            <p>
                                              <b>
                                                {
                                                  finalTicket?.orderTicket
                                                    ?.surchargeAmount
                                                }
                                              </b>
                                            </p>
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      <div className="col-sm-3">
                                        <div className="date-new-ticket">
                                          <h6>CONVENIENCE FEE</h6>
                                          <p>
                                            <b>
                                              {finalTicket?.orderTicket?.conv}
                                            </b>
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-sm-3">
                                        <div className="date-new-ticket">
                                          <h6>CGST+SGST AMOUNT</h6>
                                          <p>
                                            <b>
                                              {
                                                finalTicket?.orderTicket
                                                  ?.convGst
                                              }
                                            </b>
                                          </p>
                                        </div>
                                      </div>
                                      {/* <div className="col-sm-3">
                                        <div className="date-new-ticket">
                                          <h6>GIFT CARD</h6>
                                          <p>
                                            <b>{""}</b>
                                          </p>
                                        </div>
                                      </div> */}
                                      <div className="col-md-3">
                                        {finalTicket?.cvp_data ? (
                                          <div className="date-new-ticket">
                                            <h6>
                                              CVP VOUCHER:{" "}
                                              {finalTicket?.cvp_data?.id}
                                            </h6>
                                            <p>
                                              <span
                                                className="text-success"
                                                style={{
                                                  textDecoration:
                                                    "line-through",
                                                }}
                                              >
                                                <b>INR</b>{" "}
                                                {
                                                  finalTicket?.cvp_data
                                                    ?.actualPrice
                                                }
                                              </span>{" "}
                                              <b>INR</b>{" "}
                                              {
                                                finalTicket?.cvp_data
                                                  ?.discountPrice
                                              }
                                            </p>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  {seatsGSTArray && seatsGSTArray.length > 0 ? (
                                    <>
                                      <Divider
                                        align="center"
                                        type="dashed"
                                        style={{ margin: "5px 0" }}
                                      />
                                      <div className="">
                                        <div className="Ticket_summary-new-mobile">
                                          <h5>GST Invoice No.</h5>
                                        </div>
                                      </div>
                                      <div className="">
                                        {seatsArray && seatsArray.length > 0 ? (
                                          <div className="row">
                                            {seatsArray.map((seat, index) => (
                                              <div
                                                className="col-6"
                                                key={index}
                                              >
                                                <div className="date-new-ticket">
                                                  <h6>Ticket {seat}</h6>
                                                  {seatsGSTArray[index] && (
                                                    <p>
                                                      {seatsGSTArray[index]}
                                                    </p>
                                                  )}
                                                </div>
                                              </div>
                                            ))}
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  <div className="final-ticket-divider"></div>
                                </>
                              ) : (
                                ""
                              )}

                              {finalTicket?.orderFood?.foods.length > 0 ? (
                                <>
                                  <div className="mobile-foodbeverage-final">
                                    <div className="mobile-Ticket_summary-new">
                                      <h5>Food Summary:</h5>
                                    </div>

                                    <div className="col-sm-10">
                                      <div className="mobile-new-food-beverage">
                                        <h6>FOOD & BEVERAGES</h6>
                                        <div className="mobile-food-list-new">
                                          {finalTicket?.orderFood?.foods.map(
                                            (item, index) => (
                                              <div key={index} className="buyItem-list">
                                                <div className="buyItem-listInn">
                                                  <div className="co-itemName">
                                                    {item?.foodType === 1 ?
                                                      <svg className="co-vNv" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect x="0.5" y="0.5" width="13" height="13" rx="1.5" stroke="#149752" />
                                                        <circle cx="6.99984" cy="6.99935" r="4.08333" fill="#149752" />
                                                      </svg>
                                                      : item?.foodType === 0 ?
                                                        <svg className="co-vNv" width="14" height="14" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                          <rect x="0.961426" y="0.964844" width="10.0769" height="10.0769" rx="1.5" stroke="#87001B" />
                                                          <path d="M9 8.80886H3L6.00011 3.18945L9 8.80886Z" fill="#87001B" />
                                                          <clipPath id="clip0_3452_1183">
                                                            <rect width="6" height="6" fill="#87001B" transform="translate(3 3)" />
                                                          </clipPath>
                                                        </svg>
                                                        : ""
                                                    }

                                                    <div>
                                                      <p className="co-itemNameP">{`${item?.name} X ${item?.quantity}`}</p>
                                                      {/* {item?.upgradeParentId && <span className='upgradeTag'>Upgraded</span>} */}
                                                    </div>
                                                  </div>
                                                  <div className="co-itemName chkoutbtn">
                                                    <p className="co-pricep">
                                                      ₹{((item?.quantity * item?.price) / 100).toFixed(2)}
                                                    </p>
                                                  </div>
                                                </div>
                                                {item?.addons?.length > 0 &&
                                                  <div className="co-addon-div" style={{ paddingLeft: "25px" }}>
                                                    Add ons:
                                                    {item?.addons?.map((addon, index) => {
                                                      return (
                                                        <div key={index} className="co-addon-inndiv">
                                                          <span>
                                                            <em>{`${addon?.name} x ${addon?.quantity}`}</em>
                                                          </span>
                                                          <span style={{ fontFamily: "sf-pro-text-medium" }}>₹{((addon?.price * addon?.quantity) / 100).toFixed(2)}</span>
                                                        </div>
                                                      );
                                                    })}
                                                  </div>
                                                }
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-sm-2">
                                          <div className="date-new-tickets">
                                            <h6>Food Value</h6>
                                            <p>
                                              <b>
                                                INR{" "}
                                                {parseFloat(finalTicket?.orderFood?.fbTotalPrice?.replace(/,/g, "" )).toFixed(2)}
                                              </b>
                                            </p>
                                          </div>
                                        </div>
                                        <div className="date-new-tickets">
                                          <h6>Food Discount</h6>
                                          <p>
                                            <b>
                                              INR{" "}
                                              {parseFloat(finalTicket?.orderFood?.fbDiscount.replace(/,/g, "" )).toFixed(2)}
                                            </b>
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-sm-2">
                                        <div className="col-sm-12">
                                          <div className="grand-total-price-new">
                                            <h6>GRAND TOTAL</h6>
                                            <p>
                                              <b>
                                                INR{" "}
                                                {parseFloat(finalTicket?.totalAmount.replace(/,/g, "" )).toFixed(2)}
                                              </b>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-12">
                                      <div className="final-ticket-divider"></div>
                                    </div>
                                    <div className="col-md-2"></div>
                                    <div className="col-md-10">
                                      <div className="col-md-12">
                                        <div className="grand-total-price-new">
                                          <h6>FINAL AMOUNT</h6>
                                          <p>
                                            <b>
                                              INR{" "}
                                              {parseFloat(
                                                finalTicket?.totalAmount.replace(
                                                  /,/g,
                                                  ""
                                                )
                                              ).toFixed(2)}
                                            </b>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </section>
                        <div className="card mt-3 Final-ticket-terms ">
                          <div
                            className="show-terms-final-mobile"
                            onClick={() =>
                              setMobileTicketTnCViewMode(
                                !mobileTicketTnCViewMode
                              )
                            }
                          >
                            <span>
                              Ticket cancellation, Terms of use & Customer
                              Support
                            </span>
                            <span>
                              {" "}
                              <i
                                className={
                                  mobileTicketTnCViewMode
                                    ? "pi pi-angle-up"
                                    : "pi pi-angle-down"
                                }
                              />{" "}
                            </span>
                          </div>
                          {mobileTicketTnCViewMode ? (
                            <>
                              <section className="mt-2 cancelrefund-mobile">
                                {!finalTicket?.ca_a ? (
                                  <div className="mobile-containerd ">
                                    {/* <div className="">
                                      <div className="col-md-12">
                                        <div className="now-ticket-new">
                                          <h3>Now, with ticket cancellation</h3>
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="refund-discount-new">
                                          <h5>
                                            <b>75%</b> Refund
                                          </h5>
                                          <p>Until 2 hours before show time</p>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="refund-discount-new">
                                          <h5>
                                            <b>50%</b> Refund
                                          </h5>
                                          <p>
                                            Until 2 hours before show
                                            timebetween 2 hours & 20 min before
                                            show start time
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="refund-discount-new">
                                          <h5>
                                            <b>100%</b> Refund
                                          </h5>
                                          <p>on Food & Beverages</p>
                                        </div>
                                      </div>
                                    </div> */}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </section>
                              <section>
                                <div className="mobile-containerd mt-2">
                                  <div className="mobie-terms-use-ticket">
                                    <h6>Terms of use</h6>
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          theme === "inoxTheme"
                                            ? inoxTnx
                                            : pvrTnc,
                                      }}
                                      className="px-2"
                                    ></p>
                                    {/* {theme === 'inoxTheme' ? '' :(
                                      
                                    )
                                    } */}
                                    <div className="customer-divider"></div>
                                    <p>
                                      Customer Support: feedback@pvrcinemas.com,
                                      +91 8800900009, +91 8800 900008
                                    </p>
                                  </div>
                                </div>
                              </section>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                        {/* <div className="election-logo-ticket">
                          <img src={Election} alt="" className="img-fluid" />
                        </div> */}
                      </>
                    )}
                    <div className="history-book">
                      <div
                        className="book-history pointer"
                        onClick={() => {
                          localStorage.setItem("myBooking_viewMode", 0);
                          if (isMobile) {
                            navigate("/m.profile");
                          } else {
                            navigate("/profile");
                          }
                        }}
                      >
                        <p>Go to Booking History</p>
                      </div>
                      <div className="history-arrow">
                        <img src={historyArrow} alt="" className="img-fluid" />
                      </div>
                    </div>
                    {/* <div className="row">
                      <div className="col-md-12 col-sm-12">
                        <div className="mobile-passport-ticket">
                          {finalTicket?.ph?.length > 0 ? (
                            finalTicket?.ph?.map((item, idx) => (
                              <div className="mobile-placeholder" key={idx}>
                                <img src={item?.imageUrl} alt="" />
                              </div>
                            ))
                          ) : (
                            <img
                              src={BannerPromtion}
                              alt=""
                              className="img-fluid"
                            />
                          )}
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="show-in-mobile-view">
            <div className="mobile-download-ticket">
              {spalsh &&
                spalsh?.google_Wallet == "true" &&
                !finalTicket?.orderTicket?.flexiApply ? (
                <div className="modify-box text-center pb-3">
                  <GoogleWallet
                    orderID={finalTicket?.orderIdEx}
                    booktype="BOOKING"
                  />
                </div>
              ) : (
                ""
              )}
              <a
                href={finalTicket && finalTicket?.tu}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ProceedButton
                  className={
                    finalTicket?.tu
                      ? "download-ticket"
                      : "download-ticket btn-opacity"
                  }
                  onClick={() => hitGa("download_ticket")}
                >
                  <span className="download-tickets">
                    <img src={download} alt="" className="img-fluid" />
                  </span>
                  Download Ticket
                </ProceedButton>
              </a>
            </div>
          </section>
          <FlexiHowItWork
            visible={showflexihiw}
            content={finalTicket?.content}
            setVisible={setShowflexiHiw}
          />
          <FlexiHowTouse
            visible={showflexihowitwork}
            content={finalTicket?.content}
            setVisible={setShowflexiHowitwork}
          />
          <QrCodeView
            visible={visible}
            content={finalTicket}
            setVisible={setVisible}
          />
        </>
      )}
    </>
  );
};

export default FinalTicket;
