import React, { useEffect, useState } from "react";
import axios from "axios";
import { Sidebar } from "primereact/sidebar";
import * as appConstants from "../../../../constants/index";
import { useNavigate } from "react-router-dom";
import promotional_alert_icon from "../../../../assets/comingsoon/promotional-alerts.svg";
import arrow from "../../../../assets/profile/navigatenext.svg";
import angle_left from "../../../../assets/mobilemoviedetail/arrow-b.svg";
import whatsup_toggle_icon from "../../../../assets/comingsoon/whatsup.svg";
import sms_toggle_icon from "../../../../assets/comingsoon/sms.svg";
import { DarkButton } from "src/styled-components/customButtons";
import email_toggle_icon from "../../../../assets/comingsoon/emailers.svg";
import push_notificatiom_toggle_icon from "../../../../assets/comingsoon/push_notification.svg";
import SweetAlert from "react-bootstrap-sweetalert"; // Import SweetAlert

const ProfilePromotionalAlert = () => {
  const navigate = useNavigate();
  const theme = localStorage.getItem("theme");
  const isMobile = window.matchMedia("(max-width: 575px)").matches;
  const [visibleRight, setVisibleRight] = useState(false);
  const [sidebarViewMode, setSidebarViewMode] = useState(0);
  const [smsEnabled, setSmsEnabled] = useState(false);
  const [emailEnabled, setEmailEnabled] = useState(false);
  const [whatsappEnabled, setWhatsappEnabled] = useState(false);
  const [pushEnabled, setPushEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false); // State for SweetAlert
  const [whatsNotification, setWhatsNotification] = useState(false);
  const loginInfo = JSON.parse(sessionStorage.getItem("loginInfo") || "null");
  const token = sessionStorage.getItem("token");
  const fetchPromotionalData = async () => {
    try {
      setLoading(true);
      const token = sessionStorage.getItem("token");
      const city = localStorage.getItem("city");

      if (!token || !city) {
        throw new Error("Token or city is missing in localStorage");
      }

      const response = await axios.get(`${appConstants.base_url}v1/user/promotional/get`, {
        headers: {
          Authorization: `Bearer ${token}`,
          chain: theme === "inoxTheme" ? "INOX" : "PVR",
          city: city,
          appVersion: appConstants.av,
          platform: appConstants.pt,
          country: appConstants.cname,
        },
      });

      const promotionalData = response.data;

      if (!promotionalData || !promotionalData.output) {
        throw new Error("Unexpected API response structure");
      }

      setWhatsappEnabled(promotionalData.output.whatsapp || false);
      setSmsEnabled(promotionalData.output.sms || false);
      setEmailEnabled(promotionalData.output.email || false);
      setPushEnabled(promotionalData.output.notify || false);
    } catch (error) {
      console.error("Failed to fetch promotional data:", error.response || error.message || error);
      alert("Failed to fetch promotional data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (visibleRight) {
      fetchPromotionalData();
    }
  }, [visibleRight]);

  const handleBackClick = () => {
    setVisibleRight(false);
  };
  const hitGa = (event) => {
    let dataLayer = window["dataLayer"] || [];
    dataLayer.push({
      event: event,
    });
  };
  const whatsappCheckbox = async () => {
    const postData = {
      email: "",
      mobile: loginInfo?.data?.mob,
      name: "",
      otp: "",
      city: "",
      refreshToken: "",
      type: "",
      reason: "",
    };
    // console.log("post data", postData);
    try {
      await axios
        .post(`${appConstants.base_url}v1/user/optin/check`, postData, {
          headers: {
            Authorization: `Bearer ${token}`,
            chain: theme === "inoxTheme" ? "INOX" : "PVR",
            city: localStorage.getItem("city"),
            appVersion: appConstants.av,
            platform: appConstants.pt,
            country: appConstants.cname,
          },
        })
        .then((res) => {
          const dt = res.data;
          if (dt.result === "success") {
            setWhatsNotification(dt.output);
          } else {
            setLoading(false);
            // swal(res.data.msg);
          }
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const changeNotification = (e) => {
    if (e.target.checked === true) {
      hitGa("whatsapp_notification_alert_on")
      trueCheckbox();
    } else {
      hitGa("whatsapp_notification_alert_off")

      falseCheckbox();
    }
  };
  const trueCheckbox = async () => {
    const postData = {
      email: "",
      mobile: loginInfo?.data?.mob,
      name: "",
      otp: "",
      city: "",
      refreshToken: "",
      type: "",
      reason: "",
    };
    try {
      await axios
        .post(`${appConstants.base_url}v1/user/optin`, postData, {
          headers: {
            Authorization: `Bearer ${token}`,
            chain: theme === "inoxTheme" ? "INOX" : "PVR",
            city: localStorage.getItem("city"),
            appVersion: appConstants.av,
            platform: appConstants.pt,
            country: appConstants.cname,
          },
        })
        .then((res) => {
          const dt = res.data;
          if (dt.result === "success") {
            setWhatsNotification(dt.output);
          } else {
            setLoading(false);
            // swal(res.data.msg);
          }
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const falseCheckbox = async () => {
    const postData = {
      email: "",
      mobile: loginInfo?.data?.mob,
      name: "",
      otp: "",
      city: "",
      refreshToken: "",
      type: "",
      reason: "",
    };
    try {
      await axios
        .post(`${appConstants.base_url}v1/user/optout`, postData, {
          headers: {
            Authorization: `Bearer ${token}`,
            chain: theme === "inoxTheme" ? "INOX" : "PVR",
            city: localStorage.getItem("city"),
            appVersion: appConstants.av,
            platform: appConstants.pt,
            country: appConstants.cname,
          },
        })
        .then((res) => {
          const dt = res.data;
          if (dt.result === "success") {
            setWhatsNotification(dt.output);
          } else {
            setLoading(false);
            // swal(res.data.msg);
          }
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleApplyClick = async () => {
    try {
      setLoading(true);
      const token = sessionStorage.getItem("token");

      if (!token) {
        throw new Error("Token is missing in localStorage");
      }

      const response = await axios.post(
        `${appConstants.base_url}v1/user/promotional/set`,
        {
          whatsapp: whatsappEnabled,
          sms: smsEnabled,
          email: emailEnabled,
          notify: pushEnabled,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            chain: theme === "inoxTheme" ? "INOX" : "PVR",
            appVersion: appConstants.av,
            platform: appConstants.pt,
            country: appConstants.cname,
          },
        }
      );

      // Handle success response
      if (response.status === 200) {
        setShowAlert(true); // Show SweetAlert on success
      }
    } catch (error) {
      console.error("Failed to update promotional data:", error.response || error.message || error);
      alert("Failed to update promotional alerts. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div
        className="profile-img border-line-profile"
        onClick={() => {
          setVisibleRight(true);
          setSidebarViewMode(0);
        }}
      >
        <div className="profile-text profile-account">
          <h4>
            <span className="avtar">
              <img src={promotional_alert_icon} alt="" />
            </span>
          </h4>
          <div className="profile-text-info">
            <h2>Alert</h2>
          </div>
        </div>
        <div className="img-arrow">
          <img src={arrow} alt="" />
        </div>
      </div>
      <Sidebar
        visible={visibleRight}
        showCloseIcon={false}
        className="watchlist-show-m"
        style={{ width: "100vw" }}
        position={"right"}
        onHide={() => {
          setVisibleRight(false);
        }}
        dismissable={false}
        blockScroll={true}
        fullScreen
      >

        <div
          className={`bg-white show-in-mobile-view ${theme === "inoxTheme" ? "mobile-alert-btn-inox" : "mobile-alert-btn-pvr"}`}
          style={{ position: "sticky", top: "0", zIndex: "999" }}
        >
          <div className="watch-list-mobile-box">
            <span onClick={handleBackClick}>
              <img src={angle_left} alt="Back" />
            </span>
            <h4 style={{ textAlign: "center", width: "100%" }}>Alerts</h4>
          </div>
        </div>

        {loading ? (
          <div className="text-center mt-5"><h4>Loading...</h4></div>
        ) : (
          <>
            {/* Enable All Notifications */}
            <div className="optin-optout" style={{ marginTop: "20px" }}>
              <div className="alert-box">
                <div className="profile-text profile-account pb-0 pt-0">
                  <div className="profile-text-info">
                    <h2 className="enable_alert">Transactional Alerts</h2>
                    <p>Enable this to get your tickets information</p>
                  </div>
                </div>


                {/* WhatsApp Notifications */}
                <div
                  className={`profile-img border-line-profile watch-list-mobile-box ${theme === "inoxTheme" ? "mobile-alert-btn-inox" : "mobile-alert-btn-pvr"}`}
                  style={{ border: "0" }} >
                  <div className="profile-text profile-account">
                    <h4 style={{ marginLeft: "15px" }}>
                      <span className="avtar">
                        <img src={whatsup_toggle_icon} alt="WhatsApp Icon" />
                      </span>
                    </h4>
                    <div className="profile-text-info">
                      <h2>WhatsApp</h2>
                    </div>
                  </div>
                  <div className="toogle-checkbox">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={whatsNotification}
                        onChange={changeNotification}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>


              </div>



              <div
                className={`profile-img border-line-profile watch-list-mobile-box border-none pb-0 ${theme === "inoxTheme" ? "mobile-alert-btn-inox" : "mobile-alert-btn-pvr"}`}
              >
                <div className="profile-text profile-account pb-0 pt-0">
                  <div className="profile-text-info">
                    <h2 className="enable_alert">Enable Promotional Alerts</h2>
                  </div>
                </div>
                <div className="img-arrow toogle-checkbox">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={whatsappEnabled && smsEnabled && emailEnabled && pushEnabled}
                      onChange={() => {
                        const newValue = !(whatsappEnabled && smsEnabled && emailEnabled && pushEnabled);
                        setWhatsappEnabled(newValue);
                        setSmsEnabled(newValue);
                        setEmailEnabled(newValue);
                        setPushEnabled(newValue);
                      }}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              <div className="watch-list-mobile-box pb-0 pt-0">
                <p>Please select where all would you like to receive promotional alerts</p>
              </div>



              {/* SMS Notifications */}
              <div
                className={`profile-img border-line-profile watch-list-mobile-box ${theme === "inoxTheme" ? "mobile-alert-btn-inox" : "mobile-alert-btn-pvr"}`} >
                <div className="profile-text profile-account">
                  <h4>
                    <span className="avtar">
                      <img src={sms_toggle_icon} alt="SMS Icon" />
                    </span>
                  </h4>
                  <div className="profile-text-info">
                    <h2>SMS Notifications</h2>
                  </div>
                </div>
                <div className="img-arrow toogle-checkbox">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={smsEnabled}
                      onChange={() => setSmsEnabled(!smsEnabled)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>

              {/* WhatsApp Notifications */}
              <div
                className={`profile-img border-line-profile watch-list-mobile-box ${theme === "inoxTheme" ? "mobile-alert-btn-inox" : "mobile-alert-btn-pvr"}`}
              >
                <div className="profile-text profile-account">
                  <h4>
                    <span className="avtar">
                      <img src={whatsup_toggle_icon} alt="WhatsApp Icon" />
                    </span>
                  </h4>
                  <div className="profile-text-info">
                    <h2>WhatsApp</h2>
                  </div>
                </div>
                <div className="img-arrow toogle-checkbox">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={whatsappEnabled}
                      onChange={() => setWhatsappEnabled(!whatsappEnabled)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>

              {/* Email Notifications */}
              <div
                className={`profile-img border-line-profile watch-list-mobile-box ${theme === "inoxTheme" ? "mobile-alert-btn-inox" : "mobile-alert-btn-pvr"}`}
              >
                <div className="profile-text profile-account">
                  <h4>
                    <span className="avtar">
                      <img src={email_toggle_icon} alt="Email Icon" />
                    </span>
                  </h4>
                  <div className="profile-text-info">
                    <h2>Email Notifications</h2>
                  </div>
                </div>
                <div className="img-arrow toogle-checkbox">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={emailEnabled}
                      onChange={() => setEmailEnabled(!emailEnabled)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>

              {/* Push Notifications */}
              <div
                className={`profile-img border-line-profile watch-list-mobile-box ${theme === "inoxTheme" ? "mobile-alert-btn-inox" : "mobile-alert-btn-pvr"}`}
              >
                <div className="profile-text profile-account">
                  <h4>
                    <span className="avtar">
                      <img src={push_notificatiom_toggle_icon} alt="Push Notification Icon" />
                    </span>
                  </h4>
                  <div className="profile-text-info">
                    <h2>Push Notifications</h2>
                  </div>
                </div>
                <div className="img-arrow toogle-checkbox">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={pushEnabled}
                      onChange={() => setPushEnabled(!pushEnabled)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              <div className="border-line-profile border-none text-center">
                <div className="addmovies-mobiles optinsubmit">
                  <DarkButton className="addmovies-mobile" onClick={handleApplyClick}>
                    Submit
                  </DarkButton>
                </div>
              </div>
            </div>



          </>
        )}
      </Sidebar>

      {/* Success SweetAlert */}
      {showAlert && (
        <SweetAlert
          success
          title="Success!"
          confirmBtnCssClass={
            theme === "inoxTheme"
              ? "confirm-btn-inox border-0"
              : "confirm-btn-pvr border-0"
          }
          onConfirm={() => setShowAlert(false)}
        >
          Promotional alerts updated successfully.
        </SweetAlert>
      )}
    </>
  );
};

export default ProfilePromotionalAlert;
