import React, { useCallback, useEffect, useState } from "react";
import * as appConstants from "../../../constants/index";
import axios from "axios";
import { refraceTokenService } from "src/global-functions/refreshToken";
import { Link, useLocation, useNavigate } from "react-router-dom";
import miv_inox from "../../../assets/default-images/vertical-poster-inox.png";
import miv_pvr from "../../../assets/default-images/vertical-poster-pvr.svg";
import mih_pvr from "../../../assets/default-images/horizontal-poster-pvr.svg";
import mih_inox from "../../../assets/default-images/horizontal-poster-inox.png";
import noImage from "../../../assets/food/noimage.png";
import Cities from "src/views/common/cities/Cities";
import { useDispatch, useSelector } from "react-redux";
import MobileFooter from "../mobile-footer/MobileFooter";
import { Sidebar } from "primereact/sidebar";
import { InputText } from "primereact/inputtext";
import location from "../../../assets/nav-icon/location.svg";
import foodGIF from "../../../assets/food/food.gif";
import enablelocation from "../../../assets/food/enablelocation.svg";
import way from "../../../assets/search/ways.svg";
import angle_left from "../../../assets/mobilemoviedetail/arrow-b.svg";
import "./instafood.css";
import LikeIcon from "../../../assets/mobilemoviedetail/heart.svg";
import heart_pvr from "../../../assets/mobilemoviedetail/yellowheart.svg";
import heart_inox from "../../../assets/mobilemoviedetail/blueheart.svg";
import { addMinutes, setHours, format, setMinutes, isAfter, isBefore, getHours, getMinutes, isToday, isValid, parse } from 'date-fns';
import NowShowingSkeleton from "src/skeletons/NowShowingSkeleton";
import { monthsName } from "src/global-functions/globalFunctions";
import { Calendar } from "primereact/calendar";
import { ProceedButton } from "src/views/gift-cards/giftCardTags";
import backBtn from "../../../assets/food/backBtnn.svg";
import backBtndesk from '../../../assets/food/desktopBack.svg';
import locationIcon from '../../../assets/food/locationBlack.svg'
import FbHeader from "src/views/food-and-beverage/component/FbHeader";
import { Modal } from "react-bootstrap";
import Navbar from "src/views/common/navbar/Navbar";
import { getResponsiveClasses } from "src/views/food-and-beverage/component/ResponsiveClasses";
import Footer from "src/views/common/footer/Footer";
import SweetAlert from "react-bootstrap-sweetalert";
import SweetAlertUtility from "src/views/food-and-beverage/component/SweetAlertUtility";
import { setCityChangeData } from "src/redux/actions/cityAction";




const CinemaLocationList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = localStorage.getItem("theme");
  const modeVal = useSelector((state) => state.viewModeValue);
  const [cinemasData, setCinemasData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingSeat, setLoadingSeat] = useState(false);
  const [selectedCinemaId, setSelectedCinemaId] = useState("");
  const [visibleRight, setVisibleRight] = useState(true);
  const [filterCinema, setFilterCinema] = useState([]);
  const [daysList, setDaysList] = useState(null);
  const [theaterId, setTheaterId] = useState(null);
  const [theaterInfo, setTheaterInfo] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [time, setTime] = useState(addMinutes(new Date(), 30));
  const [date, setDate] = useState("");
  const [cinemaDistance, setCinemaDistance] = useState();
  const [maxtime, setMaxtime] = useState(addMinutes(new Date(), 30));
  const [mintime, setMintime] = useState("");
  const nineAM = setHours(setMinutes(new Date(), 0), 9); // 9:00 AM
  const elevenPM = setHours(setMinutes(new Date(), 0), 23); // 11:00 PM
  const queryParams = new URLSearchParams(window.location.search);
  const [seatDetailData, setSeatDetailData] = useState(null);
  const utmSource = queryParams.get("utm_source");
  const utm_campaign = queryParams.get("utm_campaign");
  const [visiblemapview, setVisiblemapview] = useState(false);
  const [alertDetails, setAlertDetails] = useState({ show: false, type: '', title: '', message: '' });
  const [show, setShow] = useState(false);
  const [mapMsg, setMapMsg] = useState(null);
  const [cinemaAdd, setCinemaAdd] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [formattedShowTime, setFormattedShowTime] = useState(null);
  const [formattedEndTime, setFormattedEndTime] = useState(null);
  const [screenType, setScreenType] = "";
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [userLocationSet, setUserLocationSet] = useState(false);
  const { currentClasses, getWindowWidth } = getResponsiveClasses();

  const [showModalView, setShowModalView] = useState(() => {
    const storedValue = localStorage.getItem("setShowModalView");
    return storedValue !== null ? storedValue : "true";
  });

  const location = useLocation();

  const checkLat = JSON.parse(localStorage.getItem("userLocation")) || {};
  let TransactionType = localStorage.getItem("TransactionType");
  let upcomingMovieBack = localStorage.getItem("upcomingMovieBack");
  const near_by_cinema_radius_in_meter = localStorage.getItem("near_by_cinema_radius_in_meter");

  useEffect(() => {
    if (!TransactionType && getWindowWidth) {
      localStorage.setItem("TransactionType", "OnlyFB");
    }
  }, [TransactionType])

  useEffect(() => {
    checkLocationPermission();
  }, []);

  useEffect(() => {
    if (userLocationSet) {
      const latitude = localStorage.getItem("lat") || "0.000";
      const longitude = localStorage.getItem("long") || "0.000";
      getCinemasData(latitude, longitude, "");  // ✅ Only calls after location is set
    }
  }, [userLocationSet, modeVal]);

  // useEffect(() => {
  //   if (showModalView === "true" && upcomingMovieBack === "true") {
  //     if (getWindowWidth) seatDetailsAPI();
  //     localStorage.setItem("setShowModalView", "true");
  //   }
  // }, [cinemasData]);

  const getCinemasData = async (latitude, longitude, skipbtn) => {
    try {
      setLoading(true);
      // Prepare post data
      const postData = {
        city: localStorage.getItem("city") || "",
        lat: latitude,
        lng: longitude,
        text: "",
      };

      // Send POST request
      const response = await axios.post(
        `${appConstants.base_url}v1/booking/content/cinemas`,
        postData,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token") || ""}`,
            chain: theme === "inoxTheme" ? "INOX" : "PVR",
            city: localStorage.getItem("city") || "",
            country: appConstants.cname,
            appVersion: appConstants.av,
            platform: appConstants.pt,
          },
        }
      );

      // Handle response
      if (response.status === 200 && response.data.code === 10001) {
        const cinemas = response.data.output.c || [];
        const sortedCinemas =
          parseFloat(longitude) > 0
            ? cinemas
            : cinemas.sort((a, b) => a.name.localeCompare(b.name));

        setCinemasData(cinemas);
        setFilterCinema(sortedCinemas);
        setMapMsg(response.data.output.fmsg);
        if (skipbtn !== "skipbtn" && !getWindowWidth) {
          userDistanceCalc(latitude, longitude, cinemas);
        }
        dispatch(
          setCityChangeData({
            city: sortedCinemas.find(item => item.cityName).cityName,
            change: true
          })
        );
        setShow(true);
      } else {
        setFilterCinema([])
        console.log("Unexpected response:", response);
      }
    } catch (error) {
      setLoading(false);
      const errResponse = error?.response;
      if (errResponse?.status === 403) {
        refraceTokenService(); // Refresh token if unauthorized
        console.error("Forbidden: You do not have permission to access this resource.");
      } else {
        console.error("Error fetching cinema data:", error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const checkLocationPermission = useCallback(() => {
    const permissionStatus = localStorage.getItem("locationPermission");
    setLoading(true);
    // If the location is already saved, no need to check again
    if (permissionStatus === "granted" || localStorage.getItem("userLocation")) {
      requestLocationPermission();
      return;
    }
    if ("permissions" in navigator) {
      // Standard permissions API for modern browsers
      navigator.permissions.query({ name: "geolocation" })
        .then((result) => {
          console.log("Permission status:", result.state);
          if (result.state === "granted") {
            localStorage.setItem("locationPermission", "granted");
            requestLocationPermission();
          } else {
            setShowLocationModal(true);
          }
        })
        .catch(() => {
          setShowLocationModal(true);
        });
    }
    else if ("geolocation" in navigator) {
      // Fallback for Safari
      console.log("Using geolocation fallback for Safari...");
      navigator.geolocation.getCurrentPosition(
        () => {
          // Success, store the granted permission and location
          localStorage.setItem("locationPermission", "granted");
          requestLocationPermission();
        },
        (error) => {
          console.error("Geolocation error:", error);
          setShowLocationModal(true);
        }
      );
    }
    else {
      console.warn("Geolocation is not supported on this browser.");
      setShowLocationModal(true);
    }
  }, []);

  const requestLocationPermission = useCallback(async () => {
    if (!navigator.geolocation) {
      sweetAlertError("Geolocation is not supported by this browser.");
      navigate("/select-cinema-for-fnb");
      return;
    }

    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;
        setShowLocationModal(false);
        localStorage.setItem("userLocation", JSON.stringify({ latitude, longitude }));
        localStorage.setItem("lat", latitude);
        localStorage.setItem("long", longitude);
        setUserLocationSet(true);
        // getCinemasData(latitude, longitude, "");
        // navigate("/select-cinema-for-fnb");
      },
      (error) => {
        setShowLocationModal(true);
        setLoading(false);
        handleLocationError(error);
      },
      {
        enableHighAccuracy: true,
        timeout: 7000,
        maximumAge: 0
      }
    );
  }, [navigate]);

  // Handle location errors
  const handleLocationError = (error) => {
    let errorMessage = "An unknown error occurred.";
    if (error.code === 1) {
      errorMessage = "Location access denied. Please enable location permissions.";
    } else if (error.code === 2) {
      errorMessage = "Location information is unavailable.";
    } else if (error.code === 3) {
      errorMessage = "The request to get user location timed out.";
    }
    setAlertDetails({
      show: true,
      type: 'error',
      title: 'Error!',
      message: errorMessage,
    });
    setShowLocationModal(true);
  };

  // Handle modal close and redirection on skip
  const handleLocationModalClose = useCallback((skipbtn) => {
    setShowLocationModal(false);
    localStorage.removeItem("userLocation");
    getCinemasData("", "", skipbtn);
  }, [navigate]);

  const userDistanceCalc = (latitude, longitude, cinemas) => {
    const calculateDistance = (lat1, lon1, lat2, lon2) => {
      const toRad = (value) => (value * Math.PI) / 180; // Convert degrees to radians

      const R = 6371e3; // Radius of the Earth in meters
      const dLat = toRad(lat2 - lat1);
      const dLon = toRad(lon2 - lon1);

      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRad(lat1)) *
        Math.cos(toRad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);

      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      const distance = R * c; // Distance in meters
      return distance;
    };

    const filteredCinemas = cinemas.filter((item) => {
      if (item.distance < parseInt(near_by_cinema_radius_in_meter)) {
        const theatreLat = item.latitude;
        const theatreLong = item.longitude;

        const distance = calculateDistance(
          latitude,
          longitude,
          theatreLat,
          theatreLong
        );
        return distance < parseInt(near_by_cinema_radius_in_meter);
      }
      return false;
    });

    const firstCinema = filteredCinemas.length > 0 ? filteredCinemas[0] : null;

    if (firstCinema) {
      setCinemaDistance(firstCinema);
    } else {
      setCinemaDistance(firstCinema);
      console.warn(`No cinemas found within ${near_by_cinema_radius_in_meter} meters.`);
    }
  };

  const CinemasSessionRequest = async (data, date) => {
    setLoading(true);
    setTheaterId(data?.theatreId);
    setTheaterInfo(data);
    localStorage.setItem("TheatreId", data?.theatreId);
    localStorage.setItem("theaterId", data?.theatreId);
    localStorage.setItem("selectedTheater", JSON.stringify(data));
    localStorage.setItem("booking_type", "FOOD");
    try {
      if (getWindowWidth) {
        const response = await seatDetailsAPI(data);
        if (response?.length > 0 && response?.some(item => item?.theaterId === data?.theatreId)) {
          setShow(true);
          setLoadingAtCinema(false);
          return;
        }
      }

      // ✅ Determine Transaction Type and Navigate Accordingly
      if (data?.isPosAvailable && !getWindowWidth) {
        localStorage.setItem("TransactionType", "InCinemaFB");
        navigate("/check-cinema-options");
      } else {
        localStorage.setItem("TransactionType", "OnlyFB");
        navigate("/fnbdatepicker");
      }
    } catch (error) {
      console.error("Error fetching seat details:", error);
    } finally {
      setLoading(false);
    }

    // if (data?.isPosAvailable && !getWindowWidth) {
    //   localStorage.setItem("TransactionType", "InCinemaFB");
    //   navigate("/check-cinema-options");
    // } else {
    //   localStorage.setItem("TransactionType", "OnlyFB");
    //   navigate("/fnbdatepicker");
    // }
  };

  const seatDetailsAPI = async (data) => {
    setLoadingSeat(true);
    try {
      const response = await axios.post(
        `${appConstants.base_url}v1/fnb/getExistingBookings?upcomingMovies=true`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token") || " "}`,
            chain: localStorage.getItem("theme") === "inoxTheme" ? "INOX" : "PVR",
            appVersion: appConstants.av,
            platform: appConstants.pt,
            country: appConstants.cname,
          },
        }
      );
      if (response.data.result === "success" && response.data.output.length > 0) {
        setSeatDetailData(response.data.output?.filter(item => item?.theaterId === data?.theatreId));
        formattedShowEndTime(response.data.output);
        setVisiblemapview(false);
        return response.data.output?.filter(item => item?.theaterId === data?.theatreId);
      } else {
        setVisiblemapview(true)
      }
    } catch (error) {
      sweetAlertError("Something went wrong!");
      console.error("Failed to fetch seat details:", error);
    } finally {
      setLoadingSeat(false);
    }
  };

  const formattedShowEndTime = (data) => {
    if (data.length > 0) {
      const showTime = parse(data?.[0]?.showTime, "yyyy-MM-dd HH:mm:ss.S", new Date());
      const endTime = parse(data?.[0]?.endTime, "yyyy-MM-dd HH:mm:ss.S", new Date());
      const formattedShowTime = showTime && isValid(showTime) ? format(showTime, "hh:mm a") : "-";
      const formattedEndTime = endTime && isValid(endTime) ? format(endTime, "hh:mm a") : "-";
      setFormattedShowTime(formattedShowTime);
      setFormattedEndTime(formattedEndTime);
    }
  }

  const handleConfirm = () => {
    setShowModalView("false");
    localStorage.setItem("setShowModalView", "false");
  };

  const handleHide = () => {
    if (!getWindowWidth) {
      setShow(false);
      setVisiblemapview(false);
    }
  };
  const handleSkipHide = (skipbtn) => {
    setShow(false);
    setVisiblemapview(false);
    if (skipbtn === "skipbtn") {
      localStorage.removeItem("userLocation");
      navigate("/fnbdatepicker");
    }
  };

  const sweetAlertSuccess = (msg) => {
    setAlertDetails({
      show: true,
      type: "success",
      title: "Success!",
      message: msg,
    });
  };

  const sweetAlertError = (msg) => {
    setAlertDetails({
      show: true,
      type: "error",
      title: "Error!",
      message: msg,

    });
  };

  const hideAlert = () => {
    setAlertDetails({ show: false });
  }

  const handleImageError = (e) => {
    e.target.src = noImage;
  };

  const handleUpcomingMovie = (data) => {
    const selectedTheater = JSON.parse(localStorage.getItem("selectedTheater"));
    const { showTime, theaterId } = data;
    const currentTime = new Date();
    const showTimeDate = new Date(showTime);
    const timeToUse = currentTime < showTimeDate ? showTimeDate : currentTime;
    data.fnbDelivery = false;
    data.name = data.theaterName;
    data.fbDeliveryOnSeat = selectedTheater.fbDeliveryOnSeat;
    data.isPosAvailable = selectedTheater.isPosAvailable;
    localStorage.setItem("selectedTheater", JSON.stringify(data));
    localStorage.setItem("booking_type", "FOOD");
    localStorage.setItem("theaterId", theaterId);
    localStorage.setItem("screenType", "N");
    localStorage.setItem("FoodPickupDateTime", format(timeToUse, "dd MMM yy, hh:mm a"));
    navigate("/select-food", { state: { orderDateTime: format(timeToUse, "yyyy-MM-dd HH:mm:00") } });
    localStorage.setItem("addFoodItems", JSON.stringify(data));
    hitGaUpcomingMovies(data);
  }

  let dataLayer = window["dataLayer"] || [];
  const hitGaUpcomingMovies = (data) => {
    dataLayer.push({
      event: "food_movie_date_cinema_proceed",
      item_name: data?.filmName,
      food_date: data?.showTime,
      cinema_name: data?.name,
    });
  };

  return (
    <>
      {getWindowWidth &&
        <div className="bg-white show-in-desktop-view" style={{ position: "sticky", top: "0", zIndex: "999" }}>
          <Navbar />
        </div>
      }
      <div className={currentClasses.fnbdeskdiv}>
        <div className={currentClasses.fnbdeskdivInn}>
          <div className="citieslist">
            <FbHeader headname="Cinemas" addressShow={true} />
            <div className="cities-show*">
              <div style={{ width: "100%" }} className="search-current">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="pi pi-search" />
                  </span>
                  <InputText
                    placeholder="Search Cinema"
                    id="cinema"
                    value={searchText}
                    onChange={(e) => {
                      const val = e.target.value;
                      setSearchText(val);
                      const filterCinemaData =
                        cinemasData &&
                        cinemasData?.filter((item) =>
                          item?.name
                            ?.toLowerCase()
                            ?.includes(val?.toLowerCase())
                        );
                      setFilterCinema([...filterCinemaData]);
                    }}
                  />
                </div>
              </div>
              <div className="citisli">
                {loading ? (
                  <NowShowingSkeleton countVal={1} />
                ) : filterCinema.length > 0 ? (
                  filterCinema?.map((item, idx) => {
                    let url = `/cinemasessions/${localStorage.getItem(
                      "city"
                    )}/${item.name}/${item.theatreId}`;
                    let url2 = url.replace(/\s/g, "-");
                    localStorage.setItem("selectLoction", item);
                    return (
                      <>
                        {item.foodAvailable && (
                          <div
                            style={{ width: "100%" }}
                            className="col-md-6 col-12"
                            key={idx}
                          >
                            <div
                              className="dearch-box-mobile cinemalist"
                              state={item}
                              style={{ color: "black" }}
                              key={idx}
                              onClick={() => {
                                // FoodPickuphitGa("food_pickup_cinema", item?.name);
                                CinemasSessionRequest(item, "NA");
                              }}
                            >
                              <div className="mobile-search-cinemas-show">
                                <span className="cinema-head-msearch">
                                  {item?.name}
                                </span>
                                <div className="cinemakm">
                                  {checkLat?.latitude > 0 && (
                                    <div className="distance-show-m">
                                      {item?.distanceText &&
                                        item?.distanceText.replace(
                                          "away",
                                          ""
                                        )}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="address-search-m">
                                <p>{item?.address1}</p>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })
                ) : (
                  <span className="mx-3">Cinema not found</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {getWindowWidth &&
        <Footer />
      }
      {showModalView === 'true' && cinemaDistance && visibleRight && cinemaDistance.distance < parseInt(near_by_cinema_radius_in_meter) && (
        <Modal
          className={`${currentClasses.centerModal} usrLocMdl`}
          show={show}
          onHide={handleHide}
          animation={false}
          keyboard={false}
          dialogClassName={show ? "modal-dialog modal-hide" : "modal-dialog"}
          style={{
            backgroundColor: "rgba(0,0,0,0.7)",
            overflow: "hidden",
            zIndex: 99999999999,
          }}
        >
          <div className="modalbody">
            <h3>{cinemaDistance?.name}</h3>
            <p className="dislc">{cinemaDistance?.distanceText}</p>
            <p>{cinemaDistance?.address1}</p>

            <div className="fdrdmbtn">
              <button className="modalbtn cancelbtn" onClick={handleHide}>
                Explore Cinemas
              </button>
              <button
                className="modalbtn confirmbtn"
                onClick={() => {
                  CinemasSessionRequest(cinemaDistance, "NA");
                  handleConfirm();
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </Modal>
      )}

      {showModalView === 'true' && TransactionType !== "InCinemaFB" && (
        <div>
          <Sidebar
            visible={visiblemapview}
            showCloseIcon={false}
            style={getWindowWidth && { maxWidth: "550px", borderRadius: "10px" }}
            position={getWindowWidth ? "center" : "bottom"}
            onHide={() => {
              setVisiblemapview(false);
            }}
            dismissable={true}
          >
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <div className="mob-map-container">
                  <div className="mob-map-container-content">
                    <img src={foodGIF} alt="" /><br />
                    <p>{mapMsg}</p>
                    <a href="javascript:void(0)" onClick={() => { setVisiblemapview(false) }} className={
                      theme === "inoxTheme"
                        ? "confirm-btn-inox border-0"
                        : "confirm-btn-pvr border-0"
                    } style={{ display: "block", fontSize: "18px", borderRadius: "20px", padding: "8px 27px" }}>OKAY</a>
                    {/* <hr/> */}
                  </div>
                </div>
              </div>
            </div>
          </Sidebar>
          {seatDetailData?.length > 0 && getWindowWidth &&
            <Modal
              className={`${currentClasses.centerModal} upcmngModal`}
              show={show}
              onHide={handleHide}
              animation={false}
              keyboard={false}
              dialogClassName={show ? "modal-dialog modal-hide" : "modal-dialog"}
            >
              <div className="modalbody">
                <h3 className="upTitle">

                  {getWindowWidth && <div onClick={() => {
                    setShow(false);
                    setVisiblemapview(false);
                  }} className="bckarw">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6 6.75H12V5.25H6V0L0 6L6 12V6.75Z" fill="black" />
                    </svg>
                  </div>
                  }
                  Your upcoming movie bookings
                </h3>
                <p className="dislc">Select a movie to pre-order food</p>

                <div className="upcmnglist">
                  <div className="upcmnglistinr">

                    {seatDetailData?.map((item, index) => (
                      <div key={`upcomingMovies${index}`} className="movieupcmngdetails" onClick={() => handleUpcomingMovie(item)}>
                        {item?.filmName && (
                          <div className="movieImg">
                            <img
                              src={item?.filmImg || noImage}
                              width={getWindowWidth ? 60 : 50}
                              alt={item?.filmName || "No image available"}
                              onError={handleImageError}
                            />
                          </div>
                        )}
                        <div className="upcmovdets">
                          {item?.filmName && (
                            <h3 className="movUpTitle">{item?.filmName}</h3>
                          )}

                          {item?.showTime && (
                            <p className="cmnUpfnt">{format(item?.showTime, "eee, MMM dd, hh:mm a ")}</p>
                          )}
                          <div className="addflx">
                            {item?.theaterName && (
                              <p className="cmnUpfnt">{item?.theaterName}</p>
                            )}
                          </div>
                        </div>
                        {getWindowWidth ?
                          <div className="upbtnmain">
                            <button className="upcmnbtn">Select</button>
                          </div>
                          :
                          <svg className="rgtarwup" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.7809 5.33327L5.2049 1.75726L6.1477 0.814453L11.3332 5.99993L6.1477 11.1853L5.2049 10.2425L8.7809 6.6666H0.666504V5.33327H8.7809Z" fill="black" />
                          </svg>
                        }
                      </div>
                    ))}
                  </div>
                </div>

                {/* <div className="flxbtn">
                  {getWindowWidth && <Link to="/?tab=food" className="skipUpcmng">Back</Link>} */}
                <p onClick={() => handleSkipHide("skipbtn")} className="skipUpcmng">Skip</p>
                {/* </div> */}
              </div>
            </Modal>
          }
        </div>
      )}

      <SweetAlertUtility type={alertDetails.type} title={alertDetails.title} message={alertDetails.message} show={alertDetails.show} onConfirm={hideAlert} />

      {showAlert &&
        <div>
          <SweetAlert
            error
            show={showAlert}
            title={errorMsg?.result === "dialog" ? "Oops" : errorMsg?.result}
            onConfirm={() => {
              setShowAlert(false);
              if (isBadTransaction) {
                navigate("/");
              } else {
                if (isMobile) {
                  navigate(`/m.payment/${localStorage.getItem("cinemaName")}`);
                } else {
                  navigate(`/payment/${localStorage.getItem("cinemaName")}`);
                }
              }
            }}
            onCancel={() => setShowAlert(false)}
            showConfirm={true}
            showCancel={false}
            confirmBtnText="OK"
            confirmBtnCssClass={
              theme === "inoxTheme"
                ? "confirm-btn-inox border-0"
                : "confirm-btn-pvr border-0"
            }
            closeOnClickOutside={false}
            closeOnEscape={false}
          >
            {errorMsg?.msg}
          </SweetAlert>
        </div>}
      {/* Location Modal */}
      <Modal
        className={`${currentClasses.centerModal} usrLocMdl`}
        // className={currentClasses.locationModal}
        show={showLocationModal}
        onHide={() => handleLocationModalClose()}
        animation={false}
        keyboard={false}
        style={{ backgroundColor: "rgba(0,0,0,0.7)", overflow: "hidden" }}
      >
        <img src={enablelocation} alt="Location" />
        <h3>
          Turn on precise location to help us give you the best experience
        </h3>
        <p>We will keep the battery usage to a minimum</p>
        <div className="lcflx">
          <button
            className="cmnbtn"
            onClick={() => requestLocationPermission()}
          >
            Enable Location
          </button>
          <button
            className="cmnbtn skipbtn"
            onClick={() => handleLocationModalClose("skipbtn")}
          >
            Skip
          </button>
        </div>
      </Modal>

    </>
  );
};


export default CinemaLocationList;
