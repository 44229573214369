import React, { useEffect, useRef, useState } from "react";
import Navbar from "../common/navbar/Navbar";
import tickIcon from "../../assets/food/correct.svg";
import angle_left from "../../assets/mobilemoviedetail/arrow-b.svg";
import foodGIF from "../../assets/food/food.gif";
import nonveg from "../../assets/food/nonveg.svg";
// import vegIcon from "../../assets/food/veg-icon.svg";

import pen from '../../assets/food/pen.svg'
import refresh from '../../assets/food/refresh.svg'
import cooking from '../../assets/food/cooking.gif'
import EllGreen from '../../assets/food/EllipseGreen.svg'
import backBtn from '../../assets/food/backBtnn.svg';
import EllYellow from "../../assets/food/yellowellipse.svg";
import EllEmpty from "../../assets/food/ElliEmpty.svg";
import vegIcon from "../../assets/food/vegcheckout.svg";
import nonvegcarticon from "../../assets/food/nonvegStatus.svg";
import expandIcon from "../../assets/food/expandSmall.svg";
import dassadLine from "../../assets/food/dass-line.svg";

import historyArrow from "../../assets/payment/historyarrow.svg";
import axios from "axios";
import * as appConstants from "../../constants/index";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { refraceTokenService } from "../../global-functions/refreshToken";
import FinalTicketSkeleton from "../../skeletons/FinalTicketSkeleton";
import { FaRupeeSign } from "react-icons/fa";
import { getRetryPayment } from "src/services/CouponPayment";
import SweetAlert from "react-bootstrap-sweetalert";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import FbHeader from "../food-and-beverage/component/FbHeader";
import { getResponsiveClasses } from "../food-and-beverage/component/ResponsiveClasses";
import Footer from "../common/footer/Footer";
import { IscustomEvent, salesForceEvent } from "src/global-functions/globalFunctions";


const FinalFood = () => {
	const navigate = useNavigate();
	const isMobile = window.matchMedia("(max-width: 575px)").matches;
	const theme = localStorage.getItem("theme");
	const [loading, setLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState(null);
	const [countStatus, setcountStatus] = useState(0);
	const [percentage, setpercentage] = useState(0);
	const [showAlert, setShowAlert] = useState(false);
	const [finalTicket, setFinalTicket] = useState(null);
	const [isBadTransaction, setIsBadTransaction] = useState(false);
	const [seconds, setSeconds] = useState(60);
	const [orderStatusLoading, setOrderStatusLoading] = useState(false);
	const [fnbData, setFnbData] = useState([]);
	const [pageErrMsg, setPageErrMsg] = useState(false);
	// const [isRotating, setIsRotating] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);
	const [isRefreshing, setIsRefreshing] = useState(false);
	const initialLoad = useRef(true);
	const [remainingTime, setRemainingTime] = useState({ minutes: 0, seconds: 0 });
	const [expand, setExpand] = useState(false);

	const authToken = "Bearer " + (sessionStorage.getItem("token") || " ");
	const en_bids = localStorage.getItem("en_bid");
	const orid = localStorage.getItem("orid")
	const location = useLocation();

	let [searchParams, setSearchParams] = useSearchParams();

	const { currentClasses, getWindowWidth } = getResponsiveClasses();
	const handleExpand = () => {
		setExpand(!expand);
	};


	useEffect(() => {
		const handleBackNavigation = () => {
			if (location.pathname !== "/") {
				navigate("/?tab=food", { replace: true });
			}
		};
		window.addEventListener("popstate", handleBackNavigation);
		window.history.pushState(null, "", window.location.href);
		return () => {
			window.removeEventListener("popstate", handleBackNavigation);
		};
	}, [navigate, location.pathname]);

	function formatDate(dateString) {
		const date = new Date(dateString);
		const options = { weekday: "short", day: "2-digit", month: "short" };
		return date.toLocaleDateString("en-US", options);
	}

	function formatTime(timeString) {
		const date = new Date(timeString); // Use timeString passed as an argument
		let hours = date.getHours();
		const minutes = date.getMinutes();
		const ampm = hours >= 12 ? "PM" : "AM";

		hours = hours % 12;
		hours = hours ? hours : 12; // Adjust for 0 hour to display 12
		const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
		const formattedTime = `${hours}:${formattedMinutes} ${ampm}`;

		return formattedTime; // Return the formatted time
	}

	const steps = [
		{ id: "order received", label: "Order Received" },
		{ id: "order confirmed", label: "Order Confirmed" },
		{ id: "preparing your order", label: "Preparing Your Order" },
		{ id: "ready for pickup", label: "Ready for Pickup" },
		{ id: "delivered", label: "Delivered" },
	];

	localStorage.setItem("remainingTime", JSON.stringify(remainingTime));

	const onSeat = fnbData?.otherDetails?.deliveryMode === "ONSEAT";
	const orderGetitems = JSON.parse(localStorage.getItem("prepareMultiOrderStatus")) || [];
	const autoRefreshTime = fnbData?.deliveryStatus?.autoRefreshTime + "000" || 45000;
	const handleRefreshClick = async (event) => {
		event.preventDefault();
		if (onSeat && !isDisabled && fnbData?.deliveryStatus?.orderStatus !== "Delivered" && orderGetitems.length > 0) {
			setIsRefreshing(true);
			setIsDisabled(true);
			try {
				await OrderStatusFnB();
			} catch (error) {
				console.error("Error refreshing order status:", error);
			} finally {
				setTimeout(() => setIsRefreshing(false), 400);
				setTimeout(() => setIsDisabled(false), autoRefreshTime);
			}
		}
	};

	// useEffect(() => {
	// 	const en_bids = localStorage.getItem("en_bid");
	// 	if (localStorage.getItem("orid") === en_bids) {
	// 		navigate(`/order-status?bookingid=${en_bids}`);
	// 	} else {
	// 		localStorage.setItem("orid", en_bids);
	// 		prepareMultiOrderStatus();
	// 	}
	// }, [fnbData?.otherDetails?.bookingId,
	// fnbData?.deliveryStatus?.orderStatus,
	// fnbData?.otherDetails?.deliveryMode,
	// localStorage.getItem("targetTime"),])


	// useEffect(() => {
	// }, [

	// ]);

	useEffect(() => {
		let val = localStorage.getItem('booking_ticket');
		const urlParams = new URLSearchParams(window.location.search);
		if (urlParams.has('bookingid')) {
			OrderStatusFnB();
		} else if (val == 1) {
			getFinalTicket();
			localStorage.setItem("booking_ticket", 0);
		} else {
			getJuspayStatus();
		}
	}, [countStatus]);

	useEffect(() => {
		setTimeout(() => {
			setpercentage(percentage + 1);
		}, 1000);
	}, [percentage]);

	// call juspay for getting payment status
	const getJuspayStatus = async () => {
		setLoading(true);
		const postData = {
			bookingId: localStorage.getItem("bookingid"),
			paymentId: localStorage.getItem("paymentid"),
			bookType: "BOOKING",
		};
		await axios
			.post(`${appConstants.base_url}v1/payment/juspay/status`, postData, {
				headers: {
					Authorization: `Bearer ${sessionStorage.getItem("token") || ""}`,
					chain: theme === "inoxTheme" ? "INOX" : "PVR",
					city: localStorage.getItem("city"),
					appVersion: appConstants.av,
					platform: appConstants.pt,
				},
			})
			.then((res) => {
				const dt = res.data;
				if (dt.result === "success") {
					localStorage.removeItem("isDonation");
					const ndt = dt.output;
					if (ndt.p == "PAID") {
						getFinalTicket();
					}
					else if (ndt.p == "PENDING") {
						if (countStatus == 0) {
							setpercentage(percentage + 1);
						}
						if (countStatus < 6) {
							setTimeout(() => {
								//setpercentage(percentage+1);
								setcountStatus(countStatus + 1);
							}, 10000);
						} else {
							setLoading(false);
							setShowAlert(true);
							//let check = getRetryPayment();
							//console.log(check);
							//if(!getRetryPayment()){
							setIsBadTransaction(true);
							//}
							setErrorMsg({
								result: "Pending",
								msg: ndt?.bank_msg ? ndt.bank_msg : "Payment Pending",
							});
							// navigate(`/payment/${localStorage.getItem("cinemaName")}`);
						}
					} else {
						setShowAlert(true);
						if (!getRetryPayment()) {
							setIsBadTransaction(true);
						}
						setTimeout(() => {
							IscustomEvent("FnB Transaction Failed");
						}, 4000);
						setErrorMsg({
							result: "Failed",
							msg: ndt?.bank_msg
								? ndt.bank_msg
								: "Payment Failed, Please try again",
						});
						setLoading(false);
						// navigate(`/payment/${localStorage.getItem("cinemaName")}`);
					}
				} else if (dt.result === "error" || dt.result === "dialog") {
					setShowAlert(true);
					setErrorMsg(dt);
					setIsBadTransaction(true);
				} else {
					setShowAlert(true);
					setErrorMsg(dt);
					setIsBadTransaction(true);
					//console.log(res.data.msg);
				}
			})
			.catch((error) => {
				const err = error?.response;
				if (err?.status === 403) {
					refraceTokenService();
					console.log(
						"Forbidden: You do not have permission to access this resource."
					);
				} else {
					console.log("Error:", error?.message);
				}
				setLoading(false);
			});
	};

	const deliveryStartTimer = (fnbData) => {
		const { orderTakeOn, statusEndTime } = fnbData?.deliveryStatus || {};

		const targetTimeFromStorage = localStorage.getItem("targetTime");

		const parsedOrderTakeOn = orderTakeOn ? new Date(orderTakeOn).getTime() : null;
		const parsedStatusEndTime =
			targetTimeFromStorage || (statusEndTime ? new Date(statusEndTime).getTime() : null);

		if (!parsedStatusEndTime || (parsedOrderTakeOn && parsedOrderTakeOn >= parsedStatusEndTime)) {
			// console.error("Invalid or missing target time.");
			setRemainingTime({ minutes: 0, seconds: 0 });
			return;
		}

		if (!targetTimeFromStorage) {
			localStorage.setItem("targetTime", new Date(parseInt(parsedStatusEndTime)));
		}

		const updateRemainingTime = () => {
			const currentTime = new Date().getTime();
			const difference = new Date(parsedStatusEndTime).getTime() - currentTime;

			if (difference <= 0) {
				setRemainingTime({ minutes: 0, seconds: 0 });
				localStorage.setItem("remainingTime", JSON.stringify({ minutes: 0, seconds: 0 }));
				localStorage.removeItem("targetTime");
				clearInterval(intervalId);
			} else {
				const minutes = Math.floor(difference / (1000 * 60));
				const seconds = Math.floor((difference % (1000 * 60)) / 1000);
				setRemainingTime({ minutes, seconds });
				localStorage.setItem("remainingTime", JSON.stringify({ minutes, seconds }));
			}
		};

		const intervalId = setInterval(updateRemainingTime, 1000);
		updateRemainingTime();

		return () => clearInterval(intervalId);
	}

	//===== final ticketing service ============
	const getFinalTicket = async () => {
		setLoading(true);

		const postData = {
			qr: localStorage.getItem("isQR") && isMobile ? "YES" : "NO",
			bookType: "FOOD",
			transId: localStorage.getItem("transid"),
			bookingId: localStorage.getItem("bookingid"),
			flowType: "FOOD",
			ccode: localStorage.getItem("theaterId"),
		};

		try {
			const response = await axios.post(
				`${appConstants.base_url}v1/booking/ticketing/completed`,
				postData,
				{
					headers: {
						Authorization: `Bearer ${sessionStorage.getItem("token") || ""}`,
						chain: theme === "inoxTheme" ? "INOX" : "PVR",
						city: localStorage.getItem("city"),
						appVersion: appConstants.av,
						platform: appConstants.pt,
						country: appConstants.cname,
					},
				}
			);

			const dt = response.data;

			if (dt.result === "success" && dt.code === 10001) {
				const ndt = dt.output;
				setFinalTicket(ndt);
				OrderStatusFnB();
				navigate(`/order-status?bookingid=${en_bids}`);
				prepareMultiOrderStatus();
				// Store orderId in localStorage
				localStorage.setItem("orderId", ndt.bookingId);
				// Remove unnecessary items from localStorage
				localStorage.removeItem("transid");
				localStorage.removeItem("booking_type");
				localStorage.removeItem("isQR");
				setLoading(false);
			} else {
				handleFailure(dt);
			}
		} catch (error) {
			handleError(error);
		}
	};

	// Helper function to handle failures
	const handleFailure = (errorData) => {
		setIsBadTransaction(true);
		setShowAlert(true);
		setErrorMsg(errorData || "An unknown error occurred.");
		console.error("Transaction failed:", errorData);
	};

	// Helper function to handle errors
	const handleError = (error) => {
		setIsBadTransaction(true);
		setShowAlert(true);
		setErrorMsg("There is a network failure.");

		const err = error?.response;

		if (err?.status === 403) {
			refraceTokenService();
			console.error("Forbidden: You do not have permission to access this resource.");
		} else if (err?.status === 504) {
			console.warn("Gateway Timeout: The server did not respond in time.");
		} else {
			console.error("Error:", error?.message);
		}

		setLoading(false);
	};

	const OrderStatusFnB = async () => {
		try {
			setOrderStatusLoading(true);
			const bookingId = localStorage.getItem("en_bid");//searchParams.get("bookingid");
			const orderId = searchParams.get("code");
			const response = await axios.post(
				`${appConstants.base_url}v1/fnb/fnbDeliveryStatus`,
				{
					bookingId,
					code: orderId
				},
				{
					headers: {
						Authorization: authToken,
						"Content-Type": "application/json",
						chain: localStorage.getItem("theme") === "inoxTheme" ? "INOX" : "PVR",
						appVersion: appConstants.av,
						platform: appConstants.pt,
						country: appConstants.cname,
					},
				}
			);
			if (response.data.result === "success") {
				setFnbData(response.data.output);
				deliveryStartTimer(response.data.output);
			} else {
				console.error(response.data.msg);
				setPageErrMsg("Booking Not Found!")
			}
		} catch (error) {
			console.error("Error fetching FnB data:", error);
		} finally {
			setOrderStatusLoading(false);
		}
	};

	// after 45sec api call
	useEffect(() => {
		let interval;
		const fetchData = async () => {
			const currentStatus = fnbData?.deliveryStatus?.orderStatus?.toLowerCase();
			const onSeat = fnbData?.otherDetails?.deliveryMode === "ONSEAT";

			localStorage.setItem("onSeatType", onSeat);

			if (currentStatus !== "delivered" && onSeat && orderGetitems.length > 0) {
				await OrderStatusFnB();
				interval = setInterval(() => {
					OrderStatusFnB();
				}, autoRefreshTime);
			}
		};

		fetchData();

		return () => {
			if (interval) clearInterval(interval);
		};
	}, [fnbData?.deliveryStatus?.orderStatus, fnbData?.otherDetails?.deliveryMode, fnbData?.deliveryStatus?.autoRefreshTime]);

	const prepareMultiOrderStatus = () => {
		const en_bid = window.location.search.includes("bookingId")
			? new URLSearchParams(window.location.search).get("bookingId")
			: localStorage.getItem("en_bid");

		if (!en_bid) return;

		let existingOrders;
		try {
			existingOrders = JSON.parse(localStorage.getItem("prepareMultiOrderStatus")) || [];
			if (!Array.isArray(existingOrders)) {
				existingOrders = [];
			}
		} catch (error) {
			console.error("Error parsing prepareMultiOrderStatus:", error);
			existingOrders = [];
		}

		const newOrderStatus = {
			en_bid: en_bid,
			bookingId: fnbData?.otherDetails?.bookingId || null,
			targetTime: localStorage.getItem("targetTime") || null,
			currentStatus: fnbData?.deliveryStatus?.orderStatus || null,
			deliveryMode: fnbData?.otherDetails?.deliveryMode || null,
			foodItems: fnbData?.otherDetails?.foods || null,
		};

		// Check if the `en_bid` already exists in the existingOrders array
		const existingIndex = existingOrders.findIndex((order) => order.en_bid === en_bid);

		if (existingIndex !== -1) {
			existingOrders[existingIndex] = { ...existingOrders[existingIndex], ...newOrderStatus };
		} else {
			existingOrders.push(newOrderStatus);
		}

		localStorage.setItem("prepareMultiOrderStatus", JSON.stringify(existingOrders));
	};



	const getCurrentStepIndex = () => {
		const currentStatus = fnbData?.deliveryStatus?.orderStatus?.toLowerCase();
		const index = steps.findIndex((step) => step.id === currentStatus);
		if (index === -1) return 0;
		// If current status is 'order confirmed', return the next step index
		return currentStatus
			? Math.min(index + 1, steps.length - 1)
			: index;
	};
	const getCompletedStepIndex = () => {
		const currentStatus = fnbData?.deliveryStatus?.orderStatus?.toLowerCase();
		return steps.findIndex((step) => step.id === currentStatus);
	};

	const getStepIcon = (index) => {
		const currentIndex = getCurrentStepIndex();
		const completedIndex = getCompletedStepIndex();

		if (index <= completedIndex) return EllGreen;
		if (index === currentIndex) return EllYellow;
		return EllEmpty;
	};

	const isStepActive = (index) => {
		const currentIndex = getCurrentStepIndex();
		const completedIndex = getCompletedStepIndex();
		return index <= Math.max(currentIndex, completedIndex);
	};

	const getCurrentStatusText = () => {
		const currentIndex = getCurrentStepIndex();
		return steps[currentIndex].label;
	};

	const getStepTime = (index) => {
		if (!fnbData?.deliveryStatus) return "";
		const { orderTakeOn, startedOn, readyOn, deliveredOn } =
			fnbData.deliveryStatus;

		switch (index) {
			case 0:
			case 1:
				return formatTime(orderTakeOn);
			case 2:
				return startedOn ? formatTime(startedOn) : "";
			case 3:
				return readyOn ? formatTime(readyOn) : "";
			case 4:
				return deliveredOn ? formatTime(deliveredOn) : "";
			default:
				return "";
		}
	};

	useEffect(() => {
		const handlePopState = (event) => {
			if (!window.location.search.includes("?tab=food")) {
				navigate("/?tab=food", { replace: true });
			}
		};

		window.addEventListener("popstate", handlePopState);

		return () => {
			window.removeEventListener("popstate", handlePopState);
		};
	}, [navigate]);


	return (
		<>
			{getWindowWidth &&
				<div className="bg-white show-in-desktop-view" style={{ position: "sticky", top: "0", zIndex: "999" }}>
					<Navbar />
				</div>
			}
			<div className={currentClasses.fnbdeskdiv}>
				<div className={currentClasses.fnbdeskdivInn}>
					<div className="fb-os-main">

						{loading ? (
							<>
								<div className="bg-white">
									<div className="row">
										<div className="col-md-12 col-xs-12">
											<div className="Payment-show-align">
												<div className="Payment-show-content">
													<h3>Payment is under process, Please wait ...</h3>
												</div>
												<div className="Payment-process-bar">
													<CircularProgressbar
														value={percentage}
														text={`${percentage}%`}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</>
						) : (
							<>
								<FbHeader headname="Order Status" addressShow={false} />
								{/* <div className="bg-white show-in-desktop-view" style={{ position: "sticky", top: "0", zIndex: "999" }}>
									<Navbar />
								</div>
								<div className="bg-white show-in-mobile-view" style={{ position: "sticky", top: "0", zIndex: "999" }}>
									<div className="booking-confirm">
										<div onClick={() => navigate("/")}>
											<img src={angle_left} alt="" className="" />{" "}
										</div>
										<h3>Booking Confirmed</h3>
									</div>
								</div> */}
							</>
						)}
						{showAlert ? (
							<div>
								<SweetAlert
									error
									show={showAlert}
									title={errorMsg?.result === "dialog" ? "Oops" : errorMsg?.result}
									onConfirm={() => {
										setShowAlert(false);
										if (isBadTransaction) {
											navigate("/");
										} else {
											if (isMobile) {
												navigate(`/m.payment/${localStorage.getItem("cinemaName")}`);
											} else {
												navigate(`/payment/${localStorage.getItem("cinemaName")}`);
											}
										}
									}}
									onCancel={() => setShowAlert(false)}
									showConfirm={true}
									showCancel={false}
									confirmBtnText="OK"
									confirmBtnCssClass={
										theme === "inoxTheme"
											? "confirm-btn-inox border-0"
											: "confirm-btn-pvr border-0"
									}
									closeOnClickOutside={false}
									closeOnEscape={false}
								>
									{errorMsg?.msg}
								</SweetAlert>
							</div>
						) : loading ? (
							""
						) : (
							<>
								{/* <section className="order-bg-placed ptb-45">
									<div className="container view-container">
										<div className="col-md-12">
											<div className="order-placed">
												<div className="tick-icon">
													<img src={tickIcon} alt="" className="img-fluid" />
													<h6>Order Placed!</h6>
													<p>
														Thank you! Please collect your order from the Concessionaire
														at the cinema
													</p>
												</div>
											</div>
											{loading ? (
												<FinalTicketSkeleton />
											) : (
												<div className="food-order-placed">
													<div className="row">
														<div className="col-md-6 align-self-center">
															<div className="food-gif">
																<img src={foodGIF} alt="" className="img-fluid" />
															</div>
														</div>
														<div className="col-md-6">
															<div className="order-alldetails">
																<div className="order-no">
																	<h6>Order No.</h6>
																	<p>{finalTicket?.orderIdEx}</p>
																	<div className="divider-gyftr"></div>
																</div>
																<div className="order-foodbeverage">
																	<h6>Food & BEVERAGES</h6>
																	{finalTicket?.orderFood?.foods && finalTicket?.orderFood?.foods?.map((item, idx) => (
																		<div className="order-food-placeds" key={idx}>
																			<div className="food-order-veg">
																				<h6>
																					<span className="orderplace-icon">
																						<img
																							src={item?.veg ? vegIcon : nonveg}
																							alt=""
																							className="img-fluid"
																						/>
																					</span>
																					{item?.name}
																				</h6>
																			</div>
																			<div className="food-quanity-paced">
																				<h6>{item?.quantity} X <FaRupeeSign style={{ fontSize: "10px", fontWeight: "500", marginBottom: "2px" }} />{item?.price / 100}</h6>
																			</div>
																		</div>
																	))}
																	<div className="divider-gyftr orderplaced-ud"></div>
																	<div className="sub-orderplaced">
																		<div className="sub-total-placed">
																			<h6>Sub Total</h6>
																		</div>
																		<div className="price-placed">
																			<h6><FaRupeeSign style={{ fontSize: "10px", fontWeight: "500", marginBottom: "2px" }} />{finalTicket?.finalAmount}</h6>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											)}
											<div className="history-book placed-history">
												<div
													className="book-history pointer"
													onClick={() => {
														localStorage.setItem('myBooking_viewMode', 0);
														if (isMobile) {
															navigate("/m.profile");
														} else {
															navigate("/profile");
														}
													}}
												>
													<p>Go to Booking History</p>
												</div>
												<div className="history-arrow">
													<img src={historyArrow} alt="" className="img-fluid" />
												</div>
											</div>
										</div>
									</div>
								</section> */}
								<div className="fb-os-inn">
									<div className="fb-os-coIDiv">
										<img className="fb-os-coImg" src={cooking} alt="" />
									</div>
									<div className="fb-os-about">
										{pageErrMsg ? (
											<div className="nodatamsg" style={{ height: "50vh" }}>
												{pageErrMsg}
											</div>
										) : (
											fnbData?.otherDetails && (
												<>
													<p className="fb-os-oi">
														Booking ID:
														<span> {fnbData?.otherDetails?.bookingId}</span>
													</p>
													{
														<>
															{/* {isRefreshing && <p>Refreshing order status...</p>} */}
															<div
																className={`fb-os-ref ${isDisabled ? "disabled" : ""
																	}`}
																style={{
																	cursor: isDisabled ? "default" : "pointer",
																}}
																onClick={handleRefreshClick}
															>
																{onSeat ? (
																	<p>Deliver to Seat</p>
																) : (
																	<p>Pickup from Counter</p>
																)}
																{onSeat && fnbData?.deliveryStatus?.orderStatus !== "Delivered" && localStorage.getItem("targetTime") && (
																	<img
																		src={refresh}
																		alt="Refresh"
																		className={
																			isRefreshing
																				? "refreshing"
																				: "refresh-complete"
																		}
																	/>
																)}
															</div>

															{onSeat ? (
																<>
																	<div className="fb-os-seat">
																		<p className="fb-os-audi">
																			Audi Name:{" "}
																			<span>{fnbData?.otherDetails?.audi}</span>
																		</p>
																		<p className="fb-os-audi">
																			Seat No:{" "}
																			<span>{fnbData?.otherDetails?.seats}</span>
																		</p>
																	</div>

																	<div className="fb-os-seat">
																		<p className="fb-os-pickup">
																			Order Date:<br />
																			<span>
																				{formatDate(
																					fnbData?.otherDetails?.showTime
																				)}
																			</span>
																		</p>
																		<p className="fb-os-pickup" style={{ textAlign: "right" }}>
																			Order Time:<br />
																			<span>
																				{formatTime(
																					fnbData?.otherDetails?.showTime
																				)}
																			</span>
																		</p>
																	</div>
																</>
															) : (
																<div className="fb-os-seat">
																	<p className="fb-os-pickup">
																		Pick-up Date:<br />
																		<span>
																			{formatDate(
																				fnbData?.otherDetails?.showTime
																			)}
																		</span>
																	</p>
																	<p className="fb-os-pickup" style={{ textAlign: "right" }}>
																		Pick-up Time:<br />
																		<span>
																			{formatTime(
																				fnbData?.otherDetails?.showTime
																			)}
																		</span>
																	</p>
																</div>
															)}
															{onSeat && fnbData?.deliveryStatus?.orderStatus !== "Delivered" && fnbData.deliveryStatus && (
																<>
																	<div className="preparTime">
																		<div className="fb-os-pt-img">
																			<img src={pen} alt="" />
																			<p className="fb-pyo">
																				{getCurrentStatusText()}
																			</p>
																		</div>
																		<div className="rem-time">
																			<p>
																				{remainingTime.minutes
																					.toString()
																					.padStart(2, "0")}
																				:
																				{remainingTime.seconds
																					.toString()
																					.padStart(2, "0")}
																			</p>
																		</div>
																	</div>
																	{orderStatusLoading ? (
																		<div className="loading-section">
																			<h2>Loading...</h2>
																		</div>
																	) : (
																		<div className="ste-fb-dyn">
																			<div className="vertical-stepper">
																				{steps.map((step, index) => (
																					<div
																						key={index}
																						className={`step ${isStepActive(index)
																							? "active"
																							: ""
																							}`}
																					>
																						<div className="Fnbstep-icon">
																							<img
																								src={getStepIcon(index)}
																								alt=""
																							/>
																						</div>
																						<div
																							style={{
																								color: isStepActive(index)
																									? "#000"
																									: "#888",
																							}}
																							className={`step-label ${isStepActive(index)
																								? "active"
																								: ""
																								}`}
																						>
																							{step.label}
																						</div>
																					</div>
																				))}
																			</div>
																			<div className="step-times">
																				{steps.map((_, index) => (
																					<p
																						style={{
																							color: isStepActive(index)
																								? "#000"
																								: "#888",
																							fontWeight: isStepActive(index)
																								? "500"
																								: "normal",
																						}}
																						key={index}
																					>
																						{getStepTime(index)}
																					</p>
																				))}
																			</div>
																		</div>
																	)}
																</>
															)}
														</>
													}

													<div className="fb-os-item">
														<p
															style={{ marginBottom: "10px" }}
															className="fb-os-i-cinIP"
														>
															ORDER Summary
														</p>
														{fnbData?.otherDetails?.foods?.map((item) => (
															<React.Fragment key={item.itemId}>
																<div className="fb-os-i-outer">
																	<div className="fb-os-i-innstatus">
																		{item?.veg ? (
																			<svg
																				width="14"
																				height="14"
																				viewBox="0 0 14 14"
																				fill="none"
																				xmlns="http://www.w3.org/2000/svg"
																			>
																				<rect x="0.5" y="0.5" width="13" height="13" rx="1.5" stroke="#149752" />
																				<circle cx="6.99984" cy="6.99935" r="4.08333" fill="#149752" />
																			</svg>
																		) : (
																			<svg
																				width="14"
																				height="14"
																				viewBox="0 0 12 12"
																				fill="none"
																				xmlns="http://www.w3.org/2000/svg"
																			>
																				<rect x="0.961426" y="0.964844" width="10.0769" height="10.0769" rx="1.5" stroke="#87001B" />
																				<path d="M9 8.80886H3L6.00011 3.18945L9 8.80886Z" fill="#87001B" />
																			</svg>
																		)}

																		<div>
																			<p className="fnbsta-iname">
																				{item?.name?.toLowerCase()} x {item?.quantity}
																			</p>

																			{item?.addons?.length > 0 && (
																				<em className="fnb-c-addons">
																					Add ons:
																					{item?.addons?.map((addon, index) => (
																						<p className="cart-addonP" key={`addon-${index}`}>
																							<span>
																								{addon?.name?.toLowerCase()} x {addon?.quantity}
																							</span>
																							<span className="os-statusAdd">
																								₹{((Number(addon?.price) * addon?.quantity) / 100).toFixed(2)}
																							</span>
																						</p>
																					))}
																				</em>
																			)}
																		</div>
																	</div>

																	<div className="os-itemprice">
																		₹{((item?.price * item?.quantity) / 100).toFixed(2)}
																	</div>
																</div>

																{/* Upsell Items */}
																{/* {item?.upsell?.map((upsell, index) => (
																	<div key={`upsell-${item.itemId}-${index}`} className="fb-os-i-outer">
																		<div className="fb-os-i-innstatus">
																			{item?.veg ? (
																				<svg
																					width="14"
																					height="14"
																					viewBox="0 0 14 14"
																					fill="none"
																					xmlns="http://www.w3.org/2000/svg"
																				>
																					<rect x="0.5" y="0.5" width="13" height="13" rx="1.5" stroke="#149752" />
																					<circle cx="6.99984" cy="6.99935" r="4.08333" fill="#149752" />
																				</svg>
																			) : (
																				<svg
																					width="14"
																					height="14"
																					viewBox="0 0 12 12"
																					fill="none"
																					xmlns="http://www.w3.org/2000/svg"
																				>
																					<rect x="0.961426" y="0.964844" width="10.0769" height="10.0769" rx="1.5" stroke="#87001B" />
																					<path d="M9 8.80886H3L6.00011 3.18945L9 8.80886Z" fill="#87001B" />
																				</svg>
																			)}

																			<div>
																				<p className="fnbsta-iname">
																					{upsell?.name} x {upsell?.quantity}
																				</p>
																			</div>
																		</div>

																		<div className="os-itemprice">
																			₹{((Number(upsell?.price) * upsell?.quantity) / 100).toFixed(2)}
																		</div>
																	</div>
																))} */}
															</React.Fragment>
														))}
														<img
															// style={{ marginTop: "10px" }}
															src={dassadLine}
															alt=""
														/>
														{/* <div
															style={{ marginTop: "5px" }}
															className="fb-stdivI"
														>
															<p
																style={{
																	textDecoration: "underline",
																	textUnderlineOffset: "3px",
																	margin: "3px 0px",
																}}
																onClick={handleExpand}
																className="fb-stp"
															>
																Taxes & Fees{" "}
																<img
																	style={{
																		transform:
																			expand === true
																				? "rotate(180deg)"
																				: "rotate(0deg)",
																		transition: "ease 0.3s",
																	}}
																	src={expandIcon}
																/>
															</p>
															<p
																style={{ margin: "3px 0px" }}
																className="fb-stp"
															>
																{fnbData
																	? `₹${((parseInt(fnbData?.otherDetails?.cgst) + parseInt(fnbData?.otherDetails?.sgst)) / 100)?.toFixed(2)}`
																	: "-"}
															</p>
														</div>

														{expand && (
															<div className="fb-stdivI">
																<div>
																	<p
																		style={{ marginBottom: "8px" }}
																		className="fb-stpExp"
																	>
																		CGST :
																	</p>
																	<p
																		style={{ marginBottom: "8px" }}
																		className="fb-stpExp"
																	>
																		SGST :
																	</p>
																</div>
																<div>
																	<p className="fb-stpExp">
																		{fnbData
																			? `₹${(Number(fnbData?.otherDetails?.cgst) / 100)?.toFixed(2)}`
																			: "-"}
																	</p>
																	<p className="fb-stpExp">
																		{fnbData
																			? `₹${(Number(fnbData?.otherDetails?.sgst) / 100)?.toFixed(2)}`
																			: "-"}
																	</p>
																</div>
															</div>
														)} */}

														<div style={{ paddingTop: 5, marginTop: 10 }}
															className="fb-os-i-outer"
														>
															<p className="fb-os-i-subamt">Sub Total</p>
															<p
																style={{ fontWeight: 600 }}
																className="fb-os-i-subamt"
															>
																₹{((fnbData?.otherDetails?.fbTotalPrice + fnbData?.otherDetails?.discount) / 100)?.toFixed(2)}
															</p>
														</div>
														{fnbData?.otherDetails?.discount > 0 &&
															<div className="fb-stdiv4 b1">
																<p className="fb-stpII">Discount</p>
																<p className="fb-stpII2">-₹{((fnbData?.otherDetails?.discount) / 100).toFixed(2)}</p>
															</div>
														}
														<div
															style={{
																paddingBottom: 5,
																marginBottom: 10,
															}}
															className="fb-os-i-outer"
														>
															<p className="fb-os-i-amt">Amount Paid</p>
															<p style={{ fontWeight: 600 }} className="fb-os-i-amt">
																₹{(fnbData?.otherDetails?.fbTotalPrice / 100)?.toFixed(2)}
															</p>
														</div>
													</div>
													<div className="fb-os-i-cin">
														<div className="fb-os-i-cinInn">
															<p className="fb-os-i-cinIP">Cinema</p>
															<p className="fb-os-i-cinIAdd">
																{fnbData?.otherDetails?.theaterName}
															</p>
														</div>
														<div className="fb-os-i-cinIOut">
															<div
																style={{ border: "none" }}
																className="fb-os-i-cin"
															>
																<p className="fb-os-i-cinIP">Payment Mode</p>
																<p className="fb-os-i-cinIAdd">
																	{
																		fnbData?.otherDetails?.payMode
																			?.split(",")
																			.pop()
																			.split(":")[0]
																	}
																</p>
															</div>
															<div
																className="fb-os-i-cin"
																style={{ border: "none" }}
															>
																<p
																	style={{ textAlign: "right" }}
																	className="fb-os-i-cinIP"
																>
																	Date
																</p>
																<p className="fb-os-i-cinIAdd">
																	{formatDate(fnbData?.otherDetails?.createdAt)}
																</p>
															</div>
														</div>
													</div>
												</>
											)
										)}
									</div>
								</div>
								<div className="fb-pno-btns">
									<button
										onClick={() => {
											navigate("/?tab=food");
											let dataLayer = window["dataLayer"] || [];
											dataLayer.push({
												'event': 'food_place_new_order',
											});
										}}
										className="fb-pno-btn"
									>
										Place New Order
									</button>
								</div>
							</>
						)}
					</div>
				</div>
			</div>

			{getWindowWidth &&
				<Footer />
			}
		</>
	);
};

export default FinalFood;
