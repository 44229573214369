import axios from "axios";
import * as appConstants from "../constants/index";

export const refraceTokenService = async () => {
  const theme = localStorage.getItem('theme');
  const loginInfo = JSON.parse(sessionStorage.getItem("loginInfo") || "null");
  const refresh_token = loginInfo?.refresh?.token;

  const postData = {
    refreshToken: sessionStorage.getItem("refresh_token") || null,
  };

  await axios
    .post(`${appConstants.base_url}v1/auth/refresh-tokens`, postData, {
      headers: {
        chain: theme === "inoxTheme" ? "INOX" : "PVR",
        city: localStorage.getItem("city"),
        appVersion: appConstants.av,
        platform: appConstants.pt,
        country: appConstants.cname,
      },
    })
    .then((res) => {
      const dt = res.data;
      if (dt.result === "success") {
        const ndt = dt.output;
        sessionStorage.setItem('token', ndt?.access?.token);
        sessionStorage.setItem('refresh_token', ndt?.refresh?.token);
        window.location.reload();
      } else {
        sessionStorage.removeItem("loginInfo");
        sessionStorage.removeItem("token");
        setTimeout(() => {
          window.location.href = "/";
        }, 2000);
      }

      if (res.status === 403) {
        sessionStorage.removeItem("loginInfo");
        sessionStorage.removeItem("token");
        setTimeout(() => {
          window.location.href = "/";
        }, 2000);
      }
    })
    .catch((err) => {
      console.log("error", err);
      sessionStorage.removeItem("loginInfo");
      sessionStorage.removeItem("token");
        setTimeout(() => {
          window.location.href = "/";
        }, 2000);
    });
};
