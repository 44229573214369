import React, { useEffect, useState } from 'react'
import backBtn from '../../../assets/food/backBtnn.svg'
import backBtndesk from '../../../assets/food/desktopBack.svg'
import locationIcon from '../../../assets/food/locationBlack.svg'
import searchIcon from '../../../assets/food/search.svg'
import user from "../../../assets/nav-icon/user.svg";
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { setLoginViewMode } from 'src/redux/actions/loginViewAction';
import { getResponsiveClasses } from './ResponsiveClasses'
import Cities from 'src/views/common/cities/Cities'


const FbHeader = ({ className, locationName, setUserSelectedFood, headname, cartShow, searchShow, fnbInput, fnbInputShow, addressShow, handleBack, myAccount, filterIndex }) => {
  const navigateState = useLocation();
  const navigate = useNavigate();
  const loginInfo = JSON.parse(sessionStorage.getItem("loginInfo") || "null");
  const mobHistory = localStorage.getItem("mobHistory");
  const fnb_qr = localStorage.getItem("fnb_qr");
  const bookType = localStorage.getItem("booking_type") || "FOOD";
  const { currentClasses, getWindowWidth } = getResponsiveClasses();


  const selectedTheater = localStorage.getItem("selectedTheater") && JSON.parse(localStorage.getItem("selectedTheater"));
  const addtocartData = useSelector(state => state.addToCartReduceHandler.FoodVoucher);

  const { name, city, cinemaname, cinemaId, encrypted_id } = useParams();

  const backHandleClick = () => {
    const url = location?.pathname?.replaceAll("%25", "%")?.replaceAll("%26", "&");
    switch (url) {
      case "/select-food":
        const mobileRedirect =
          fnb_qr === "YES"
            ? "/?tab=food"
            : mobHistory
              ? "/m.profile"
              : bookType === "BOOKING"
                ? navigate(-1)
                : selectedTheater.isPosAvailable
                  ? "/check-cinema-options"
                  : "/select-cinema-for-fnb";

        const desktopRedirect =
          mobHistory
            ? "/profile"
            : bookType === "BOOKING"
              ? navigate(-1)
              : "/select-cinema-for-fnb";

        const redirectPath = getWindowWidth ? desktopRedirect : mobileRedirect;
        navigate(redirectPath, mobHistory ? { state: { showModal: true, filterIndex } } : undefined);
        break;
      case "/fnbdatepicker":
        navigate(selectedTheater?.isPosAvailable === false || getWindowWidth ? "/select-cinema-for-fnb" : "/check-cinema-options");
        break;
      case "/select-cinema-for-fnb":
        navigate("/?tab=food");
        break;
      case "/fnb-checkout":
        navigate("/select-food");
        break;
      case "/offers":
      case "/fnb-seats-selection":
        navigate("/fnb-checkout");
        break;
      case `/bankoffer/${name?.replaceAll("%25", "%")}`:
      case `/promocode`:
        navigate("/offers");
        break;
      case "/scan-qr":
      case "/food-Vouchers-at-boxoffice":
      case "/order-status":
        navigate(
          getWindowWidth
            ? navigateState.state?.mobHistory === "mobHistory"
              ? "/profile"
              : "/"
            : navigateState.state?.mobHistory === "mobHistory"
              ? "/m.profile"
              : "/?tab=food",
          navigateState.state?.mobHistory === "mobHistory" ? { state: { showModal: true, filterIndex: filterIndex } } : undefined
        );
        break;
      case `/redeem-offers/${name}`:
        navigate("/food-Vouchers-at-boxoffice");
        break;
      case `/offeractivated`:
      case `/foodcart`:
        navigate("/food-Vouchers-at-boxoffice");
        break;
      case `/foodbooking`:
        navigate(getWindowWidth ? "/profile" : "/m.profile", { state: { showModal: true, filterIndex: filterIndex } });
        break;
      case `/`:
        // This case handles when user swipes back to the home page
        if (window.confirm("Are you sure you want to exit?")) {

          window.location.href = "/"; // Redirect to home if needed
        }
        // If canceled, do nothing (user remains on the home page)
        break;
      default:
        navigate(-1);
        // console.warn("Unhandled path:", url);
        // navigate("/");
        break;
    }
  };


  useEffect(() => {
    const handlePopState = () => {
      backHandleClick();
    };

    const handleBeforeUnload = (event) => {
      const url = location.pathname;
      if (url === "/") {
        // Custom message is not supported in modern browsers, but we set a default message
        const confirmationMessage = "Are you sure you want to exit? Any unsaved changes may be lost.";
        event.preventDefault();
        event.returnValue = confirmationMessage; // Required for Chrome
      }
    };

    // Add event listeners
    window.addEventListener("popstate", handlePopState);
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      // Cleanup event listeners
      window.removeEventListener("popstate", handlePopState);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [navigate, location.pathname]);

  return (
    <>
      {!fnbInputShow && (
        <div
          style={{
            borderTopLeftRadius: getWindowWidth ? "20px" : "0px",
            borderTopRightRadius: getWindowWidth ? "20px" : "0px",
          }}
          className={`containerfnb ${className || ""}`}
        >
          <div className="cont-fnb-inn">
            <div className="fnb-loc-div">
              <img
                onClick={backHandleClick}
                src={getWindowWidth ? backBtndesk : backBtn}
                alt="backBtn"
              />
              <div>
                <p className={currentClasses.headerTitle}>{headname}</p>
                {/* <p className="fnb-os-p">{headname}</p> */}
                {addressShow && (
                  <>
                    <Link
                      to="/select-cinema-for-fnb"
                      className={currentClasses.headerAddress}
                    >
                      {location.pathname !== "/select-cinema-for-fnb" && (
                        <img src={locationIcon} alt="location" />
                      )}
                      {location.pathname === "/select-cinema-for-fnb" ? (
                        <p className="fnbloc-name nav-center now-right"><Cities /></p>
                      ) : locationName ? (
                        <p className="fnbloc-name">{locationName}</p>
                      ) : (
                        <p className="fnbloc-name">{selectedTheater?.name}</p>
                      )}

                    </Link>
                  </>
                )}
              </div>
            </div>

            <div className="rgtsec">
              {myAccount && (
                <div className="search-icon-m">
                  <div
                    onClick={() => {
                      if (loginInfo.access.token) {
                        // setVisible(true);
                        navigate("/m.profile");
                      } else {
                        dispatch(setLoginViewMode({ value: true }));
                      }
                    }}
                  >
                    <img src={user} alt="" className="img-fluid" />
                  </div>
                  {/* <MProfile /> */}
                </div>
              )}
              {cartShow && (
                <Link to="/foodcart" className="cartIcon">
                  <svg
                    width="42"
                    height="42"
                    viewBox="0 0 42 42"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="42" height="42" rx="21" fill="black" />
                    <path
                      d="M16.4206 12.667H25.5873C25.8495 12.667 26.0965 12.7905 26.2539 13.0003L28.5039 16.0003V28.5003C28.5039 28.9606 28.1308 29.3337 27.6706 29.3337H14.3372C13.877 29.3337 13.5039 28.9606 13.5039 28.5003V16.0003L15.7539 13.0003C15.9113 12.7905 16.1583 12.667 16.4206 12.667ZM26.8373 17.667H15.1706V27.667H26.8373V17.667ZM26.4206 16.0003L25.1706 14.3337H16.8372L15.5872 16.0003H26.4206ZM18.5039 19.3337V21.0003C18.5039 22.3811 19.6232 23.5003 21.0039 23.5003C22.3846 23.5003 23.5039 22.3811 23.5039 21.0003V19.3337H25.1706V21.0003C25.1706 23.3015 23.3051 25.167 21.0039 25.167C18.7027 25.167 16.8372 23.3015 16.8372 21.0003V19.3337H18.5039Z"
                      fill="white"
                    />
                  </svg>
                  {addtocartData.length !== 0 && (
                    <span className="cartCount">{addtocartData.length}</span>
                  )}
                </Link>
              )}
            </div>
            {searchShow && (
              <div>
                <img
                  onClick={fnbInput}
                  src={searchIcon}
                  className={currentClasses.headerSearch}
                  alt="searchIcon"
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default FbHeader