import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import backBtn from "../../assets/food/backBtnn.svg";
import bestSellerIcon from "../../assets/food/bestseller.svg";
import closeIcon from "../../assets/food/close.svg";
import foodcategaryicon from "../../assets/food/foodcategary.svg";
// import expandIcon from "../../assets/food/expandSmall.svg";
import expandCartIcon from "../../assets/food/cartDownArrow.svg";
import leftLinePVR from "../../assets/food/leftLine.svg";
import rightLinePVR from "../../assets/food/rightLine.svg";
import leftLineINOX from "../../assets/food/rightLine-INOX.svg";
import rightLineINOX from "../../assets/food/leftLine-INOX.svg";
import scrollIcon from "../../assets/food/scrollarrow.svg";
import modalLine from "../../assets/food/modalLine.svg";
import miv_inox from "../../assets/default-images/vertical-poster-inox.png";
import miv_pvr from "../../assets/default-images/vertical-poster-pvr.svg";
import noSack from "../../assets/food/no-snack.svg";
import priceArrow from "../../assets/food/price-arrow.svg";
import minus from "../../assets/food/minus.svg";
import noImg from "../../assets/food/noimage250.png";
import nonvegIcon from "../../assets/food/nonveg.svg";
import plus from "../../assets/food/plus.svg";
import rightArrow from "../../assets/food/rightArr.svg";
import searchIcon from "../../assets/food/searchIcon.svg";
import cartIcon from "../../assets/food/shoppingCart.svg";
import activeCartIcon from "../../assets/food/shoppingActiveCart.svg";
import vegIcon from "../../assets/food/veg.svg";
import vegIconCart from "../../assets/food/vegcarticon.png";
import nonvegIconCart from "../../assets/food/nonvegStatus.svg";
import discountCardIconPVR from "../../assets/food/dicountIcon.svg";
import discountCardIconINOX from "../../assets/food/discount-INOX.svg";
import deskback from "../../assets/food/deskbackbtn.svg";
import AddButton from "./component/AddButton";
import FbHeader from "./component/FbHeader";
import FnBCard from "./component/FnBCard";
import PairItems from "./component/PairItems";
import RecommendeCard from "./component/RecommendeCard";
import "./fnb.css";
import "./foodoffer.css";
import cross from "../../assets/food/cross.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  addFoodToCart,
  removeFoodFromCart,
  setUserSelectedFood,
} from "../../redux/actions/addToCartAction";
import * as appConstants from "../../constants/index";
import SweetAlertUtility from "./component/SweetAlertUtility";
import FnbLandingSkeleton from "src/skeletons/FnbLandingSkeleton";
import FnbCheckoutSkeleton from "src/skeletons/FnbCheckoutSkeleton";
import Navbar from "../common/navbar/Navbar";
import { format, parse } from "date-fns";
import { getResponsiveClasses } from "./component/ResponsiveClasses";
import { salesForceEvent } from "src/global-functions/globalFunctions";
import { ProceedBtn } from "../get-food/getfoodTags";
import { FaRupeeSign } from "react-icons/fa";
import ExtendTime from "../common/extend-time/ExtendTime";
import BookingSummarySkeleton from "src/skeletons/BookingSummarySkeleton";
import SeatLayoutBreadBrumb from "../common/app-breadcrumb/SeatLayoutBreadBrumb";

const FnBLanding = () => {
  const [show, setShow] = useState(false);
  const [showOffer, setShowOffer] = useState(false);
  const [showCustomiz, setShowCustomiz] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [isOfferClosing, setOfferIsClosing] = useState(false);
  const [isCustomizClosing, setCustomizClosing] = useState(false);
  const [addedItems, setAddedItems] = useState([]);
  const [selectedMovie, setSelectedMovie] = useState([]);
  const [fnbInputShow, setFnBInputShow] = useState(false);
  const [searchItem, setSearchItem] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [expandCartModal, setExpandCartModal] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [tempCustomizations, setTempCustomizations] = useState({});
  const [fnbData, setFnbData] = useState([]);
  const [repeatData, setRepeatData] = useState([]);
  const [fnbCoupen, setfnbCoupen] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [caretPosition, setCaretPosition] = useState(null);
  const [showConvFee, setShowConvFee] = useState(false);

  const [filters, setFilters] = useState({
    veg: false,
    nonVeg: false,
    bestsellers: false,
    categories: "",
  });

  const [alertDetails, setAlertDetails] = useState({
    show: false,
    type: "",
    title: "",
    message: "",
  });
  const [categoriesIcons, setCategoriesIcons] = useState([])


  const inputRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const scrollContainerRef = useRef(null);
  const scrollRepeatAgainRef = useRef(null);
  const categoriesContainerRef = useRef(null);
  const cartItemIds = useRef([]);

  const [isLeftArrowVisibleScroll, setIsLeftArrowVisibleScroll] =
    useState(false);
  const [isRightArrowVisibleScroll, setIsRightArrowVisibleScroll] =
    useState(true);

  const [isLeftArrowVisibleRepeat, setIsLeftArrowVisibleRepeat] =
    useState(false);
  const [isRightArrowVisibleRepeat, setIsRightArrowVisibleRepeat] =
    useState(true);

  const [isLeftArrowVisibleCategories, setIsLeftArrowVisibleCategories] =
    useState(false);
  const [isRightArrowVisibleCategories, setIsRightArrowVisibleCategories] =
    useState(true);

  const { currentClasses, getWindowWidth } = getResponsiveClasses();

  const AuthToken = sessionStorage.getItem("token") || " ";
  const theme = localStorage.getItem("theme");
  const city = localStorage.getItem("city");
  const TransactionType = localStorage.getItem("TransactionType") || "OnlyFB";
  const addFoodItems = JSON.parse(localStorage.getItem("addFoodItems")) || null;
  const bookType = localStorage.getItem("booking_type") || "FOOD";
  const loginInfo = JSON.parse(sessionStorage.getItem("loginInfo") || "null");
  const selectedTheater = JSON.parse(localStorage.getItem("selectedTheater"));
  const theatreId = JSON.parse(localStorage.getItem("theaterId")) || {};
  const fnb_qr = localStorage.getItem("fnb_qr");
  TransactionType === "InCinemaFB" && localStorage.removeItem("FoodPickupDateTime");
  const cinemaname = localStorage.getItem("cinema_name");
  const seatCount = localStorage.getItem("seatCount") || 0;
  const currExperience = localStorage.getItem("currExperience") || "N";

  let seatdetails = null;
  if (window.matchMedia("(max-width: 575px)").matches && fnb_qr === "YES") {
    seatdetails = JSON.parse(localStorage.getItem("seatdetails")) || null;
  } else {
    localStorage.removeItem("seatdetails");
  }


  const categoryRefs = useRef({});

  useEffect(() => {
    if (filters?.categories && categoryRefs.current[filters.categories]) {
      categoryRefs.current[filters.categories].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  }, [filters?.categories]);

  // const cartItems = useSelector(
  //   (state) => state.addToCartReduceHandler.cartItems
  // );
  // const userSelectedFood = useSelector(
  //   (state) => state.addToCartReduceHandler.userSelectedFood
  // );

  const cartTransprantBtn = {
    color: "black",
    backgroundColor: "transparent",
    border: "1px solid #EDE8E9",
  };

  // const foodType = TransactionType === "InCinemaFB" ? "ONSEAT" : "ADDFOOD";
  // const flowType = TransactionType === "FOOD";

  // Extract year, month, and day
  const todayDate = new Date();
  const year = todayDate.getFullYear();
  const month = String(todayDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1
  const day = String(todayDate.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;

  // Format the time as "hh:mm:ss"
  const currentTime = new Date();
  const hours = String(currentTime.getHours()).padStart(2, "0");
  const minutes = String(currentTime.getMinutes()).padStart(2, "0");
  const seconds = String(currentTime.getSeconds()).padStart(2, "0");
  const formattedTime = `${hours}:${minutes}:00`;
  // const formattedTime = `${hours}:${minutes}:${seconds}`;
  const { orderDateTime } = location.state || {};
  const OnlyFBDate = orderDateTime ? orderDateTime.split(" ") : null;


  // const { address1, theatreId } = selectedTheater || {};
  const FinalBookingDate = OnlyFBDate
    ? OnlyFBDate[0]
    : formattedDate.toString();
  const FinalBookingTime = OnlyFBDate
    ? OnlyFBDate[1] //format(new Date(OnlyFBDate), "HH:mm:00")
    : formattedTime.toString();

  useEffect(() => {
    if (location.pathname == "/select-food") {
      if (localStorage.getItem("fnbSaveFoodDetailsError") === null && bookType !== "BOOKING") {
        cancelTrnsService();
        localStorage.removeItem("getAllFnbData");
        localStorage.removeItem("bookingid");
        localStorage.removeItem("transid");
      }
      localStorage.removeItem("AppliedOfferData");
      if (addFoodItems || bookType === "BOOKING") { localStorage.removeItem("cartItems"); }
    }
  }, [location]);
  const [messages, setMessages] = useState({
    nams: "",
    emptyCart: "",
    itemQuantityLimit: "",
    deliveryLocation: "",
  });

  useEffect(() => {
    ListingFnBdata();
    fnbOffer();
  }, []);

  const ListingFnBdata = async () => {
    try {
      setLoading(true);
      const seat = seatdetails && `${seatdetails?.seatrow}${seatdetails?.seatno}`;
      const screenType =
        (currExperience &&
          (currExperience.includes("GOLD") ||
            currExperience.includes("LUXE") ||
            currExperience.includes("INSIGNIA") ||
            currExperience.includes("DIRECTOR")))
          ? currExperience
          : "N";

      const formData = {
        // qr: seatdetails?.fnb_qr === "YES" ? "YES" : "NO",
        qr: TransactionType === "OnlyFB" ? "NO" : "YES",
        bookType: bookType,
        audi: "",
        screen: screenType,
        seat: seat || "",
        city: city || "",
        transId: bookType === "BOOKING" ? localStorage.getItem("transid") : 0,
        ccode: theatreId,
        addBookId: addFoodItems ? addFoodItems?.orderIdEx : bookType === "BOOKING" ? localStorage.getItem("bookingid") : "",
        foodType: TransactionType === "OnlyFB" ? "ADDFOOD" : "ONSEAT",
      };
      const response = await axios.post(
        `${appConstants.base_url}v1/booking/food/getfoodV2`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${AuthToken}`, // Make sure AuthToken is defined
            "Content-Type": "application/json",
            chain: theme === "inoxTheme" ? "INOX" : "PVR",
            appVersion: appConstants.av,
            platform: appConstants.pt,
            city: city || "",
            country: appConstants.cname,
          },
        }
      );

      if (response.data.result === "success") {
        const updatedDataArr = response.data.output?.r?.map((item) => {
          return {
            ...item,
            h: item.h.toLowerCase(),
            addOnItems: item.addOnItems?.map((addon) => {
              return { ...addon, addOnItemName: addon.addOnItemName.toLowerCase() }; // Rename comboItemId to id
            }),
            comboItems: item.comboItems.map((combo) => {
              return { ...combo, id: combo.comboItemId, comboItemName: combo.comboItemName.toLowerCase() }; // Rename comboItemId to id
            }),
          };
        });

        localStorage.setItem(
          "messages",
          JSON.stringify({
            nams: response.data.output.nams || "Sorry, you can select only 25 items at one time.",
            emptyCart: response.data.output.emptyCart || "Please add at least one item to proceed.",
            itemQuantityLimit: response.data.output.itemQuantityLimit || "You can only order maximum 10 quantities for this item",
            deliveryLocation: response.data.output.deliveryLocation || "Please specify the delivery location for the order to proceed.",
          })
        );

        const repeat = response.data.output.repeat?.map((item) => item);
        const repeatdt = updatedDataArr?.filter((item) => repeat?.includes(item?.id));
        setFnbData(updatedDataArr);
        setCategoriesIcons(response.data.output);
        setRepeatData(repeatdt);
        setSelectedMovie(response.data.output);
        response?.data?.output?.defaultDisplatCat.toLowerCase() !== "all" && handleCheckboxChange(response?.data?.output?.defaultDisplatCat);
        localStorage.setItem("selectedMovie", JSON.stringify(response.data.output));
        localStorage.setItem("seatCount", response.data.output.aqt);
        if (response.data.output?.orderTicket && bookType === "BOOKING") {
          localStorage.setItem("TransactionType", "OnlyFB");
          localStorage.setItem("FoodPickupDateTime", format(parse(response.data.output?.orderTicket?.showTime, "EEE, MMM dd, hh:mm a", new Date()), "dd MMM yy, hh:mm a"));
        }
      }
      if (response.data.result === "error" && bookType === "BOOKING") {
        getWindowWidth ? navigate(`/payment/${cinemaname}`) : navigate(`/m.payment/${cinemaname}`)
      }
      setLoading(false);
      hitGa("fnb_explore_menu");
    } catch (error) {
      if (error?.response?.status === 403) navigate("/");
      console.error("Error fetching FnB data:", error);
    } finally {
      setLoading(false);
    }
  };

  const hitGa = (event) => {
    let dataLayer = window["dataLayer"] || [];
    dataLayer.push({
      event: event,
    });
  };

  useEffect(() => {
    const savedCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    setAddedItems(Array.isArray(savedCartItems) ? savedCartItems : []);
    if (
      theatreId === undefined ||
      theatreId === null ||
      TransactionType === undefined ||
      TransactionType === null ||
      AuthToken === null ||
      AuthToken === undefined
    ) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    cartItemIds.current = addedItems.map((i) => i.ItemID);

    const updatedItems = addedItems.map((item) => {
      if (item.upgradeItems && item.upgradeItems.length > 0) {
        const updatedUpgradeItems = item.upgradeItems.map((upgrade) => {
          const fnbItem = fnbData.find((fnb) => fnb.id === upgrade.upgradeItemId);

          return fnbItem
            ? {
              upgradeParentId: item.ItemID || "",
              AddOnItem: fnbItem?.addOnItems,
              IsVeg: fnbItem?.veg,
              ItemID: fnbItem?.id,
              ItemName: fnbItem?.h,
              addonIds: fnbItem?.addonIds,
              foodType: fnbItem?.foodType,
              isCustmization: fnbItem?.addOn === true ? true : false,
              itemImageURL: fnbItem?.i,
              preparationTime: fnbItem?.preparationTime,
              preparationType: fnbItem?.preparationType,
              upgradable: fnbItem?.upgradable,
              price: fnbItem?.dp,
              quantity: 1,
            }
            : {};

        });

        return {
          ...item,
          upgradeItems: updatedUpgradeItems.filter((upgrade) => Object.keys(upgrade).length > 0),
        };
      }

      return item;
    });

    localStorage.setItem('cartItems', JSON.stringify(updatedItems));

    if (expandCartModal && updatedItems.length === 0) {
      setExpandCartModal(false);
    }
  }, [addedItems, expandCartModal, fnbData]);


  const fnbOffer = async () => {
    try {
      const response = await axios.post(
        `${appConstants.base_url}v1/offer/list`,
        {
          id: 0,
          city: city,
          payment: false,
          fnb: true,
          ccode: theatreId
        },
        {
          headers: {
            Authorization: `Bearer ${AuthToken}`,
            "Content-Type": "application/json",
            chain: theme === "inoxTheme" ? "INOX" : "PVR",
            appVersion: appConstants.av,
            platform: appConstants.pt,
            country: appConstants.cname,
          },
        }
      );

      if (response.data.result === "success") {
        setfnbCoupen(response?.data?.output);
      } else {
        console.error("Unexpected response format:", response);
      }
    } catch (error) {
      console.error("Error fetching FnB offer coupon:", error);
    }
  };

  const sendCartData = async () => {
    try {
      setCheckoutLoading(true);

      let dataLayer = window["dataLayer"] || [];
      const foodNames = addedItems.map(item => item.ItemName).join(", ");
      const foodTypes = addedItems.map(item => item.Categories).join(", ");
      const prices = addedItems.map(item => (item.price / 100)).join(", ");
      dataLayer.push({
        event: "food_proceed",
        food_name: foodNames,
        food_type: foodTypes,
        price: prices
      });
      if (addedItems.length > 0) {
        navigate("/fnb-checkout");
      } else {
        const messages = JSON.parse(localStorage.getItem("messages"));
        sweetAlertError(messages.emptyCart || "Your cart is empty.");
      }
    } catch (error) {
      console.error("Error fetching FnB data:", error);
      setCheckoutLoading(false);
      sweetAlertError("An error occurred. Please try again.");
    } finally {
      setCheckoutLoading(false);
    }
  };

  const cancelTrnsService = async () => {
    const postData = {
      ccode: theatreId,
      qr: TransactionType === "OnlyFB" ? "NO" : "YES",
      bookType: bookType,
      transId: localStorage.getItem("transid") || 0,
      bookingId: localStorage.getItem("bookingid") || 0,
      //ccode: cinemacode,
      donate: "",
      flexi: 0,
      cancelUnpaid: true,
    };

    await axios
      .post(`${appConstants.base_url}v1/booking/ticketing/cancel`, postData, {
        headers: {
          chain: theme === "inoxTheme" ? "INOX" : "PVR",
          city: city,
          Authorization: `Bearer ${AuthToken}`,
          appVersion: appConstants.av,
          platform: appConstants.pt,
          country: appConstants.cname,
        },
      })
      .then((res) => {

        localStorage.removeItem("isQR");
        localStorage.removeItem("bookingid");
        localStorage.removeItem("transid");
        localStorage.removeItem("DeliverytoSeatData");

      })
      .catch((error) => {
        const err = error?.response;
        if (err?.status === 403) {
          refraceTokenService();
          console.log(
            "Forbidden: You do not have permission to access this resource."
          );
        } else {
          console.log("Error:", error.message);
        }
        setLoading(false);
      });
  };

  const handleExpandCartModalClose = () => setExpandCartModal(false);
  const handleExpandCartModalShow = () => setExpandCartModal(true);

  const handleShowCartModal = () => {
    setExpandCartModal(!expandCartModal);
  };

  const handleFoodInfoClose = () => {
    setTimeout(() => {
      setShow(false);
    }, 300);
  }

  const handleFoodInfoShow = (item) => {
    setSelectedItem(item);
    setShow(true);
  };

  const handleOfferfoClose = () => {
    setTimeout(() => {
      setIsClosing(false);
      setShowOffer(false);
    }, 300);
  };

  const handleOfferfoShow = (offer) => {
    setShowOffer(true);
    setSelectedOffer(offer);
  };

  const handleCustomizfoClose = () => {
    setCustomizClosing(true);
    setTempCustomizations({});
    setTimeout(() => {
      setCustomizClosing(false);
      setShowCustomiz(false);
    }, 0);
  };

  const handleCustomizfoShow = (item) => {
    setSelectedItem(item);
    setShowCustomiz(true);
    setTempCustomizations({ ...item, addOnItems: [], addonIds: [] });
  };

  useEffect(() => { }, [tempCustomizations]);

  useEffect(() => {
    const container = scrollContainerRef?.current;
    if (!container) return;

    const checkScroll = () => {
      const scrollLeft = container.scrollLeft;
      const maxScrollLeft = container.scrollWidth - container.clientWidth;
      setIsLeftArrowVisibleScroll(scrollLeft > 0);
      setIsRightArrowVisibleScroll(scrollLeft < maxScrollLeft);
    };

    const resizeListener = () => checkScroll();

    const timer = setTimeout(() => {
      checkScroll();
    }, 100);

    window.addEventListener("resize", resizeListener);

    return () => {
      clearTimeout(timer);
      window.removeEventListener("resize", resizeListener);
    };
  }, [scrollContainerRef?.current]);

  const updateArrowVisibility = (container, type) => {
    const scrollLeft = container.scrollLeft;
    const maxScrollLeft = container.scrollWidth - container.clientWidth;

    const isLeftVisible = scrollLeft > 0;
    const isRightVisible = scrollLeft < maxScrollLeft;

    if (type === "scroll") {
      setIsLeftArrowVisibleScroll(isLeftVisible);
      setIsRightArrowVisibleScroll(isRightVisible);
    } else if (type === "repeat") {
      setIsLeftArrowVisibleRepeat(isLeftVisible);
      setIsRightArrowVisibleRepeat(isRightVisible);
    } else if (type === "categories") {
      setIsLeftArrowVisibleCategories(isLeftVisible);
      setIsRightArrowVisibleCategories(isRightVisible);
    }
  };

  const handleScroll = (type) => {
    const container = scrollContainerRef?.current;
    if (container) {
      container.scrollBy({
        left: type === "left" ? -560 : type === "right" ? 560 : 0,
        behavior: "smooth",
      });

      setTimeout(() => {
        updateArrowVisibility(container, "scroll");
      }, 500);
    }
  };

  const handleScrollRepeat = (type) => {
    const container = scrollRepeatAgainRef?.current;
    if (container) {
      container.scrollBy({
        left: type === "left" ? -560 : type === "right" ? 560 : 0,
        behavior: "smooth",
      });

      setTimeout(() => {
        updateArrowVisibility(container, "repeat");
      }, 500);
    }
  };

  const handleScrollCategories = (type) => {
    const container = categoriesContainerRef?.current;
    if (container) {
      container.scrollBy({
        left: type === "left" ? -560 : type === "right" ? 560 : 0,
        behavior: "smooth",
      });

      setTimeout(() => {
        updateArrowVisibility(container, "categories");
      }, 500);
    }
  };

  const fnBInputHandle = () => {
    setFnBInputShow(!fnbInputShow);
  };

  useEffect(() => {
    if (fnbInputShow && inputRef.current) {
      inputRef.current.focus();
    }
  }, [fnbInputShow]);

  const handleHide = () => {
    setIsClosing(true);
    setTimeout(() => {
      setShow(false);
      setIsClosing(false);
      setSelectedItem(null);
      handleFoodInfoClose();
    }, 50);
  };

  const handleExpandHide = () => {
    setExpandCartModal(true);
    setTimeout(() => {
      handleExpandCartModalClose(false);
      setExpandCartModal(false);
    }, 0);
  };

  const handleOfferHide = () => {
    setOfferIsClosing(true);
    setTimeout(() => {
      setOfferIsClosing(false);
      handleOfferfoClose();
      setSelectedOffer(null);
    }, 50);
  };

  const handleCustmizeHide = (action) => {
    setCustomizClosing(true);
    // if (!action) {
    //   setTempCustomizations((prev) => {
    //     const updatedItem = { ...prev };
    //     updatedItem.addOnItems = [];
    //     updatedItem.addonIds = [];
    //     return updatedItem;
    //   });
    //   addToCart(null, "addToCartWithAddons");
    // }

    handleFoodInfoClose();
    setTimeout(() => {
      setCustomizClosing(false);
      handleCustomizfoClose();
    }, 50);
  };

  // Addon Item selection for adding in Cart
  const handleCustomizationChange = (addonItem, isChecked) => {
    setTempCustomizations((prev) => {
      if (!prev) return prev;

      // const updatedItem = { ...prev };
      const updatedItem = {
        ...prev,
        addonIds: prev.addonIds ? [...prev.addonIds] : [],
        addOnItems: prev.addOnItems ? [...prev.addOnItems] : [],
      };

      const totalCount = countAllItems + updatedItem.addonIds.length + 1;

      if (totalCount >= Math.max(seatCount, 10)) {
        const messages = JSON.parse(localStorage.getItem("messages"));
        sweetAlertError(messages?.itemQuantityLimit);
        return prev;
      }

      // if (countAllItems + updatedItem.addonIds.length + 1 >= 10) {
      //   const messages = JSON.parse(localStorage.getItem("messages"));
      //   sweetAlertError(messages?.itemQuantityLimit || "Maximum food items limit has been reached");
      //   return prev;
      // }

      if (isChecked) {
        if (updatedItem.addonIds.length >= 3) {
          sweetAlertError("More than 3 add-ons are not allowed");
          return prev; // Return the previous state
        }
        updatedItem.addonIds.push(addonItem.addOnItemId);
        updatedItem.addOnItems.push(addonItem);
      } else {
        updatedItem.addOnItems = updatedItem.addOnItems.filter(
          (item) => item.addOnItemId !== addonItem.addOnItemId
        );
        updatedItem.addonIds = updatedItem.addonIds.filter(
          (id) => id !== addonItem.addOnItemId
        );
      }

      return updatedItem;
    });
  };

  const calculateCustomizationTotal = (id) => {
    if (!tempCustomizations.id) return 0;
    if (tempCustomizations.id && tempCustomizations.id?.length <= 0) return 0;
    // return 0;
    let addOns = [...tempCustomizations.addOnItems];
    return addOns.reduce((total, itemTwo) => total + itemTwo.addOnItemRate, 0);
  };

  // Add this function to calculate the subtotal
  const calculateSubtotal = (() => {
    let total = addedItems?.reduce((total, item) => {
      // const checkItemPrice = item.foodType === "1" ? item.price : item.price;
      const itemTotal = (item.price / 100) * item.quantity;
      const addonTotal = item?.AddOnItem?.reduce((addonSum, addon) => {
        return (addonSum + addon?.price * addon.quantity);
      }, 0);
      return total + itemTotal + (addonTotal / 100);
    }, 0);
    return total;
  })();

  // Helper function to all items count
  let countAllItems = (() => {
    let i = 0;
    addedItems &&
      addedItems.forEach((item) => {
        i = i + item.quantity;
        if (item.AddOnItem) {
          item.AddOnItem.forEach((addon) => (i = i + addon.quantity));
        }
      });
    return i;
  })();
  // Helper function to all items count
  let countFoodItems = (() => {
    let i = 0;
    addedItems &&
      addedItems.forEach((item) => {
        i = i + item.quantity;
        // if (item.AddOnItem) {
        //   item.AddOnItem.forEach((addon) => (i = i + addon.quantity));
        // }
      });
    return i;
  })();

  const addToCart = (selectedItem, action) => {
    let addedItemsCopy = [...addedItems];
    let isSame = false;

    if (!selectedItem && action === "addToCartWithAddons") {
      selectedItem = { ...tempCustomizations };
    }

    const uniqueKey = selectedItem.id + (selectedItem.addonIds?.length > 0 ? selectedItem.addonIds.sort().join("-") : "");

    addedItemsCopy.forEach((item) => {
      const itemKey = item.ItemID + (item.addonIds?.length > 0 ? item.addonIds.sort().join("-") : "");

      if (itemKey === uniqueKey) {
        // if ((bookType === "BOOKING" && item.quantity >= seatCount) || (bookType !== "BOOKING" && item.quantity >= 10)) {
        if (item.quantity >= 10) {
          sweetAlertError(messages?.itemQuantityLimit);
        }

        if (addedItemsCopy.reduce((total, i) => total + i.quantity, 0) >= seatCount) {
          sweetAlertError(messages?.itemQuantityLimit);
          return;
        }

        // if (item.quantity >= 10) {
        //   sweetAlertError(
        //     "You can't add more than 10 of the same combination to your cart."
        //   );
        //   return;
        // }

        item.quantity++;
        item.AddOnItem.forEach((addon) => addon.quantity++);
        isSame = true;
      }
    });

    if (isSame) {
      setAddedItems(addedItemsCopy);
      return;
    }

    // if (addedItemsCopy.length >= seatCount || addedItemsCopy.length >= 10) {
    //   sweetAlertError(messages?.itemQuantityLimit);
    //   return;
    // }

    if (addedItemsCopy.reduce((total, i) => total + i.quantity, 0) >= seatCount) {
      sweetAlertError(messages?.itemQuantityLimit);
      return;
    }

    // ✅ Ensure individual item count does not exceed 10
    if (selectedItem.quantity >= 10) {
      sweetAlertError("You can't add more than 10 of the same item.");
      return;
    }

    // if (addedItemsCopy.length >= 10) {
    //   sweetAlertError("You can't add more than 10 unique items to your cart.");
    //   return;
    // }



    const newItem = {
      ItemID: selectedItem.id || selectedItem.comboItemId,
      ItemName: selectedItem.h || selectedItem.comboItemName,
      Categories: selectedItem?.ct,
      IsVeg: selectedItem.veg,
      quantity: 1,
      price: selectedItem.dp || parseInt(selectedItem.comboItemRate) * 100,
      foodType: selectedItem.foodType,
      PrimaryItemID: selectedItem.id || selectedItem.comboItemId,
      itemImageURL: selectedItem.i,
      sgst: selectedItem.sgst,
      cgst: selectedItem.cgst,
      isCustmization: selectedItem.addOn === true ? true : false,
      preparationTime: selectedItem.preparationTime,
      upgradable: selectedItem.upgradable,
      upsellable: selectedItem.upsellable,
      upgradeItems: selectedItem?.upgradeItems
        ? selectedItem?.upgradeItems?.map(upgrade => ({
          upgradeItemId: upgrade.upgradeItemId,
          upgradeItemName: upgrade.upgradeItemName,
          upgradeItemRate: upgrade.upgradeItemRate
        })) : [],
      upsellItems: selectedItem?.upsellItems
        ? selectedItem?.upsellItems?.map(upsell => ({
          upsellItemId: upsell.upsellItemId,
          upsellItemName: upsell.upsellItemName,
          upsellItemRate: upsell.upsellItemRate
        })) : [],
      parentUpsellId: selectedItem.parentUpsellId || null,

      preparationType: selectedItem.preparationType,
      addonIds: selectedItem.addonIds || [],
      AddOnItem: selectedItem.addOnItems
        ? selectedItem.addOnItems.map((e) => ({
          addOnItemId: e.addOnItemId,
          name: e.addOnItemName,
          price: e.addOnItemRate,
          quantity: 1,
        }))
        : [],

    };

    if (newItem.isCustmization) {
      addedItemsCopy.unshift(newItem);
    } else {
      addedItemsCopy.push(newItem);
    }

    setAddedItems(addedItemsCopy);
  };


  const removeFromCart = (id) => {
    const numOfItems = cartItemIds.current.filter((itemId) => itemId === id);

    if (numOfItems.length === 1) {
      setAddedItems((prevItem) => {
        let stateCopy = [...prevItem];
        let removeInd = -1;
        stateCopy.map((item, index) => {
          if (item.ItemID === id) {
            item.quantity--;
            if (item?.AddOnItem?.length) {
              let addOns = [...item.AddOnItem];
              addOns.map((e) => {
                e.quantity--;
              });
              item.AddOnItem = addOns;
            }
            if (item?.quantity <= 0) {
              removeInd = index;
            }
          }
        });
        if (removeInd >= 0) {
          stateCopy.splice(removeInd, 1);
        }
        localStorage.setItem("cartItems", JSON.stringify(stateCopy));
        return stateCopy;
      });
    } else {
      handleExpandCartModalShow();
    }
  };

  if (checkoutLoading) {
    return (
      <div className={currentClasses.fnbdeskdiv}>
        <div className={currentClasses.fnbdeskdivInn}>
          <FnbCheckoutSkeleton />
        </div>
      </div>
    );
  } else if (loading) {
    return (
      <div className={currentClasses.fnbdeskdiv}>
        <div className={currentClasses.fnbdeskdivInn}>
          <FnbLandingSkeleton />
        </div>
      </div>
    );
  }

  const handleCheckboxChange = (filter) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters };
      if (filter === "Veg") {
        newFilters.veg = !prevFilters.veg;
        if (newFilters.veg) newFilters.nonVeg = false;
      } else if (filter === "non veg") {
        newFilters.nonVeg = !prevFilters.nonVeg;
        if (newFilters.nonVeg) newFilters.veg = false;
      } else if (filter === "Bestsellers") {
        newFilters.bestsellers = !prevFilters.bestsellers;
        newFilters.categories = newFilters.bestsellers
          ? ""
          : newFilters.categories;
      } else {
        // Handle category filters
        // const categories = new Set(prevFilters.categories);
        const filterLowerCase = filter.toLowerCase();
        if (newFilters.categories === filterLowerCase) {
          newFilters.categories = "";
        } else {
          newFilters.categories = filterLowerCase;
          if (newFilters.bestsellers) {
            newFilters.bestsellers = false;
          }
        }
      }

      return newFilters;
    });
  };
  const handleImageError = (e) => {
    e.target.src = noImg; // Set fallback image if the original image fails
  };

  const applyFilters = (data) => {
    let filteredData = [...data];

    // Apply search filter
    if (searchItem) {
      filteredData = filteredData.filter((item) =>
        item?.h?.toLowerCase().includes(searchItem?.toLowerCase())
      );
    }

    // Apply veg/non-veg filter
    if (filters.veg || filters.nonVeg) {
      filteredData = filteredData.filter(
        (item) => (filters.veg && item.foodType === 1 && "veg") || (filters.nonVeg && item.foodType === 0 && "non veg")
      );
    }

    // Apply bestseller filter    
    if (filters.bestsellers) {
      filteredData = filteredData.filter((item) => item.bestSeller);
    }

    // Apply category filters
    if (filters.categories !== "") {
      filteredData = filteredData.filter((item) => {
        //  If multiple categories are selected, an item must match ALL selected categories
        //  return [...filters.categories].every((category) =>
        //    item.itemCategory.toLowerCase().includes(category)
        //  );
        return item.ct.toLowerCase() === filters.categories;
      });
    }

    return filteredData;
  };

  const filteredDataShow = applyFilters(fnbData || []);

  const sweetAlertSuccess = (msg) => {
    setAlertDetails({
      show: true,
      type: "success",
      title: "Success!",
      message: msg,
    });
  };

  const sweetAlertError = (msg) => {
    setAlertDetails({
      show: true,
      type: "error",
      title: "Error!",
      message: msg,
    });
  };

  const hideAlert = () => {
    setAlertDetails({ show: false });
  };

  const handleBlur = () => {
    setCaretPosition(inputRef.current.selectionStart);
  };


  const handleFocus = () => {
    if (caretPosition !== null) {
      inputRef.current.setSelectionRange(caretPosition, caretPosition);
    }
  };

  const handleCloseClick = () => {
    setSearchItem("");
  }

  // Food & Ticket Side Bar
  const selectedFoodData = (data) => {
    if (bookType === "BOOKING") {
      if (!addedItems || addedItems.length === 0) return null;

      return addedItems.map((item, index) => {
        const checkItemPrice = item.price / 100;

        return (
          <div key={`${item.ItemID}-${index}`} className="Reco-card-main bookingfnbFood">
            <div className="fb-card-inn">
              <div className="Reco-item-view">
                {item.foodType === 1 ? (
                  <svg className="fnb-itemVorN" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.5" width="13" height="13" rx="1.5" stroke="#149752" />
                    <circle cx="6.99984" cy="6.99935" r="4.08333" fill="#149752" />
                  </svg>
                ) : item.foodType === 0 ? (
                  <svg className="fnb-itemVorN" width="14" height="14" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.961426" y="0.964844" width="10.0769" height="10.0769" rx="1.5" stroke="#87001B" />
                    <path d="M9 8.80886H3L6.00011 3.18945L9 8.80886Z" fill="#87001B" />
                  </svg>
                ) : (
                  <p className="emtyIcon"></p>
                )}

                {/* <img
              className="cartitemimg"
              src={item?.itemImageURL || noImg}
              alt={item?.itemName || "No image available"}
              onError={handleImageError}
            /> */}
              </div>

              <div className="Reco-item-desc">
                <p className="fb-itemName">{item?.ItemName}</p>
                {item.AddOnItem.length > 0 && (
                  <em className="fnb-c-addons">
                    Add-ons:
                    {item.AddOnItem.map((addon, addonIndex) => (
                      <p className="cart-addonP" key={addonIndex}>
                        {addon.name} ₹{addon.price / 100} X {addon.quantity}
                      </p>
                    ))}
                  </em>
                )}
                <AddButton
                  cartBtn={true}
                  cartStyle={cartTransprantBtn}
                  key={`${item.ItemID}-${index}-btn`}
                  data={item}
                  cartIndex={index}
                  setAddedItems={setAddedItems}
                  addedItems={addedItems}
                  isCart={true}
                  cartItemIds={cartItemIds.current}
                  updateCount={addToCart}
                  handleFoodInfoShow={() => handleFoodInfoShow(item)}
                  handleCustomizfoShow={() => handleCustomizfoShow(item)}
                  removeFromCart={removeFromCart}
                  totalItems={countAllItems}
                />
              </div>
              <div className="Customfb-btnDiv">
                <p className="fb-itemPrice">
                  ₹
                  {(
                    item.quantity * checkItemPrice +
                    item.AddOnItem.reduce((sum, addon) => sum + addon.price * item.quantity, 0) /
                    100
                  ).toFixed(2)}
                </p>
              </div>
            </div>
          </div>
        );
      });
    }
  };
  const calculateMovieSubTotal = (bookingType, totalTicketPrice, calculateSubTotal) => {
    if (bookingType === "BOOKING") {
      const subtotal =
        (+totalTicketPrice?.replace(/,/g, "") || 0) +
        calculateSubTotal

      return subtotal.toFixed(2);
    } else {
      return calculateSubTotal

    }
  };

  const calculateGrandTotal = (
    bookingType,
    totalTicketPrice,
    convTotal,
    calculateSubtotal,
    item = false
  ) => {
    let pricevalue;
    if (bookingType === "BOOKING") {
      pricevalue =
        (+totalTicketPrice?.replace(/,/g, "") || 0) +
        (+convTotal || 0) +
        calculateSubtotal
    } else {
      pricevalue = calculateSubtotal;
    }
    // console.log("grnd-total", pricevalue);
    return item ? pricevalue.toFixed(2) : (
      <span>
        {bookingType === "BOOKING" ? pricevalue.toFixed(2) : pricevalue}
      </span>
    );
  };

  return (
    <>
      {getWindowWidth &&
        <div className="bg-white show-in-desktop-view" style={{ position: "sticky", top: "0", zIndex: "999" }}>
          <Navbar />
        </div>
      }
      {bookType === "BOOKING" &&
        <div className="fnbbreadcrb">
          <SeatLayoutBreadBrumb />
        </div>
      }
      <div className={`${bookType === "BOOKING" ? "ticketfnb row" : ""} ${currentClasses.fnbdeskdiv}`}>
        <div className={`${bookType === "BOOKING" ? "col-md-9 " : ""} ${currentClasses.fnbdeskdivInn}`}>
          <FbHeader
            fnbInput={fnBInputHandle}
            locationName={addFoodItems ? addFoodItems?.orderFilmCinema?.theaterName : cinemaname}
            headname="Order Snacks"
            fnbInputShow={fnbInputShow}
            searchShow={true}
            addressShow={true}
          />
          {fnbInputShow && (
            <div className="checkboxVegDiv">
              <img
                onClick={() => {
                  setFnBInputShow(false);
                  setSearchItem("");
                }}
                className={currentClasses.fnbSearchBack}
                src={getWindowWidth ? deskback : backBtn}
                alt=""
              />

              <div className="fnBSearchDiv">
                <svg className="fnBSearchIcon" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.16699 0.667969C12.307 0.667969 15.667 4.02797 15.667 8.16797C15.667 12.308 12.307 15.668 8.16699 15.668C4.02699 15.668 0.666992 12.308 0.666992 8.16797C0.666992 4.02797 4.02699 0.667969 8.16699 0.667969ZM8.16699 14.0013C11.3895 14.0013 14.0003 11.3905 14.0003 8.16797C14.0003 4.94464 11.3895 2.33464 8.16699 2.33464C4.94366 2.33464 2.33366 4.94464 2.33366 8.16797C2.33366 11.3905 4.94366 14.0013 8.16699 14.0013ZM15.2378 14.0605L17.5953 16.4171L16.4162 17.5963L14.0595 15.2388L15.2378 14.0605Z" fill="black" />
                </svg>
                <input
                  placeholder="Search Food Items..."
                  className="fnBSearch"
                  type="text"
                  value={searchItem}
                  onChange={(e) => setSearchItem(e.target.value)}
                  ref={inputRef}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                />
                {searchItem &&
                  <div className="micIcon" onClick={handleCloseClick}>
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="black" />
                    </svg>
                  </div>
                }
              </div>
            </div>
          )}
          {!fnbInputShow && (
            <>
              {repeatData.length > 0 && (
                <>
                  <div className="ra-div">
                    <img
                      className="ra-leftline"
                      src={theme === "pvrTheme" ? leftLinePVR : leftLineINOX}
                      alt="left-line"
                    />
                    <p className="ra-ptag">REPEAT AGAIN?</p>
                    <img
                      className="ra-rightline"
                      src={theme === "pvrTheme" ? rightLinePVR : rightLineINOX}
                      alt="right-line"
                    />
                  </div>
                  <div className={currentClasses.vchrLanding}>
                    <div ref={scrollRepeatAgainRef} className="Rec-outer">
                      {repeatData?.map((item) => (
                        <RecommendeCard
                          key={`repeat-${item.id}`}
                          data={item}
                          handleFoodInfoShow={() => handleFoodInfoShow(item)}
                          handleCustomizfoShow={() =>
                            handleCustomizfoShow(item)
                          }
                          addedItems={addedItems}
                          cartItemIds={cartItemIds.current}
                          updateAddedItems={addToCart}
                          updateCount={addToCart}
                          removeFromCart={removeFromCart}
                          totalItems={countAllItems}
                        />
                      ))}
                    </div>

                    {getWindowWidth && repeatData.length > 2 && (
                      <>
                        {isRightArrowVisibleRepeat && (
                          <img
                            className="rptarw fnb-arrow fnb-scrollArrRight"
                            src={scrollIcon}
                            alt="scrollIcon"
                            onClick={() => handleScrollRepeat("right")}
                          />
                        )}
                        {isLeftArrowVisibleRepeat && (
                          <img
                            className="rptarw fnb-arrow fnb-scrollArrleft"
                            src={scrollIcon}
                            alt="scrollIcon"
                            onClick={() => handleScrollRepeat("left")}
                          />
                        )}
                      </>
                    )}
                  </div>
                </>
              )}

              {/* Coupon Section */}
              {fnbCoupen?.offers?.length > 0 &&
                <div className={currentClasses.vchrLanding}>
                  <div ref={scrollContainerRef} className="dis-outer">
                    {fnbCoupen?.offers?.map((offer, index) => (
                      <div
                        key={`offer-${offer.vouId}-${index}`}
                        onClick={() => handleOfferfoShow(offer)}
                        className="fnb-dis-div"
                      >
                        <img
                          className="fnb-disicon"
                          src={
                            theme === "pvrTheme"
                              ? discountCardIconPVR
                              : discountCardIconINOX
                          }
                          alt=""
                        />
                        <div className="fnb-dis-des">
                          <p className="fnb-disoff">{offer.vouDesc}</p>
                        </div>
                      </div>
                    ))}
                  </div>

                  {getWindowWidth && fnbCoupen?.offers?.length > 2 && (
                    <>
                      {isRightArrowVisibleScroll && (
                        <img
                          className="voucherarw fnb-arrow fnb-scrollArrRight"
                          src={scrollIcon}
                          alt="scrollIcon"
                          onClick={() => handleScroll("right")}
                        />
                      )}
                      {isLeftArrowVisibleScroll && (
                        <img
                          className="voucherarw fnb-arrow fnb-scrollArrleft"
                          src={scrollIcon}
                          alt="scrollIcon"
                          onClick={() => handleScroll("left")}
                        />
                      )}
                    </>
                  )}
                </div>
              }

              {/* Categories Section */}
              <div className={currentClasses.mncategories}>
                <div
                  ref={categoriesContainerRef}
                  style={{ top: getWindowWidth && "70px" }}
                  className="fb-filter"
                >
                  <div
                    className="fbvegDiv"
                    style={{
                      backgroundColor: filters.veg ? "#FFF0D7" : "white",
                      border: filters.veg ? "1px solid transparent" : "",
                    }}
                  >
                    <span className="fb-v">Veg</span>
                    <label className="switchVeg">
                      <input
                        type="checkbox"
                        checked={filters.veg}
                        onChange={() => handleCheckboxChange("Veg")}
                        className="checkboxVeg"
                      />
                      <div className="sliderVeg"></div>
                    </label>
                  </div>

                  <div
                    className="fbvegDiv"
                    style={{
                      backgroundColor: filters.nonVeg ? "#FFF0D7" : "white",
                      border: filters.nonVeg ? "1px solid transparent" : "",
                    }}
                  >
                    <span className="fb-v">Non veg</span>
                    <label className="switchVeg">
                      <input
                        type="checkbox"
                        checked={filters.nonVeg}
                        onChange={() => handleCheckboxChange("non veg")}
                        className="checkboxNonVeg"
                      />
                      <div className="sliderNonVeg"></div>
                    </label>
                  </div>

                  <div
                    className="fbvegDiv"
                    style={{
                      backgroundColor: filters.bestsellers
                        ? "#FFF0D7"
                        : "white",
                      border: filters.bestsellers
                        ? "1px solid transparent"
                        : "",
                    }}
                    onClick={() => handleCheckboxChange("Bestsellers")}
                  >
                    <img
                      style={{ marginRight: "0px" }}
                      className="fbvegimg"
                      src={bestSellerIcon}
                      alt=""
                    />
                    <span className="fb-v">Bestsellers</span>
                  </div>

                  {/* Category filters */}
                  {Array.from(new Set(fnbData?.map((item) => item.ct))).map(
                    (category, index) => (
                      <div
                        key={index}
                        ref={(el) => (categoryRefs.current[category.toLowerCase()] = el)}
                        className={`fbvegDiv ${filters?.categories === category?.toLowerCase() ? "activecat" : ""}`}
                        style={{
                          backgroundColor:
                            filters?.categories === category?.toLowerCase()
                              ? "#FFF0D7"
                              : "white",
                          border:
                            filters?.categories === category?.toLowerCase()
                              ? "1px solid transparent"
                              : "",
                          columnGap: "5px",
                        }}
                        onClick={() => handleCheckboxChange(category)}
                      >
                        <span className="fb-v">
                          <img
                            className="fbvegimg"
                            src={categoriesIcons.cats.find((catIcon) => catIcon.name === category)?.imageUrl || categoriesIcons?.defaultCatImgUrl}
                            alt=""
                          />
                          {category.charAt(0).toUpperCase() +
                            category.slice(1).toLowerCase()}
                        </span>
                      </div>
                    )
                  )}
                </div>

                {getWindowWidth && fnbData?.length > 0 && (
                  <>
                    {isRightArrowVisibleCategories && (
                      <img
                        className="ctgryarw fnb-arrow fnb-scrollArrRight"
                        src={scrollIcon}
                        alt="scrollIcon"
                        onClick={() => handleScrollCategories("right")}
                      />
                    )}
                    {isLeftArrowVisibleCategories && (
                      <img
                        className="ctgryarw fnb-arrow fnb-scrollArrleft"
                        src={scrollIcon}
                        alt="scrollIcon"
                        onClick={() => handleScrollCategories("left")}
                      />
                    )}
                  </>
                )}
              </div>
            </>
          )}

          <div className="fnbItem_outer">
            {filteredDataShow && filteredDataShow.length > 0 ? (
              filteredDataShow.map((data, index) => {
                // Find the corresponding item in addedItems
                const addedItem = Array.isArray(addedItems)
                  ? addedItems.find((item) => item.ItemID === data.id)
                  : undefined;

                const itemCount = addedItem ? addedItem.quantity : 0;

                return (
                  <React.Fragment key={`${data.id}-${index}`}>
                    <FnBCard
                      key={`fnb-card-${data.id}`}
                      data={data}
                      handleFoodInfoShow={() => handleFoodInfoShow(data)}
                      handleCustomizfoShow={() => handleCustomizfoShow(data)}
                      addedItems={addedItems}
                      cartItemIds={cartItemIds.current}
                      updateAddedItems={addToCart}
                      updateCount={addToCart}
                      removeFromCart={removeFromCart}
                      totalItems={countAllItems}
                    />
                    {data?.upsellable && itemCount > 0 && (
                      <>
                        <p className="fnb-pwt-ptag">PAIR THIS WITH</p>
                        <div className="showhori-pair">
                          {data.upsellItems.map((upsellItem, subIndex) => {

                            const matchedItem = fnbData.find((fnbitem) => fnbitem.id === upsellItem.upsellItemId);

                            if (matchedItem) {
                              const combinedData = {
                                ...matchedItem,
                                parentUpsellId: data.id || "",
                                // upsellItemRate: upsellItem.upsellItemRate,
                                quantity: 1,
                              };
                              return (
                                <PairItems
                                  key={`pair-${data.id}-${upsellItem.upsellItemId}-${subIndex}`}
                                  data={combinedData}
                                  addedItems={addedItems}
                                  updateAddedItems={addToCart}
                                  cartItemIds={cartItemIds.current}
                                  updateCount={addToCart}
                                  handleFoodInfoShow={() => handleFoodInfoShow(combinedData)}
                                  handleCustomizfoShow={() =>
                                    handleCustomizfoShow(combinedData)
                                  }
                                  removeFromCart={removeFromCart}
                                  totalItems={countAllItems}
                                />
                              );
                            }
                            // });
                          })}
                        </div>
                      </>
                    )}
                  </React.Fragment>
                );
              })
            ) : (
              <div className="no-found-fnb">
                <p className="no-found-fnbP">No items found!</p>
              </div>
            )}
          </div>
          {/* {addedItems.length > 0 && ( */}
          <div
            style={getWindowWidth ? { maxWidth: "750px" } : null}
            className={currentClasses.itemAddCart}
          >
            <div
              className={`fnbCart-div ${countAllItems > 0 ? "activeItems" : ""}`}
              onClick={countAllItems > 0 && handleShowCartModal}
            >
              <div className="fnbCart-divInn">
                <img
                  src={countAllItems > 0 ? activeCartIcon : cartIcon}
                  alt=""
                />
                <p className="fnb-cartnid qtydsk">
                  {getWindowWidth
                    ? countAllItems > 0
                      ? `${countFoodItems} Items Added`
                      : "No items added"
                    : countAllItems > 0
                      ? `${countFoodItems} Items`
                      : "No items added"}
                  {!getWindowWidth && countAllItems > 0 && (
                    <img
                      style={{
                        transform: expandCartModal
                          ? "rotate(0deg)"
                          : getWindowWidth
                            ? "rotate(270deg)"
                            : "rotate(180deg)",

                        transition: "ease 0.3s",
                        marginTop: getWindowWidth ? "0px" : "10px",
                        marginLeft: "5px",
                      }}
                      src={expandCartIcon}
                      alt="expand"
                    />
                  )}
                </p>
              </div>
              <p className="fnb-cartnid">₹{calculateSubtotal.toFixed(2)}</p>
            </div>
            <button onClick={() => {
              if (bookType === "BOOKING" && addedItems.length === 0) {
                navigate(getWindowWidth ? `/payment/${cinemaname}` : `/m.payment/${cinemaname}`);
                hitGa("fnb_skip");
              } else {
                sendCartData();
              }
            }} className={currentClasses.fnbButton}>
              {loading ? (
                <>
                  Processing{" "}
                  <Spinner
                    style={{ height: "15px", width: "15px" }}
                    animation="border"
                    variant={theme === "pvrTheme" ? "dark" : "light"}
                  />
                </>
              ) : (
                bookType === "BOOKING" && addedItems.length === 0 ? "Continue" : "Proceed"
              )}
            </button>
          </div>

          <Modal
            className="fnbmdl"
            show={show || isClosing}
            onHide={handleHide}
            animation={false}
            keyboard={false}
            dialogClassName={
              getWindowWidth
                ? isOfferClosing
                  ? "modal-dialogdeskAbout modal-hide"
                  : "modal-dialogdeskAbout"
                : isOfferClosing
                  ? "modal-dialog modal-hide"
                  : "modal-dialog"
            }
          >
            {getWindowWidth ? (
              <div className="aboutfnbcloseOut">
                <img
                  className="aboutfnbclose"
                  onClick={handleHide}
                  src={closeIcon}
                  alt="close"
                />
              </div>
            ) : (
              <div className="m-cus-head" style={{ paddingBottom: 0, borderBottom: 0, justifyContent: "end" }}>
                <img className="thumbCloseModal" src={modalLine} alt="" />
                <img onClick={handleHide} src={closeIcon} alt="close" />
              </div>
            )}

            {selectedItem && (
              <div className={currentClasses.itemAboutModal.aboutModalMain}>
                <div className="fnb-am-iiDiv">
                  <img
                    className="fnb-am-iimag"
                    src={
                      selectedItem?.i
                        ? selectedItem?.i
                        : selectedItem?.imgData
                          ? `data:image/jpeg;base64,${selectedItem?.imgData}`
                          : noImg
                    }
                    // src={selectedItem?.i || noImg}
                    width="84"
                    alt={selectedItem?.itemName || "No image available"}
                    onError={handleImageError}
                  />
                </div>
                <div className="abtdesk">

                  <div className={currentClasses.itemAboutModal.aboutModalInnerDiv}>
                    <div className="abtleftItem">
                      {!getWindowWidth && (
                        <div className="vegIcon">
                          {selectedItem.foodType === 1 ?
                            <svg className="m-v-nvIm" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect x="0.5" y="0.5" width="13" height="13" rx="1.5" stroke="#149752" />
                              <circle cx="6.99984" cy="6.99935" r="4.08333" fill="#149752" />
                            </svg>
                            : selectedItem.foodType === 0 ?
                              <svg className="m-v-nvIm" width="14" height="14" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.961426" y="0.964844" width="10.0769" height="10.0769" rx="1.5" stroke="#87001B" />
                                <path d="M9 8.80886H3L6.00011 3.18945L9 8.80886Z" fill="#87001B" />
                                <clipPath id="clip0_3452_1183">
                                  <rect width="6" height="6" fill="#87001B" transform="translate(3 3)" />
                                </clipPath>
                              </svg>
                              : ""
                          }
                        </div>
                      )}
                      <div className="abtItems">
                        <p className="m-itemName">
                          {getWindowWidth && (
                            <img
                              style={{ marginleft: "10px" }}
                              className="m-v-nvImg"
                              src={
                                selectedItem.foodType === 1
                                  ? vegIcon
                                  : selectedItem.foodType === 1 ? nonvegIconCart : ""
                              }
                              alt={selectedItem.foodType}
                            />
                          )}
                          {selectedItem.h || selectedItem.comboItemName}
                        </p>

                        {selectedItem.wt && selectedItem.dp && (
                          <>
                            <ul className="m-fb-desc">
                              {selectedItem.wt && <li>{selectedItem.wt}</li>}
                              {selectedItem.en && (
                                <li>{selectedItem.en.replace(".00", "")}</li>
                              )}
                            </ul>
                            {selectedItem.fa && (
                              <p className="allergensP">{selectedItem?.fa?.toLowerCase().replace(/,/g, ', ')}</p>
                            )}
                          </>
                        )}
                        <p className="m-fb-price">₹{selectedItem.dp / 100 || parseInt(selectedItem.comboItemRate)}</p>
                      </div>

                    </div>

                    <div className="abtbtn">
                      <AddButton
                        key={selectedItem.id}
                        data={selectedItem}
                        addedItems={addedItems}
                        cartItemIds={cartItemIds.current}
                        updateCount={addToCart}
                        handleFoodInfoShow={() =>
                          handleFoodInfoShow(selectedItem)
                        }
                        removeFromCart={removeFromCart}
                        handleCustomizfoShow={() =>
                          handleCustomizfoShow(selectedItem)
                        }
                        totalItems={countAllItems}
                      />

                      {selectedItem.addOn && (
                        <span className="custom-btn">Customizable</span>
                      )}
                    </div>
                  </div>

                  {selectedItem?.comboItems?.length > 0 &&
                    <div className="cmbpart abtmodal">
                      {selectedItem?.comboItems?.map((items) => (
                        <p className="cmbTitle">
                          <div className="cmbleft">
                            <div className="vegIcon">
                              {items.foodType === 1 ?
                                <svg className="m-v-nvIm" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <rect x="0.5" y="0.5" width="13" height="13" rx="1.5" stroke="#149752" />
                                  <circle cx="6.99984" cy="6.99935" r="4.08333" fill="#149752" />
                                </svg>
                                : items.foodType === 0 ?
                                  <svg className="m-v-nvIm" width="14" height="14" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="0.961426" y="0.964844" width="10.0769" height="10.0769" rx="1.5" stroke="#87001B" />
                                    <path d="M9 8.80886H3L6.00011 3.18945L9 8.80886Z" fill="#87001B" />
                                    <clipPath id="clip0_3452_1183">
                                      <rect width="6" height="6" fill="#87001B" transform="translate(3 3)" />
                                    </clipPath>
                                  </svg>
                                  : ""
                              }
                            </div>
                            <span className="cmbTitlenr">{items?.comboItemName} X{items?.comboItemQty}</span>
                          </div>
                          <span className="cmbprice "><span className="prcfont">₹</span>{items?.comboItemSaleAmount}</span>
                        </p>
                      ))}
                    </div>
                  }
                </div>
              </div>
            )}
          </Modal>
          <Modal
            className="fnbmdl"
            show={showOffer || isOfferClosing}
            onHide={handleOfferHide}
            animation={false}
            keyboard={false}
            dialogClassName={
              `offermdl ${getWindowWidth
                ? isOfferClosing
                  ? "modal-dialogdesk modal-hide"
                  : "modal-dialogdesk"
                : isOfferClosing
                  ? "modal-dialog modal-hide"
                  : "modal-dialog"}`
            }
          >
            {selectedOffer && (
              <div className={currentClasses.offerDetailModal}>
                <img className="thumbCloseModalOff" src={modalLine} alt="" />
                <div className="m-offer-hdiv">
                  <div
                    className="m-cus-head"
                    style={{ padding: 0, borderBottom: 0 }}
                  >
                    <span className="Cus-itemspan">
                      {getWindowWidth ? "Offer Details" : selectedOffer.vouDesc}
                    </span>
                    <img
                      onClick={handleOfferHide}
                      src={closeIcon}
                      alt="close"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  {!getWindowWidth && (
                    <p className="m-offer-ptag">
                      {`Valid till ${format(
                        new Date(selectedOffer.validTo),
                        "dd MMM yyyy"
                      )}`}
                    </p>
                  )}
                </div>
                {getWindowWidth && (
                  <div className="offerDetailsDsk">
                    <div className="icn">
                      <img
                        className="fnb-disicon"
                        src={
                          theme === "pvrTheme"
                            ? discountCardIconPVR
                            : discountCardIconINOX
                        }
                        alt=""
                      />
                    </div>
                    <div className="discDsk">
                      <span className="Cus-itemspan">
                        {selectedOffer.vouDesc}
                      </span>
                      <p className="m-offer-ptag">
                        {`Valid till ${format(
                          new Date(selectedOffer.validTo),
                          "dd MMM yyyy"
                        )}`}
                      </p>
                    </div>
                  </div>
                )}

                {selectedOffer.tnc != null ? (
                  <div className="m-offer-dec-div">
                    <h3 className="m-off-dec-h">How to Avail</h3>
                    {/* <ul> */}
                    {selectedOffer.tnc.map((condition, index) => (
                      <p key={index} className="m-off-dec-li">{condition}</p>
                    ))}
                    {/* </ul> */}
                    {/* <h3 className="m-off-dec-h">Terms & Conditions</h3> */}
                    {/* <ol> */}
                    {/* {selectedOffer.tnc.map((condition, index) => (
                      <p key={index} className="m-off-dec-li">{condition}</p>
                    ))} */}
                    {/* </ol> */}
                  </div>
                ) : (
                  <div className="off-nadiv">
                    <h2 className="off-nah2">-</h2>
                  </div>
                )}
              </div>
            )}
          </Modal>
          <Modal
            className="fnbmdl"
            show={showCustomiz || isCustomizClosing}
            onHide={handleCustmizeHide}
            animation={false}
            keyboard={false}
            dialogClassName={
              getWindowWidth
                ? isOfferClosing
                  ? "modal-dialogdesk modal-hide"
                  : "modal-dialogdesk"
                : isOfferClosing
                  ? "modal-dialog modal-hide"
                  : "modal-dialog"
            }
          >
            {!getWindowWidth && (
              <div
                className="m-cus-head"
                style={{ paddingBottom: 0, borderBottom: 0, justifyContent: "end" }}
              >
                <img className="thumbCloseModal" src={modalLine} alt="" />
                <img onClick={handleCustmizeHide} src={closeIcon} alt="close" />
              </div>
            )}

            <div>
              {!getWindowWidth && selectedItem && (
                <div className={currentClasses.itemAboutModal.aboutModalMain}>
                  <div className="fnb-am-iiDiv">
                    <img
                      className="fnb-am-iimag"
                      src={
                        selectedItem?.i
                          ? selectedItem?.i
                          : selectedItem?.imgData
                            ? `data:image/jpeg;base64,${selectedItem?.imgData}`
                            : noImg
                      }
                      width="84"
                      alt={selectedItem?.itemName || "No image available"}
                      onError={handleImageError}
                    />
                  </div>
                  <div className={currentClasses.itemAboutModal.aboutModalInnerDiv}>
                    <div className="abtleftItem">
                      {!getWindowWidth && (
                        <div className="vegIcon">
                          {selectedItem.foodType === 1 ?
                            <svg className="m-v-nvIm" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect x="0.5" y="0.5" width="13" height="13" rx="1.5" stroke="#149752" />
                              <circle cx="6.99984" cy="6.99935" r="4.08333" fill="#149752" />
                            </svg>
                            : selectedItem.foodType === 0 ?
                              <svg className="m-v-nvIm" width="14" height="14" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.961426" y="0.964844" width="10.0769" height="10.0769" rx="1.5" stroke="#87001B" />
                                <path d="M9 8.80886H3L6.00011 3.18945L9 8.80886Z" fill="#87001B" />
                                <clipPath id="clip0_3452_1183">
                                  <rect width="6" height="6" fill="#87001B" transform="translate(3 3)" />
                                </clipPath>
                              </svg>
                              : ""
                          }
                        </div>
                      )}
                      <div className="abtItems">
                        <p className="m-itemName">
                          {getWindowWidth && (
                            <img
                              style={{ marginLeft: "10px" }}
                              className="m-v-nvImg"
                              src={
                                selectedItem.foodType === 1
                                  ? vegIcon
                                  : selectedItem.foodType === 0 ? nonvegIconCart : ""
                              }
                              alt={selectedItem.foodType}
                            />
                          )}
                          {selectedItem.h || selectedItem.comboItemName}
                        </p>

                        {selectedItem.wt && selectedItem.dp && (
                          <>
                            <ul className="m-fb-desc">
                              {selectedItem.wt && <li>{selectedItem.wt}</li>}
                              {selectedItem.en && <li>{selectedItem.en.replace(".00", "")}</li>}
                            </ul>
                            {selectedItem.fa && (
                              <p className="allergensP">{selectedItem.fa.toLowerCase().replace(/,/g, ", ")}</p>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <p className="m-fb-price">
                      ₹{selectedItem.dp / 100 || parseInt(selectedItem.comboItemRate)}
                    </p>
                  </div>
                </div>
              )}
              {!getWindowWidth && selectedItem?.comboItems?.length > 0 &&
                <div className="cmbpart">
                  {selectedItem?.comboItems?.map((items) => (
                    <p className="cmbTitle">
                      <div className="cmbleft">
                        <div className="vegIcon">
                          {items.foodType === 1 ?
                            <svg className="m-v-nvIm" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect x="0.5" y="0.5" width="13" height="13" rx="1.5" stroke="#149752" />
                              <circle cx="6.99984" cy="6.99935" r="4.08333" fill="#149752" />
                            </svg>
                            : items.foodType === 0 ?
                              <svg className="m-v-nvIm" width="14" height="14" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.961426" y="0.964844" width="10.0769" height="10.0769" rx="1.5" stroke="#87001B" />
                                <path d="M9 8.80886H3L6.00011 3.18945L9 8.80886Z" fill="#87001B" />
                                <clipPath id="clip0_3452_1183">
                                  <rect width="6" height="6" fill="#87001B" transform="translate(3 3)" />
                                </clipPath>
                              </svg>
                              : ""
                          }
                        </div>
                        <span className="cmbTitlenr">{items?.comboItemName} X{items?.comboItemQty}</span>
                      </div>
                      <span className="cmbprice "><span className="prcfont">₹</span>{items?.comboItemSaleAmount}</span>
                    </p>
                  ))}
                </div>
              }
            </div>

            {selectedItem?.addOnItems && selectedItem?.addOnItems?.length > 0 ? (
              <div className={currentClasses.customizeModal}>
                <div className="m-cus-head custItms">
                  <span className="Cus-itemspan">Customise Item</span>
                  {getWindowWidth && (
                    <img
                      onClick={handleCustmizeHide}
                      src={closeIcon}
                      alt="close"
                      style={{ cursor: "pointer" }}
                    />
                  )}
                </div>
                <div className="m-cus-inn">
                  {getWindowWidth ? (
                    <div className="m-cus-inameDsk flxav">
                      <div className="imsImg">
                        <img
                          className="vgicon"
                          src={selectedItem.foodType === 1 ? vegIconCart : selectedItem.foodType === 1 ? nonvegIconCart : ""}
                          alt={selectedItem.h}
                        />
                        <img
                          className="fnb-am-iimag"
                          src={
                            selectedItem?.i
                              ? selectedItem?.i
                              : selectedItem?.imgData
                                ? `data:image/jpeg;base64,${selectedItem?.imgData}`
                                : noImg
                          }
                          width="84"
                          alt={selectedItem.itemName || "No image available"}
                          onError={handleImageError}
                        />
                      </div>
                      <div className="itmsDsk">
                        <p className="m-cus-ip">{selectedItem.h}</p>
                        <div className="flxav">
                          <p className="m-cus-iprice">₹{selectedItem.dp / 100}</p>
                          {selectedItem.addOn && <span className="custom-btn">Customizable</span>}
                        </div>
                        {selectedItem.wt && selectedItem.dp && (
                          <>
                            <ul className="m-fb-desc">
                              {selectedItem.wt && <li>{selectedItem.wt}</li>}
                              {selectedItem.en && <li>{selectedItem.en.replace(".00", "")}</li>}
                            </ul>
                            {selectedItem.fa && (
                              <p className="allergensP">{selectedItem.fa.toLowerCase().replace(/,/g, ", ")}</p>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="m-cus-iname">
                      <p className="m-cus-ip">{selectedItem.h}</p>
                      <p className="m-cus-iprice">₹{selectedItem.dp / 100}</p>
                    </div>
                  )}

                  <div className="m-extraI">
                    <h3 className="m-extraH">Extra</h3>
                    {selectedItem.addOnItems.map((item, index) => (
                      <div key={index} className="m-e-iList">
                        <p className="m-e-iName">{item.addOnItemName}</p>
                        <p className="m-e-iprice">
                          <label htmlFor={`m-e-icheck${index}`}>
                            ₹{item.addOnItemRate / 100}
                          </label>
                          <input
                            id={`m-e-icheck${index}`}
                            className="m-e-icheck"
                            type="checkbox"
                            name={item.itemName}
                            checked={tempCustomizations.addonIds?.includes(item.addOnItemId)}
                            onChange={(e) =>
                              handleCustomizationChange(item, e.target.checked)
                            }
                          />
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="m-c-final">
                  <div className="m-c-finalPrice">
                    <p className="m-c-total">
                      Total: ₹
                      {(
                        (parseFloat(selectedItem.dp) +
                          calculateCustomizationTotal(selectedItem.id)) /
                        100
                      ).toFixed(2)}
                    </p>
                    <p style={{ fontSize: "12px", display: "flex", alignItems: "center", columnGap: "7px" }}>
                      Add-on {tempCustomizations?.addonIds?.length > 1 ? "'s" : ""} selected{" "}
                      <span style={{ fontWeight: 600, fontSize: "16px", margin: "0px" }}>
                        {tempCustomizations?.addonIds?.length || 0}
                      </span>
                    </p>
                  </div>
                  <button
                    onClick={() => {
                      addToCart(null, "addToCartWithAddons");
                      handleCustmizeHide("addToCartBtn");
                    }}
                    className="c-btn-pro"
                  >
                    Add to Cart
                  </button>
                </div>
              </div>
            ) : (
              <p className="fnbm-neif">No extra items found.</p>
            )}
          </Modal>

          <Modal
            className="fnbmdl"
            show={expandCartModal}
            onHide={handleExpandHide}
            animation={false}
            keyboard={false}
            dialogClassName={
              getWindowWidth
                ? isOfferClosing
                  ? "modal-dialogdesk modal-hide"
                  : "modal-dialogdesk"
                : isOfferClosing
                  ? "modal-dialog modal-hide"
                  : "modal-dialog"
            }
          >
            <div
              style={{
                marginBottom: addedItems?.length > 0 ? "0px" : "0px",
              }}
              className={currentClasses.cartModal.cartModalMain}
            >
              <img className="thumbCloseModal" src={modalLine} alt="" />
              <div className="m-cus-head">
                <span className="Cus-itemspan">Your cart</span>
                <img
                  style={{ cursor: "pointer" }}
                  onClick={handleExpandHide}
                  src={closeIcon}
                  alt="close"
                />
              </div>

              <div className={currentClasses.cartModal.cartModalInnerDiv}>

                {addedItems?.length > 0 ? (
                  addedItems?.map((item, index) => {
                    const checkItemPrice =
                      item.foodType === "1"
                        ? item.price / 100
                        : item.price / 100;
                    return (
                      <div
                        key={`${item.ItemID}-${index}`}
                        className="fnb-m-cartCard"
                      >
                        <div className="fnb-m-cartiimg">
                          {item.foodType === 1 ?
                            <svg className={currentClasses.cartModal.cartImgVeg} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect x="0.5" y="0.5" width="13" height="13" rx="1.5" stroke="#149752" />
                              <circle cx="6.99984" cy="6.99935" r="4.08333" fill="#149752" />
                            </svg>
                            : item.foodType === 0 ?
                              <svg className={currentClasses.cartModal.cartImgVeg} width="14" height="14" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.961426" y="0.964844" width="10.0769" height="10.0769" rx="1.5" stroke="#87001B" />
                                <path d="M9 8.80886H3L6.00011 3.18945L9 8.80886Z" fill="#87001B" />
                                <clipPath id="clip0_3452_1183">
                                  <rect width="6" height="6" fill="#87001B" transform="translate(3 3)" />
                                </clipPath>
                              </svg>
                              :
                              <p className="emtyIcon"></p>
                          }

                          <img className="cartitemimg" src={item?.itemImageURL || noImg}
                            alt={item?.itemName || "No image available"}
                            onError={handleImageError}
                          />
                        </div>
                        <div className="fnb-m-cartCardAbo">
                          <h3
                            style={{
                              height:
                                item?.ItemName?.length > 20 ? "34px" : "20px",
                            }}
                            className={currentClasses.cartModal.cartTitle}
                          >
                            {item?.ItemName}
                          </h3>
                          {item.AddOnItem.length > 0 && (
                            <em className="fnb-c-addons">
                              Add-ons:
                              {item.AddOnItem.map((addon, addonIndex) => {
                                return (
                                  <p className="cart-addonP" key={addonIndex}>
                                    {addon.name} ₹{addon.price / 100} X {addon.quantity}
                                  </p>
                                );
                              })}
                            </em>
                          )}
                          <div className="fnb-c-btnP">
                            <AddButton
                              cartBtn={true}
                              cartStyle={cartTransprantBtn}
                              key={`${item.ItemID}-${index}-btn`}
                              data={item}
                              cartIndex={index}
                              setAddedItems={setAddedItems}
                              addedItems={addedItems}
                              isCart={true}
                              cartItemIds={cartItemIds.current}
                              updateCount={addToCart}
                              handleFoodInfoShow={() =>
                                handleFoodInfoShow(item)
                              }
                              handleCustomizfoShow={() =>
                                handleCustomizfoShow(item)
                              }
                              removeFromCart={removeFromCart}
                              totalItems={countAllItems}
                            />
                            <p>
                              ₹
                              {(
                                item?.quantity * checkItemPrice +
                                item?.AddOnItem?.reduce(
                                  (sum, addon) =>
                                    sum + addon.price * item.quantity,
                                  0
                                ) /
                                100
                              ).toFixed(2)}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="nodatamsg">Your cart is empty</div>
                )}
              </div>
              {!getWindowWidth && addedItems?.length > 0 ? (
                <div className="fb-added-div">
                  <div className="fnbCart-div">
                    <div
                      onClick={handleShowCartModal}
                      className="fnbCart-divInn"
                    >
                      <img src={cartIcon} alt="" />
                      <p className="fnb-cartnid">
                        {/* {(() => {
													const totalItems = addedItems.reduce(
														(sum, item) => sum + item.quantity,
														0
													);
													return `Total Item${totalItems === 1 ? "" : "s"
														}: ${totalItems}`;
													})()} */}
                        {`${countFoodItems} Items`}
                        <img
                          style={{
                            transform: expandCartModal
                              ? "rotate(0deg)"
                              : "rotate(180deg)",
                            transition: "ease 0.3s",
                            marginTop: "10px",
                            marginLeft: "5px",
                          }}
                          src={expandCartIcon}
                          alt="expand"
                        />
                      </p>
                    </div>
                    <p className="fnb-cartnid">
                      ₹{calculateSubtotal.toFixed(2)}
                    </p>
                  </div>
                  <button onClick={sendCartData} className="fb-added-btn">
                    {loading ? (
                      <>
                        Processing{" "}
                        <Spinner
                          style={{ height: "20px", width: "20px" }}
                          animation="border"
                          variant={theme === "pvrTheme" ? "dark" : "light"}
                        />
                      </>
                    ) : (
                      "Proceed"
                    )}
                  </button>
                </div>
              ) : (
                <div className="cartPriceDesk">
                  <div className="cartPriceDeskInr">
                    <p>To be Paid</p>
                    <p className="fnb-cartnid">
                      ₹{calculateSubtotal.toFixed(2)}
                    </p>
                  </div>

                  <button
                    onClick={sendCartData}
                    className="fb-added-btn addcrtbtn"
                  >
                    {loading ? (
                      <>
                        Processing{" "}
                        <Spinner
                          style={{ height: "20px", width: "20px" }}
                          animation="border"
                          variant={theme === "pvrTheme" ? "dark" : "light"}
                        />
                      </>
                    ) : (
                      "Proceed"
                    )}
                  </button>
                </div>
              )}
            </div>
          </Modal>

          <SweetAlertUtility
            type={alertDetails.type}
            title={alertDetails.title}
            message={alertDetails.message}
            show={alertDetails.show}
            onConfirm={hideAlert}
          />
        </div>
        {bookType === "BOOKING" &&
          <div className="show-in-desktop-view col-md-3 book-summary">
            {loading ? (
              <BookingSummarySkeleton />
            ) : (
              <div className="all-summary get-food-info">
                <div>
                  {localStorage.getItem("booking_type") === "BOOKING" && (
                    <ExtendTime />
                  )}
                </div>
                <div className="booking-summary">
                  <div className="book-head">
                    <h3>Booking Summary</h3>
                  </div>
                  <div className="ticket-cancel">
                  </div>
                </div>
                {localStorage.getItem("booking_type") === "BOOKING" && (
                  <>
                    <div className="movies-summary">
                      <div className="summary-movies-photo">
                        <img
                          src={
                            selectedMovie?.orderFilmCinema?.posterVert
                              ? selectedMovie?.orderFilmCinema?.posterVert
                              : theme === "inoxTheme"
                                ? miv_inox
                                : miv_pvr
                          }
                          alt=""
                          className="img-fluid"
                          width={50}
                        />
                      </div>
                      <div className="summary-movies-content">
                        <h5>{selectedMovie?.orderFilmCinema?.filmName}</h5>
                        <ul>
                          <li>
                            {selectedMovie?.orderFilmCinema?.certificate}
                          </li>
                          <li>
                            <span className="dot-c"></span>
                            {selectedMovie?.orderFilmCinema?.genre}
                          </li>
                          <li>
                            <span className="dot-c"></span>
                            {selectedMovie?.orderFilmCinema?.language}
                          </li>
                        </ul>
                        <p>
                          <span className=""></span>
                          {selectedMovie?.orderFilmCinema?.theaterName}
                        </p>
                        <p>{selectedMovie?.showTime}</p>
                      </div>
                    </div>
                    <div className="seat-info">
                      <h6>Seat Info</h6>
                      {localStorage.getItem("booking_type") === "BOOKING" ? (
                        <div className="ticket-value">
                          {selectedMovie?.orderTicket?.numOfSeats && (
                            <p>{selectedMovie?.orderTicket?.showClassName}</p>
                          )}
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="select-seat-number">
                        {selectedMovie?.orderTicket?.seats
                          ?.split(",")
                          .map((item, idx) => {
                            return (
                              <>
                                <div
                                  className="seat-number"
                                  style={
                                    theme === "inoxTheme"
                                      ? { backgroundColor: "#DCE2EF" }
                                      : { backgroundColor: "#FFF0D6" }
                                  }
                                  key={idx}
                                >
                                  <p>{item}</p>
                                </div>
                              </>
                            );
                          })}
                      </div>
                    </div>
                  </>
                )}

                <div className="ticket-prices">
                  {localStorage.getItem("booking_type") === "BOOKING" ? (
                    <>
                      <h6>Tickets</h6>
                      <div className="ticket-value-ticket">
                        {selectedMovie?.orderTicket?.numOfSeats && (
                          <div className="tickets-count">
                            <h6>
                              {selectedMovie?.orderTicket?.numOfSeats}{" "}
                              <b>x</b>{" "}
                              {selectedMovie?.orderTicket?.ticketPrice}
                            </h6>
                          </div>
                        )}
                        <div className="ticket-price">
                          <p>
                            <FaRupeeSign
                              style={{
                                fontSize: "10px",
                                fontWeight: "500",
                                marginBottom: "2px",
                              }}
                            />
                            {selectedMovie?.orderTicket?.totalTicketPrice?.replace(
                              /,/g,
                              ""
                            )}
                          </p>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {addedItems?.length === 0 ? (
                    <div className="no-snack">
                      <img src={noSack} alt="" className="img-fluid" />
                      <p>No snacks added</p>
                    </div>
                  ) : (
                    <div className="food-beverages">
                      <h6>Food & BEVERAGES</h6>
                      {addedItems && selectedFoodData(addedItems)}
                    </div>
                  )}
                </div>

                <div className="food-pay-amount payment-details-food">
                  <div className="bill-details">
                    <h6>PAYMENT DETAILS</h6>
                    <div className="grand-price">
                      <div className="bill-amount">
                        <div className="sub-total">
                          <h6>Sub Total</h6>
                        </div>
                        <div className="tax-fees">
                          <h6>
                            <FaRupeeSign
                              style={{
                                fontSize: "10px",
                                fontWeight: "500",
                                marginBottom: "2px",
                              }}
                            />
                            {calculateMovieSubTotal(
                              localStorage.getItem("booking_type"),
                              selectedMovie?.orderTicket?.totalTicketPrice?.replace(/,/g, ""),
                              calculateSubtotal
                            )}
                          </h6>
                        </div>
                      </div>
                      {localStorage.getItem("booking_type") === "BOOKING" && (
                        <>
                          {selectedMovie?.orderTicket?.convTotal > 0 ? (
                            <div className="bill-amounts">
                              <div className="sub-total">
                                <h6>
                                  Taxes & Fees
                                  <span
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setShowConvFee(!showConvFee);
                                    }}
                                  >
                                    <img
                                      src={priceArrow}
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </span>
                                </h6>
                              </div>
                              <div className="tax-fees">
                                <h6>
                                  <FaRupeeSign
                                    style={{
                                      fontSize: "10px",
                                      fontWeight: "500",
                                      marginBottom: "2px",
                                    }}
                                  />
                                  {selectedMovie?.orderTicket?.convTotal}
                                </h6>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}

                          {showConvFee && (
                            <>
                              <div className="food-show-gst">
                                <div className="bill-amounts">
                                  <div className="gst">
                                    <h6>Convenience Fee</h6>
                                  </div>
                                  <div className="tax-gst">
                                    <h6>
                                      <FaRupeeSign
                                        style={{
                                          fontSize: "10px",
                                          fontWeight: "500",
                                          marginBottom: "2px",
                                        }}
                                      />
                                      {selectedMovie?.orderTicket?.conv}
                                    </h6>
                                  </div>
                                </div>

                                {selectedMovie?.orderFilmCinema
                                  ?.hsnSacCode ? (
                                  <div className="bill-amounts">
                                    <div className="gst">
                                      <h6>
                                        (Sac Code :{" "}
                                        {
                                          selectedMovie?.orderFilmCinema
                                            ?.hsnSacCode
                                        }
                                        )
                                      </h6>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}

                                <div className="bill-amounts">
                                  <div className="gst">
                                    <h6>GST</h6>
                                  </div>
                                  <div className="tax-gst">
                                    <h6>
                                      <FaRupeeSign
                                        style={{
                                          fontSize: "10px",
                                          fontWeight: "500",
                                          marginBottom: "2px",
                                        }}
                                      />
                                      {selectedMovie?.orderTicket?.convGst}
                                    </h6>
                                  </div>
                                </div>

                                {selectedMovie?.orderFilmCinema?.stateGSTN ? (
                                  <div className="bill-amounts">
                                    <div className="gst">
                                      <h6>GST NUMBER</h6>
                                    </div>
                                    <div className="tax-gst">
                                      <h6>
                                        {
                                          selectedMovie?.orderFilmCinema
                                            ?.stateGSTN
                                        }
                                      </h6>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div className="food-pay-amount">
                  <div className="seat-bottom-btn">
                    <div className="all-grand">
                      <div className="grand-tota col-md-3">
                        <h6>Grand Total</h6>
                        <h6>
                          <FaRupeeSign
                            style={{
                              fontSize: "10px",
                              fontWeight: "500",
                              marginBottom: "2px",
                            }}
                          />
                          {calculateGrandTotal(
                            localStorage.getItem("booking_type"),
                            selectedMovie?.orderTicket?.totalTicketPrice?.replace(/,/g, ""),
                            selectedMovie?.orderTicket?.convTotal,
                            calculateSubtotal
                          )}
                        </h6>
                      </div>
                      {/* <div className="register-btn col-md-3">
                      <ProceedBtn
                        className="btn-proceeded"
                        onClick={() => {
                          IscustomEvent("Procced to Payment")
                          if (addedItems.length > 0) {
                            hitGa("fnb_add_checkout");
                            saveFoodselectedMovie();
                          } else {
                            hitGa("fnb_skip");
                            navigate(`/payment/${cinemaname}`);
                          }
                        }}
                        disabled={saveFoodLoading}
                      >
                        {saveFoodLoading ? (
                          <span>
                            Proceeding
                            <img src={Loader} alt="" className="img-fluid" />
                          </span>
                        ) : (
                          "Proceed"
                        )}
                      </ProceedBtn>
                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        }
      </div>
    </>
  );
};

export default FnBLanding;
