import React, { useContext, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import FbHeader from './component/FbHeader'
import { useDispatch, useSelector } from 'react-redux';
import { constantsTypes } from 'src/redux/reduxConstants';
import emoji from '../../assets/food/emoji.svg'
import { TabPanel, TabView } from 'primereact/tabview';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import * as appConstants from "../../constants/index";
import SweetAlertUtility from './component/SweetAlertUtility';

const OffersPromocode = () => {
    const [showOffer, setShowOffer] = useState(false);
    const [loading, setLoading] = useState(false);
    const [promoCode, setPromoCode] = useState('');
    const [cardError, setCardError] = useState(false);
    const [errMsg, setErrMsg] = useState(false);
    const [offerApplied, setOfferApplied] = useState(false);
    const [alertDetails, setAlertDetails] = useState({ show: false, type: '', title: '', message: '' });

    const authToken = "Bearer " + sessionStorage.getItem("token");
    const theatreId = localStorage.getItem("TheatreId");
    const transactionType = localStorage.getItem("TransactionType");
    const appliedVocher = JSON.parse(localStorage.getItem("AppliedOfferData"));
    const storedDataString = JSON.parse(localStorage.getItem("getAllFnbData"));

    const finalAmount = appliedVocher?.resOffer?.output?.dp;

    // const { name } = useParams();
    const pathname = "Promocode" //name.replaceAll("-", " ");
    const location = useLocation();
    const applyVpucherState = location?.state?.promoCodeState;
    const navigate = useNavigate();

    

    const userInfo = localStorage.getItem("loginInfo");
    const userInfoData = JSON.parse(userInfo);
    const userSelectedFood = JSON.parse(localStorage.getItem("getAllFnbData"));
   


    // const AppliedOffer = localStorage.getItem("AppliedOffer");

    const handleOfferClose = () => {
        setShowOffer(false);
        navigate("/fnb-checkout");
    };

    const handleChange = (e) => {
        // let value = e.target.value.replace(/\D/g, '');
        // value = value.replace(/(.{4})/g, '$1 ').trim();
        setPromoCode(value);
    };

    const handlePromoCode = async (e) => {
        e.preventDefault();

        if (promoCode === "") {
            setCardError(true);
            setErrMsg("Enter promocode");
            return;
        } else {
            // localStorage.setItem('cardDetails', JSON.stringify({ cardType, cardNumber }));
            setCardError(false);
            const formData = new URLSearchParams({
              theatreId: theatreId,
              transId: userSelectedFood?.fnAllData?.transId,
              binNo: "", //cardNumber.replaceAll(" ", ""), // 401704,//cardNumber,
              transactionType: transactionType,
              cardType: "",
              mobile: userInfoData?.data?.mob, //userInfoData?.data?.mob || '',
              vouId: applyVpucherState.vouId,
              bankName: applyVpucherState.bankName,
              vouType: applyVpucherState.vouType,
              vouNo: promoCode,
              platform: "MOB WEBSITE",
            });

            setLoading(true);

            try {
                const response = await axios.post(`${appConstants.base_url}v1/fnb/fnbApplyOffer`,
                    formData.toString(),
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            Authorization: authToken,
                          
                        },
                    }
                );
                // debugger
                if (response.data.status === 1) {
                    setOfferApplied(response.data);

                    const AppliedOfferData = {
                        vouDesc: pathname,
                        vouId: applyVpucherState.vouId,
                        resOffer: response.data,
                        vouPayType: "",
                    };

                    localStorage.setItem('AppliedOfferData', JSON.stringify(AppliedOfferData));

                    const existGetAllFnbData = JSON.parse(localStorage.getItem("getAllFnbData"))
                    existGetAllFnbData.AppliedOfferDataNew = AppliedOfferData;
                    AppliedOfferData.ApplyOfferRespn = response?.data;
                    localStorage.setItem('getAllFnbData', JSON.stringify(existGetAllFnbData));

                    setShowOffer(true);
                } else {
                    setCardError(true);
                    setErrMsg(response.data.msg);
                }
            } catch (error) {
                sweetAlertError("Something went wrong!")
                console.error('Failed to fetch splash data:', error);
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        inputRef.current.focus();
    }, []);

    const inputRef = useRef(null);

    const sweetAlertError = (msg) => {
        setAlertDetails({
            show: true,
            type: 'error',
            title: 'Error!',
            message: msg,
        });
    };

    const hideAlert = () => {
        setAlertDetails({ show: false });
    }

    return (
        <div>
            <FbHeader headname={pathname} />
            <div className='ttlamt'>
                <p>To be paid</p>
                <p> {finalAmount
                    ? `₹${finalAmount}`
                    : `₹${storedDataString?.fnAllData?.totalPrice.toFixed(
                        2
                    )}`}</p>
            </div>
            <div className='contentPage cardTabs'>
                <div className="frmgrp">
                    <label>Enter Voucher Code</label>
                    <input
                        type="text"
                        className='frmcntrl'
                        name="promono"
                        id="promono"
                        placeholder=''
                        value={promoCode}
                        maxLength={19}
                        onChange={(e) => setPromoCode(e.target.value)}
                        // ref={creditCardInputRef}
                        autoComplete="off"
                        ref={inputRef}
                    />
                    {cardError && <p className='errmsg'>{errMsg}</p>}
                </div>
                <p className='payment-feildp'>We promise to keep your voucher safe</p>
                <div className="gyftr-box"><p>Promocode Voucher amount would not be refunded on cancellation</p>
                    <div className="divider-gyftr"></div>
                    <p>If the vouchervalue is more than the bill the remaining amount will be forfeited.</p>
                </div>
                <div className="fixbtmbtn"><button className="cmnbtn w100" onClick={handlePromoCode}>{loading ? 'Processing...' : 'Apply'}</button></div>
            </div>

            <style>
                {`
				.modal.show .modal-dialog {
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				.modal-content {
					max-width: 85vw;
					position: relative;
					border-radius: 20px;
				}
				`}
            </style>
            <Modal
                show={showOffer}
                onHide={handleOfferClose}
                animation={false}
                keyboard={false}
                style={{ backgroundColor: "rgba(0,0,0,0.7)", overflow: "hidden" }}
            >
                <div className="fb-o-m">
                    <img className="fb-o-emoji" src={emoji} alt="" />
                    <p className="fb-m-code">'{pathname}' Applied!</p>
                    <div className="fb-m-det">
                        <p className="fb-m-price">₹{offerApplied?.output?.di}</p>
                        <p className="fb-m-saved">saved with this</p>
                        <p className="fb-m-offP">Food Offer</p>
                    </div>
                    <button onClick={handleOfferClose} className="fb-m-aBtn">
                        Awesome!
                    </button>
                </div>
            </Modal>

            <SweetAlertUtility type={alertDetails.type} title={alertDetails.title} message={alertDetails.message} show={alertDetails.show} onConfirm={hideAlert} />
        </div>
    )
}



export default OffersPromocode
