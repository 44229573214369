import React, { useEffect, useState } from "react";
import arrowback from "../../../assets/passport/mdi_arrow_back.png";
import coupon from "../../../assets/family/coupon-line-1.png";
import question from "../../../assets/family/question-line-1.png";
import banner from "../../../assets/family/banner-pvr.png";
import li from "../../../assets/family/li-blue.png";

import { Sidebar } from "primereact/sidebar";
import { AddBtn, AlsoAddBtn } from "src/views/get-food/getfoodTags";
import uparrow from "../../../assets/static-page/uparrow.svg";
import downarrow from "../../../assets/static-page/down.svg";
import { FaMinus, FaPlus } from "react-icons/fa";
import { familyBundleApply } from "src/services/CouponPayment";
import { Accordion, AccordionTab } from "primereact/accordion";
import "./FamilyBundle.css"; // Custom CSS for fb-prefixed classes
import { Checkbox } from "primereact/checkbox";
import SweetAlert from "react-bootstrap-sweetalert";
import FlexiTnc from "../flexipay/FlexiTnc";
import { click } from "@testing-library/user-event/dist/click";

const FamilyBundle = ({
  familyBundle,
  content,
  setFamilyBundle,
  ticketSummaryCalled,
}) => {
  //   const [familyBundle, setFamilyBundle] = useState(true);
  const [isAccordionTabOpen, setIsAccordionTabOpen] = useState(false);
  const theme = localStorage.getItem("theme");
  const [currentValue, setCurrentValue] = useState(1);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 575);
  const [checked, setChecked] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showflexitnc, setShowflexiTnc] = useState(false);
  useEffect(() => {    
    
  }, [content]); 


  const TncAccept = (val) => {
    setShowflexiTnc(val);
    setChecked(!val);
  };
  const calculateDiscount = () => {
    let newval = currentValue - 1;
    const ticketdynamicvalue =
      content?.familyBundleVouchers[0]?.discValue +
      content?.familyBundleVouchers[0]?.fbIncValue * newval;
    const discountAmount =
      (ticketdynamicvalue * content?.familyBundleVouchers[0]?.fbDiscPer) / 100;
    const priceAfterDiscount = ticketdynamicvalue - discountAmount;
    return priceAfterDiscount.toFixed(2);
  };

  const ApplyBundle = () => {
    if (!checked) {
      setErrorMsg("Please accept terms and conditions!");
      setShowAlert(true);
      return "";
    }
    setLoading(true);
    let postdata = {
      qr:
        localStorage.getItem("isQR") && isMobile
          ? "YES"
          : localStorage.getItem("fnb_qr") && isMobile
          ? "YES"
          : "NO",
      bookType: localStorage.getItem("booking_type"),
      transId: localStorage.getItem("transid"),
      bookingId: localStorage.getItem("bookingid"),
      ccode: localStorage.getItem("theaterId"),

      cvpId: content?.familyBundleVouchers[0]?.vouId,
      quantity: currentValue - 1,
      increment: currentValue - 1,
    };
    familyBundleApply(postdata)
      .then((res) => {
        // console.log(res);
        setLoading(false);
        let data = res.data;
        setFamilyBundle(false);
        ticketSummaryCalled(true);
        if (data?.output) {
          // localStorage.setItem("transidFamilyBunndle", data?.output?.transid);
        }
      })
      .catch((error) => {
        setLoading(false);
        const err = error?.response;
        if (err?.status === 403) {
          // refraceTokenService();
          console.log(
            "Forbidden: You do not have permission to access this resource."
          );
        } else {
          console.log("Error:", error?.message);
        }
        // setLoading(false);
      });
  };

  const pushToDataLayer = (event, voucherName, voucherId, price, validDate,inc_quantity) => {
    let dataLayer = window["dataLayer"] || [];  
    dataLayer.push({
      event: event,
      voucher_name: voucherName,
      voucher_id: voucherId,
      price: price,
      valid_date: validDate,
      inc_quantity: inc_quantity
    });  
  };
  const accordionHeader = (item) => {
    return (
      <div className="d-flex align-items-center">
        <div className="faq-question">
          <h4>
            <img
              src={item == "Voucher Details" ? coupon : question}
              alt=""
              className="img-fluid "
            />{" "}
            {item}
          </h4>
          <span>
            {item == "Voucher Details"
              ? "Know details about this voucher"
              : "Know how to avail this voucher"}
          </span>
        </div>
      </div>
    );
  };

  return (
    <>
      <SweetAlert
        // error
        show={showAlert}
        // title={errorMsg}
        onConfirm={() => {
          setShowAlert(false);
          // console.log('confirm')
          //navigate('/')
        }}
        onCancel={() => setShowAlert(false)}
        confirmBtnCssClass={
          theme === "inoxTheme"
            ? "confirm-btn-inox border-0"
            : "confirm-btn-pvr border-0"
        }
      >
        {errorMsg}
      </SweetAlert>
      <Sidebar
        header="Header"
        visible={familyBundle}
        position={isMobile ? "bottom" : "right"}
        className="family-bundle-sidebar"
        onHide={() => {
          setFamilyBundle(false);
          // changefaq(false);
        }}
        style={isMobile ? {} : { width: "unset" }}
        dismissable={false}
        showCloseIcon={false}
        // blockScroll={true}
      >
        <div className="right-side show-in-mobile-view">
          <div className="colse-btn pvr-inox-heading">
            <div className="right-side-info-fb">
              <div className="inox-passport">
                <h2 className="text-center">
                  {content?.familyBundleData?.title}
                </h2>
              </div>
            </div>
            <p className="text-center">
              {content?.familyBundleData?.description}
            </p>
          </div>
        </div>
        {/* mobile view */}
        {/*desktop view  */}
        <div className="right-side show-in-desktop-view">
          <div className="colse-btn pvr-inox-heading">
            <div className="row">
              <div className="col-10 ">
                <h3 className="ms-3">{content?.familyBundleData?.title}</h3>
              </div>
              <div className="col-2">
                <span
                  onClick={() => {
                    setFamilyBundle(false);
                    //   changefaq(false);
                  }}
                >
                  <i
                    className="pi pi-times"
                    style={{ color: "#000", cursor: "pointer" }}
                  ></i>
                </span>
              </div>
            </div>
            <p className="card-text ms-3">
              {content?.familyBundleData?.description}
            </p>
          </div>
        </div>
        <div className="fb-container d-flex flex-column">
          <div className="card flex-grow-1">
            <div className="card-body">
              {/* <h5 className="card-title">
              Upgrade your Experience with an Amazing Deal!
            </h5> */}
              {/* <p className="card-text">
              {content?.familyBundleData?.description}
            </p> */}
              <div className="fb-deal-banner text-white">
                <img
                  src={
                    theme === "inoxTheme"
                      ? content?.familyBundleData?.imgUrl
                      : content?.familyBundleData?.imgUrlPVR
                  }
                ></img>
                <h6>{content?.familyBundleData?.imgDescription}</h6>
                <p>{content?.familyBundleData?.imgTitle}</p>
              </div>
              <div className="fb-voucher-details mb-3">
                <h5>{content?.familyBundleVouchers[0]?.vouDesc}</h5>
                <p className="text-muted">
                  {content?.familyBundleVouchers[0]?.validity}
                </p>
                <div
                  className={`${
                    theme === "inoxTheme"
                      ? "fb-price-section-inox"
                      : "fb-price-section"
                  } d-flex justify-content-between align-items-center`}
                >
                  <AlsoAddBtn
                    className="subtract"
                    // onClick={() =>
                    //   currentValue > 1 ? setCurrentValue(currentValue - 1) : ""
                    // }
                    onClick={() => {
                      if (currentValue > 1) {
                        setCurrentValue(currentValue - 1);                    
                        if (content?.familyBundleVouchers?.length > 0) {
                          const voucher = content.familyBundleVouchers[0];  
                          pushToDataLayer(
                          "family_bundle_minus",
                            voucher.vouDesc, 
                            voucher.vouId,
                            voucher.discValue, 
                            voucher.validity,
                            currentValue -1
                          );
                        } 
                      }
                    }}
                    
                    disabled={currentValue > 1 ? false : true}
                  >
                    <FaMinus />
                  </AlsoAddBtn>
                  <div
                    className={
                      theme === "inoxTheme" ? "fb-price-inox" : "fb-price"
                    }
                  >
                    <span className="display-4">
                      ₹
                      {content?.familyBundleVouchers[0]?.discValue +
                        content?.familyBundleVouchers[0]?.fbIncValue *
                          (currentValue - 1)}
                    </span>
                  </div>
                  <div className="fb-discount">
                    <span className="fb-original-price text-muted text-decoration-line-through ms-3">
                      ₹
                      {content?.familyBundleVouchers[0]?.discValue +
                        content?.familyBundleVouchers[0]?.fbIncValue *
                          (currentValue - 1)}
                    </span>
                    <span className="fb-discounted-price text-success ms-2">
                      ₹{calculateDiscount()}
                    </span>

                    <span className="badge bg-success ms-3">
                      {content?.familyBundleVouchers[0]?.fbDiscPer}% OFF
                    </span>
                  </div>
                  <AddBtn
                    className="plus"
                    // onClick={() => addFoods(item)}
                    onClick={() => {
                      console.log(
                        "click",
                        currentValue <
                          content?.familyBundleVouchers[0]?.maxFbIncrement + 1,
                        currentValue,
                        content?.familyBundleVouchers[0]?.maxFbIncrement + 1
                      );

                      if (
                        currentValue <
                        content?.familyBundleVouchers[0]?.maxFbIncrement + 1
                      ) {
                        setCurrentValue(currentValue + 1);
                        if (content?.familyBundleVouchers?.length > 0) {
                          const voucher = content.familyBundleVouchers[0];  
                          pushToDataLayer(
                          "family_bundle_plus",
                            voucher.vouDesc, 
                            voucher.vouId,
                            voucher.discValue, 
                            voucher.validity,
                            currentValue
                          );
                        }                       
                      } else {
                        setShowAlert(true);
                        setErrorMsg(
                          "You've reached the max number of options."
                        );
                      }
                    }}
                    // disabled={
                    //   currentValue <
                    //   content?.familyBundleVouchers[0]?.maxFbIncrement + 1
                    //     ? false
                    //     : true
                    // }
                    style={
                      currentValue <
                      content?.familyBundleVouchers[0]?.maxFbIncrement + 1
                        ? {}
                        : {
                            backgroundColor:
                              theme === "inoxTheme" ? "#DCE2EF" : "#FFF0D6",
                          }
                    }
                  >
                    <FaPlus />
                  </AddBtn>
                </div>
              </div>

              <div
                className="accordion spacing-family-bundles"
                id="fb-voucherAccordion"
              >
                <Accordion
                  expandIcon={
                    <img
                      src={downarrow}
                      alt="Expand Icon"
                      className="img-fluid custom-cricle-arrow"
                    />
                  }
                  collapseIcon={
                    <img
                      src={uparrow}
                      alt="Collapse Icon"
                      className="img-fluid custom-cricle-arrow"
                    />
                  }
                  // onTabClose={() => setIsAccordionTabOpen(false)}
                  // onTabOpen={() => setIsAccordionTabOpen(true)}
                  activeIndex={0}
                >
                  <AccordionTab header={accordionHeader("Voucher Details")}>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="faq-answer-fb">
                          <ul>
                            {content &&
                              content?.familyBundleData?.voucherDetails?.map(
                                (item, index) => {
                                  return <li>{item}</li>;
                                }
                              )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </AccordionTab>
                  <AccordionTab header={accordionHeader("How It Works?")}>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="faq-answer">
                          <div>
                            <ul
                              className={
                                theme === "inoxTheme"
                                  ? "hiw-steps-inox"
                                  : "hiw-steps-pvr"
                              }
                            >
                              {content &&
                                content?.familyBundleData?.hiw?.map(
                                  (item, index) => {
                                    return (
                                      <li className="hiw-step">
                                        <span
                                          className={
                                            theme === "inoxTheme"
                                              ? "hiw-step-icon-inox"
                                              : "hiw-step-icon-pvr"
                                          }
                                        ></span>{" "}
                                        <span className="hiw-step-text">
                                          {!item?.header ? (
                                            ""
                                          ) : (
                                            <>
                                              <strong className="hiw-section-title">
                                                {item?.header}
                                              </strong>
                                              <br />
                                            </>
                                          )}
                                          {item?.text}
                                        </span>{" "}
                                      </li>
                                    );
                                  }
                                )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </AccordionTab>
                </Accordion>
              </div>
            </div>
          </div>
          {/* Button fixed at the bottom */}
          <div className="fb-btn-bottom">
            <div className="termsandcondition">
              <div className="terms">
                <div
                  className={
                    theme === "inoxTheme"
                      ? " check-payment-inox me-2"
                      : " check-payment-pvr me-2"
                  }
                >
                  <Checkbox
                    inputId="binary"
                    checked={checked}
                    onChange={(e) => setChecked(e.checked)}
                  />
                </div>{" "}
                I agree to the&nbsp;{" "}
                <a
                  href="javascript:void(0);"
                  onClick={() => setShowflexiTnc(true)}
                  style={{ color: "#000", textDecoration: "underline" }}
                >
                  {" "}
                  {"  "} terms & conditions
                </a>
              </div>
              <div className="voucher-total-left">Voucher Total</div>
              <div className="voucher-total-right">
                <span class="fb-original-price text-muted text-decoration-line-through ms-3">
                  ₹
                  {content?.familyBundleVouchers[0]?.discValue +
                    content?.familyBundleVouchers[0]?.fbIncValue *
                      (currentValue - 1)}
                </span>
                <span class="fb-discounted-price text-success ms-2">
                  ₹{calculateDiscount()}
                </span>
              </div>
            </div>

            <div className="fb-ftn-left ms-2">
              <button
                className="btn btn-secondary w-100"
                onClick={() =>{ setFamilyBundle(false)
                  if (content?.familyBundleVouchers?.length > 0) {
                    const voucher = content.familyBundleVouchers[0];                  
                      pushToDataLayer(
                      'family_bundle_skip', 
                        voucher.vouDesc,
                        voucher.vouId, 
                        voucher.discValue, 
                        voucher.validity,  
                        ''       
                      );
                   
                  }
                }
              }
              >
                Skip
              </button>
            </div>
            <div className="fb-ftn-right me-2">
              <button
                className={`${theme === "inoxTheme"?"btn-warning-inox":"btn-warning-pvr"} w-100`}
                onClick={() => { (!loading ? ApplyBundle() : "")
                  if (content?.familyBundleVouchers?.length > 0) {
                    const voucher = content.familyBundleVouchers[0];
                    if (voucher) {
                      localStorage.setItem("vouDesc", voucher.vouDesc);
                      localStorage.setItem("vouId", voucher.vouId);
                      localStorage.setItem("discValue", voucher.discValue);
                      localStorage.setItem("validity", voucher.validity);
                    } 
                      pushToDataLayer(
                      'family_bundle_proceed', 
                        voucher.vouDesc,
                        voucher.vouId, 
                        voucher.discValue, 
                        voucher.validity,  
                        currentValue      
                      );                    
                  }
                }
                }
              >
                Proceed
                {loading ? (
                  <i
                    className="pi pi-spin pi-spinner"
                    style={{ fontSize: "1.5em" }}
                  ></i>
                ) : (
                  ""
                )}
              </button>
            </div>
          </div>
        </div>
      </Sidebar>
      <FlexiTnc
        visible={showflexitnc}
        content={{ tnc: content?.familyBundleTnc, fb: true }}
        setVisible={TncAccept}
      />
    </>
  );
};

export default FamilyBundle;
