import React, { useEffect } from "react";
import { Sidebar } from "primereact/sidebar";
import foodGIF from "../../../assets/food/food.gif";
import { ProceedButton } from "src/styled-components/generalTags";
import FlexiIcon from "../../../assets/flexi/FLEXISHOW-LOGO-WHITE.svg";
import Cross from "../../../assets/flexi/cross.svg";
import "./flexi.css"

function FlexiPayPopup({ visible, content, setVisible }) {
  const theme = localStorage.getItem("theme");
  const isMobile = window.matchMedia("(max-width: 575px)").matches;
  useEffect(() => {    
  }, [visible]);

  const hitGa = (event) => {
    let dataLayer = window["dataLayer"] || [];
    dataLayer.push({
      event: event,
    });
  };
  return (
    <div>
      <Sidebar
        visible={visible}
        //   icons={proceedbtn}
        showCloseIcon={false}
        // style={{ height: "64%" }}
        position={isMobile?"bottom":"top"}
         className="flexi-popup"
        onHide={() => {
          setVisible(false);
        }}
        dismissable={true}
      >
        <div className="row">
          <div className="col-lg-12 col-sm-12">
            <div className="mob-map-container">
              <div className="cross-flexi-popup">
                <img src={Cross} alt="" onClick={() => {
                    setVisible(false);
                  }} className="img-fluid"/>
              </div>
              <div className="flexi-popup-content">
                <img src={FlexiIcon} alt="" height={"50px"} />
                <br />
                <h2 dangerouslySetInnerHTML={{ __html: content?.title }}></h2>
                <p dangerouslySetInnerHTML={{ __html: content?.subtitle }}></p>
                <div className="ifyou-need"
                  dangerouslySetInnerHTML={{ __html: content?.content }}
                ></div>
                <ProceedButton className="flexi-ok"
                onClick={() => {
                  setVisible(false)                  
                  hitGa("flexishow_ticket_ok");
                }} >
                  OK
                </ProceedButton>
              </div>
            </div>
          </div>
        </div>
      </Sidebar>
    </div>
  );
}

export default FlexiPayPopup;
