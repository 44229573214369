import React, { useEffect } from "react";
import { Sidebar } from "primereact/sidebar";
import foodGIF from "../../../assets/food/food.gif";
import { ProceedButton } from "src/styled-components/generalTags";
import cross from "../../../assets/flexi/cross.svg";
import "./flexi.css"

function FlexiTnc({ visible, content, setVisible }) {
  const theme = localStorage.getItem("theme");
  const isMobile = window.matchMedia("(max-width: 575px)").matches;
  useEffect(() => {
  }, [visible]);
  return (
    <div>
      <Sidebar
        visible={visible}
        //   icons={proceedbtn}
        showCloseIcon={false}
        // style={{ height: "64%" }}
        position={isMobile ? "bottom" : "right"}
        onHide={() => {
          setVisible(false);
        }}
        className="flexi-terms-conds"
        dismissable={true}
      >
        {!content?.fb?<>
        <div className="terms-flexi-head">
          <div className="terms-heading">
            <h4>Terms & Conditions</h4>
          </div>
          <div className="flexi-cross show-in-desktop-view">
            <img src={cross} alt="" className="img-fluid" onClick={() => setVisible(false)} />
          </div>
        </div>
        <div className="divider-flexi"></div>
        </>
        :""}
        <div className="row">
          <div className="col-lg-12 col-sm-12">
            <div className={`flexi-terms-list px-4 ${content?.fb?"p-4":""}` }
            >            <div
              dangerouslySetInnerHTML={{ __html: content?.tnc }}
            >

              </div>
              <div className="accept-viww-flexi">
                <ProceedButton className="how-it-okay" onClick={() => setVisible(false)}>
                  Accept
                </ProceedButton>
              </div>
            </div>
          </div>
        </div>
      </Sidebar>
    </div>
  );
}

export default FlexiTnc;
