import React, { useEffect, useRef, useState } from 'react';
import OfferCard from './component/OfferCard';
import { Modal } from 'react-bootstrap';
import emoji from '../../assets/food/emoji.svg';
import FbHeader from './component/FbHeader';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as appConstants from "../../constants/index";
import SweetAlertUtility from './component/SweetAlertUtility';
import { TabPanel, TabView } from 'primereact/tabview';
import FnbOfferCardSkeleton from 'src/skeletons/FnbOfferCardSkeleton';
import Navbar from '../common/navbar/Navbar';
import { getResponsiveClasses } from './component/ResponsiveClasses';

const OffersCardPage = () => {
	const navigate = useNavigate();
	const [showOffer, setShowOffer] = useState(false);
	const [loading, setLoading] = useState(false);
	const [offersVoucher, setOffersVoucher] = useState(null);
	const [offerApplied, setOfferApplied] = useState(false);
	const [pageErrMsg, setPageErrMsg] = useState(false);
	const [alertDetails, setAlertDetails] = useState({ show: false, type: '', title: '', message: '' });
	const [activeIndex, setActiveIndex] = useState(0);
	const [promoError, setPromoError] = useState(false);
	const [promoCode, setPromoCode] = useState('');
	const [errMsg, setErrMsg] = useState(false);
	const [promoCodeBln, setPromoCodeBln] = useState();

	const userInfoData = JSON.parse(localStorage.getItem("loginInfo"));
	const appliedOfferData = JSON.parse(localStorage.getItem("AppliedOfferData"));
	const theatreId = localStorage.getItem("TheatreId");
	const cardDetailsData = JSON.parse(localStorage.getItem("cardDetails"));
	const transactionType = localStorage.getItem("TransactionType");
	const userSelectedFood = JSON.parse(localStorage.getItem("getAllFnbData"));
	const seatdetails = JSON.parse(localStorage.getItem("seatdetails"));
    const fnb_qr = localStorage.getItem("fnb_qr") || null;
	const theme = localStorage.getItem("theme");

	const bookingId = localStorage.getItem("bookingid") || "";
	const transId = localStorage.getItem("transid") || "";
	const authToken = "Bearer " + sessionStorage.getItem("token");
	// const transId = userSelectedFood?.fnAllData?.transId || "";

	const { currentClasses, getWindowWidth } = getResponsiveClasses();
	useEffect(() => {
		// fnbApplyOffer();
		getPayModeLists();
	}, []);


	const getPayModeLists = async () => {
		setLoading(true);
		const postData = {
			ccode: localStorage.getItem("theaterId"),
			bookingId: bookingId,
			transId: transId,
			bookType: localStorage.getItem("booking_type"),
			// mandate: true,/
			unpaid: localStorage.getItem("unpaidMode"),
		};

		await axios
			.post(`${appConstants.base_url}v1/booking/ticketing/paymodes`, postData, {
				headers: {
					Authorization: authToken,
					chain: theme === "inoxTheme" ? "INOX" : "PVR",
					city: localStorage.getItem("city"),
					appVersion: appConstants.av,
					platform: appConstants.pt,
					country: appConstants.cname,
				},
			})
			.then((res) => {
				const dt = res.data;
				if (dt.result === "success") {
					// const ndt = dt.output;
					setOffersVoucher(dt.output);
				} else if (res.data.code === 12001) {
					// alert(res.data.msg);
				} else {
					console.log(res.data.msg);
				}

				setLoading(false);
			})
			.catch((error) => {
				const err = error?.response;
				if (err?.status === 403) {
					// refraceTokenService();
					console.log(
						"Forbidden: You do not have permission to access this resource."
					);
				} else {
					console.log("Error:", error?.message);
				}
				setLoading(false);
			});
	};

	const handleOfferClose = (vouDesc, vouId) => {

		setShowOffer(false);
		navigate("/fnb-checkout");
	};


	const handlePromoCode = async (e) => {
		e.preventDefault();

		if (promoCode === "") {
			setPromoError(true);
			setErrMsg("Enter promocode");
			return;
		} else {
			setPromoCodeBln(promoCode)
			getApplyData(promoCode, "", "", "promocode", "", "", "");
		}
	}

	const getApplyData = async (vouId, vouDesc, vouType, category, type, tnc) => {
		setLoading(true);
		const voutypedata = vouType?.toLowerCase() === "auto" ? "AUTOVOUCHER" : vouType?.toLowerCase() === "binbased" ? "BANKOFFER" : vouType?.toLowerCase() === "promocode" ? "PROMOCODE" : "AUTOVOUCHER";
		const unlockData = vouType?.toLowerCase() === "auto" ? false : "";
		const cardData = vouType?.toLowerCase() === "binbased" ? cardDetailsData?.cardNumber : "";
		const cardTypeData = vouType?.toLowerCase() === "binbased" ? cardDetailsData?.cardType : "";
		const vouIdData = vouType?.toLowerCase() === "promocode" ? 0 : vouId;
		const qrsData = fnb_qr === "YES" ? true : false;
		const fnb_qrData = seatdetails?.fnb_qr === "YES" || transactionType === "InCinemaFB" ? "YES" : "NO";

		const formData = {
			ccode: theatreId || "",
			bookingId: bookingId || "",
			transId: transId || "",
			bookType: "FOOD",
			couponId: vouIdData,
			coupon: vouId,
			couponType: voutypedata || "",
			qr: fnb_qrData,
			card: cardData || "",
			cardType: cardTypeData || "",
			mobile: userInfoData?.data?.mob || '',
			otp: "",
			unlock: unlockData,
			qrs: qrsData
		}

		// const formData = headerData;

		const state = { ...formData, tnc }
		if (category?.toLowerCase() === "offer" && type?.toLowerCase() === "bank" && vouType?.toLowerCase() === "binbased") {
			const nameURL = encodeURIComponent(vouDesc.replace(/\s+/g, '-').toLowerCase());
			navigate(`/bankoffer/${nameURL}`, { state: { bankCurrentDataState: state } });
		} else if (category?.toLowerCase() === "voucher" && type?.toLowerCase() === "normal" && vouType?.toLowerCase() === "") {
			// const nameURL = encodeURIComponent(vouDesc.replace(/\s+/g, '-').toLowerCase());
			navigate(`/promocode`, { state: { promoCodeState: state }, });
		} else {
			setLoading(true);
			try {
				const response = await axios.post(
					`${appConstants.base_url}v1/payment/coupon/apply`,
					formData,
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: authToken,
							chain: localStorage.getItem("theme") === "inoxTheme" ? "INOX" : "PVR",
							appVersion: appConstants.av,
							platform: appConstants.pt, //"WEBSITE", //appConstants.pt,
							country: appConstants.cname
						},
					}
				);

				if (response?.data.result === "success") {
					setOfferApplied(response.data);
					setShowOffer(true);
					const appliedOfferData = {
						vouDesc: vouDesc,
						vouId: vouId,
						resOffer: response.data,
						vouPayType: voutypedata
					};
					localStorage.setItem('AppliedOfferData', JSON.stringify(appliedOfferData));


					const existGetAllFnbData = JSON.parse(localStorage.getItem("getAllFnbData"))
					existGetAllFnbData.AppliedOfferDataNew = appliedOfferData;
					appliedOfferData.ApplyOfferRespn = response?.data;
					localStorage.setItem('getAllFnbData', JSON.stringify(existGetAllFnbData));

				} else {
					sweetAlertError(response.data.msg);
				}
			} catch (error) {
				sweetAlertError('Something went wrong!');
				console.error('Error details:', error.response ? error.response.data : error.message);
			} finally {
				setLoading(false);
			}
		}
	};

	const inputRef = useRef(null);

	const handleTabChange = (e) => {
		setActiveIndex(e.index);
	};

	useEffect(() => {
		if (offersVoucher?.autoVouchers?.length === 0 && offersVoucher?.vouchers?.length === 0) {
			setActiveIndex(2);
		} else if (offersVoucher?.bankoffers?.length === 0) {
			setActiveIndex(1);
		}
	}, [offersVoucher]);

	useEffect(() => {
		if (activeIndex === 2 && inputRef.current) {
			inputRef.current.focus();
		}
	}, [activeIndex]);

	const sweetAlertError = (msg) => {
		setAlertDetails({
			show: true,
			type: 'error',
			title: 'Error!',
			message: msg,
		});
	};

	const hideAlert = () => {
		setAlertDetails({ show: false });
	}

	return (
		<>
			{getWindowWidth &&
				<div className="bg-white show-in-desktop-view" style={{ position: "sticky", top: "0", zIndex: "999" }}>
					<Navbar />
				</div>
			}
			<div className={currentClasses.fnbdeskdiv}>
				<div className={currentClasses.fnbdeskdivInn}>
					<FbHeader locationName={false} headname="Offers" />
					{loading ? (
						<FnbOfferCardSkeleton />
					) : (
						<div className="offer-cardOuter">
							{pageErrMsg ? (
								<div className="nodatamsg">{pageErrMsg}</div>
							) : offersVoucher && (
								<>
									<TabView
										activeIndex={activeIndex}
										onTabChange={handleTabChange}
										className="vouTabs "
									>
										{offersVoucher?.bankoffers?.length > 0 &&
											<TabPanel header="Bank Offers">
												{/* {renderOffersByType(["bank", "netbanking"])} */}
												{offersVoucher?.bankoffers.map((item, index) => (
													<OfferCard key={index} handleOfferfoShow={getApplyData} items={item} />
												))}
											</TabPanel>
										}

										{(offersVoucher?.autoVouchers?.length > 0 || offersVoucher?.vouchers?.length > 0) &&
											<TabPanel header="Brand Offers">
												{/* {renderOffersByType("normal")} */}

												{offersVoucher?.autoVouchers.map((item, index) => (
													<OfferCard key={index} handleOfferfoShow={getApplyData} items={item} />
												))}
												{offersVoucher?.vouchers.map((item, index) => (
													<OfferCard key={index} handleOfferfoShow={getApplyData} items={item} />
												))}
											</TabPanel>
										}

										<TabPanel header="Promocode">
											<div className="prmcode cardTabs">
												<div className="frmgrp">
													<label>Enter Voucher Code</label>
													<input
														type="text"
														className='frmcntrl'
														name="promono"
														id="promono"
														placeholder=''
														value={promoCode}
														maxLength={19}
														onChange={(e) => setPromoCode(e.target.value)}
														// ref={creditCardInputRef}
														autoComplete="off"
														ref={inputRef}
													/>
													{promoError && <p className='errmsg'>{errMsg}</p>}
												</div>

												<p className='payment-feildp'>We promise to keep your voucher safe</p>
												<div className="gyftr-box"><p>Promocode Voucher amount would not be refunded on cancellation</p>
													<div className="divider-gyftr"></div>
													<p>If the voucher value is more than the bill the remaining amount will be forfeited.</p>
												</div>
												<div className={currentClasses.applyButton}><button className="cmnbtn w100" onClick={handlePromoCode}>{loading ? 'Processing...' : 'Apply'}</button></div>
											</div>
										</TabPanel>

									</TabView>
								</>
							)}
						</div>)}
				</div>
			</div>
			<style>
				{`
				.modal.show .modal-dialog {
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				.modal-content {
					max-width: 85vw;
					position: relative;
					border-radius: 20px;
				}
				`}
			</style>
			<Modal
				show={showOffer}
				onHide={() => setShowOffer(false)}
				animation={false}
				keyboard={false}
				style={{ backgroundColor: "rgba(0,0,0,0.7)", overflow: "hidden" }}
			>
				<div className="fb-o-m">
					<img className="fb-o-emoji" src={emoji} alt="" />
					<p className="fb-m-code">'{promoCodeBln ? promoCodeBln : appliedOfferData?.vouDesc}' Applied!</p>
					<div className="fb-m-det">
						<p className="fb-m-price">₹{offerApplied?.output?.di}</p>
						<p className="fb-m-saved">saved with this</p>
						<p className="fb-m-offP">Food Offer</p>
					</div>
					<button onClick={() => handleOfferClose(appliedOfferData?.vouDesc, appliedOfferData?.vouId)} className="fb-m-aBtn"> Awesome! </button>
				</div>
			</Modal>

			<SweetAlertUtility type={alertDetails.type} title={alertDetails.title} message={alertDetails.message} show={alertDetails.show} onConfirm={hideAlert} />
		</>
	);
}

export default OffersCardPage;
