import React, { useState } from "react";
import { DonateButton } from "../paymentTags";
import SweetAlert from "react-bootstrap-sweetalert";
import { Giftcardapply } from "src/services/CouponPayment";
import ReCAPTCHA from "react-google-recaptcha";
import { InputText } from "primereact/inputtext";

const GiftCard = ({ sendDataForPayment }) => {
  const theme = localStorage.getItem("theme");
  const [gccardnum, setValue6] = useState(null);
  const [gccardpin, setGccardpin] = useState(null);

  const [errorMsg, setErrorMsg] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const handleCaptchaChange = (value) => {
    // This function is called when the user successfully verifies the captcha
    setIsCaptchaVerified(true);
  };

  const setPayment = () => {
    // if (!isCaptchaVerified && theme === "inoxTheme") {
    //   setErrorMsg("Please verify captcha");
    //   setShowAlert(true);
    //   return "";
    // }
    if (!gccardnum) {
      setErrorMsg("Please enter card number");
      setShowAlert(true);
      return "";
    }
    if (!gccardpin) {
      setErrorMsg("Please enter card pin");
      setShowAlert(true);
      return "";
    }
    setLoading(true);
    const postData = {
      transId: localStorage.getItem("transid") || 0,
      bookingId: localStorage.getItem("bookingid"),
      bookType: localStorage.getItem("booking_type")
        ? localStorage.getItem("booking_type")
        : "BOOKING",
      gccardnum: gccardnum,
      gcpinnum: gccardpin,
      type: "GC",
    };
    Giftcardapply(postData)
      .then((res) => {
        const dt = res.data;
        dt.voucheName = "GIFTCARD";
        sendDataForPayment(dt);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
      });
  };

  return (
    <>
      <SweetAlert
        error
        show={showAlert}
        title={errorMsg}
        onConfirm={() => {
          setShowAlert(false);
          // console.log('confirm')
          //navigate('/')
        }}
        onCancel={() => setShowAlert(false)}
        confirmBtnCssClass={
          theme === "inoxTheme"
            ? "confirm-btn-inox border-0"
            : "confirm-btn-pvr border-0"
        }
      >
        {errorMsg}
      </SweetAlert>
      <div className="">
        <div className="payment-form">
          <div className="paywithdebit">
            <div className="debit-card show-in-desktop-view">
              <h6>Gift Card</h6>
            </div>
            <div className="to-paid">
              {/* <p>
                To be Paid:
                <span className="price-cross">₹8888</span>{" "}
                <span className="price-blod">₹8888</span>
              </p> */}
            </div>
          </div>
          <div className="payment-debitcard">
            <form>
              <div className="row">
                <div className="col-md-12">
                  <div className="payment-feild">
                    <span className="p-float-label">
                      <InputText
                        type="text"
                        id="gccardnum"
                        className="offer-control"
                        value={gccardnum}
                        onChange={(e) =>
                          setValue6(e.target.value.replace(/[^0-9]/g, ""))
                        }
                        maxlength="18"
                      />
                      <label htmlFor="gccardnum">Enter Card Number</label>
                    </span>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="payment-feild">
                    <span className="p-float-label">
                      <InputText
                        type="text"
                        id="gccardpin"
                        className="offer-control"
                        value={gccardpin}
                        onChange={(e) =>
                          setGccardpin(e.target.value.replace(/[^0-9]/g, ""))
                        }
                        maxlength="8"
                      />
                      <label htmlFor="gccardpin">Enter PIN</label>
                    </span>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="payment-feild">
                    <span className="p-float-label">
                      {/* {theme === "inoxTheme" ? (
                        <ReCAPTCHA
                          sitekey="6LdXBZoUAAAAAHOq5qTq0GwmiuCn1e1D3zxNZH7d"
                          onChange={handleCaptchaChange}
                        />
                      ) : (
                        ""
                      )} */}
                      {/* <label htmlFor="chips">Captcha</label> */}
                    </span>
                  </div>
                </div>
                {/* <div className="col-md-12">
                  <div className="payment-feild">
                    <span className="p-float-label">
                      <Chips
                        id="chips"
                        value={value6}
                        onChange={(e) => setValue6(e.value)}
                      />
                      <label htmlFor="chips">Enter Captcha</label>
                    </span>
                  </div>
                </div> */}
                <div className="col-md-12">
                  <div className="field-checkbox">
                    <label htmlFor="binary">
                      Gift card will not be refunded for cancellation
                    </label>
                  </div>
                  {/* <div className="check-securely">
                    <h6>
                      <span>
                        <label className="checkbox-container">
                          <InputText type="checkbox" checked={statusCheck} onChange={()=> setstatusCheck(statusCheck)} />
                          <span className="checkmark"></span>
                        </label>
                      </span>
                      Securely save this card for future use
                    </h6>
                  </div> */}
                </div>
                <div className="col-md-12">
                  <div className="verfiy-pay">
                    <DonateButton
                      className={"verfiy-payment"
                        // isCaptchaVerified && theme === "pvrTheme"
                        //   ? "verfiy-payment"
                        //   : theme === "inoxTheme"
                        //   ? "verfiy-payment"
                        //   : "verfiy-payment btn-opacity"
                      }
                      type="button"
                      onClick={setPayment}
                    >
                      Apply
                      {loading ? (
                        <i
                          className="pi pi-spin pi-spinner"
                          style={{ fontSize: "1.5em" }}
                        ></i>
                      ) : (
                        ""
                      )}
                    </DonateButton>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default GiftCard;
